
import ToastAx       from 'app/actions/toast';
import {
  VolEventTypes,
}                    from 'app/constants';
import VolEventsDuck from 'app/ducks/company-admin/vol-events';
import history       from 'app/history';
import paths         from 'app/paths';
import CadminSlx     from 'app/selectors/company-admin/';
import BuilderSlx    from 'app/selectors/company-admin/builder-vol-event';

const Types = {
  SET_VOL_EVENT: 'CADMIN_VEB_SET_VOL_EVENT',
  SET_VOL_EVENT_ID: 'CADMIN_VEB_SET_VOL_EVENT_ID',
  SET_KEY_VAL: 'CADMIN_VEB_SET_KEY_VAL',
  SET_KEY_VALS: 'CADMIN_VEB_SET_KEY_VALS',
  SET_TAG: 'CADMIN_VEB_SET_TAG',
  SET_NONPROFIT: 'CADMIN_VEB_SET_NONPROFIT',
  SET_LEAD_EMPLOYEE: 'CADMIN_VEB_SET_LEAD_EMPLOYEE',
  ADD_SHIFT: 'CADMIN_VEB_ADD_SHIFT',
  REMOVE_SHIFT: 'CADMIN_VEB_REMOVE_SHIFT',
  UPDATE_SHIFT: 'CADMIN_VEB_UPDATE_SHIFT',
  SAVE: 'CADMIN_VEB_SAVE',
  SET_HAS_SUBMITTED: 'CADMIN_VEB_SET_HAS_SUBMITTED',
  SET_GROUP: 'CADMIN_VEB_SET_GROUP',
  REMOVE_GROUP: 'CADMIN_VEB_REMOVE_GROUP',
  DEFAULT_PRIMARY_GROUP: 'CADMIN_VEB_DEFAULT_PRIMARY_GROUP',
  SET_PRIMARY_GROUP: 'CADMIN_VEB_SET_PRIMARY_GROUP',
  ADD_LINK: 'CADMIN_VEB_ADD_LINK',
  REMOVE_LINK: 'CADMIN_VEB_REMOVE_LINK',
  UPDATE_LINK: 'CADMIN_VEB_UPDATE_LINK',
};

const Actions = {

  setVolEventId: (volEventId) => {
    return { type: Types.SET_VOL_EVENT_ID, volEventId };
  },

  setVolEvent: (volEvent, volEventShifts) => {
    return { type: Types.SET_VOL_EVENT, volEvent, volEventShifts };
  },

  setKeyVal: (key, val) => {
    return { type: Types.SET_KEY_VAL, key, val };
  },

  setKeyVals: (keyVals) => {
    return { type: Types.SET_KEY_VALS, keyVals };
  },

  setTag: (tag, checked) => {
    return { type: Types.SET_TAG, tag, checked };
  },

  setNonprofit: (nonprofit) => (dispatch, getState) => {
    dispatch(Actions.setKeyVal('nonprofitId', nonprofit.id));
    const state = getState();
    const nteeCode = BuilderSlx.nteeCode(state);
    const type = BuilderSlx.type(state);
    const isGroupEvent = type === VolEventTypes.EVENT;
    if (!nteeCode && nonprofit.nteeCode && !isGroupEvent) {
      dispatch(Actions.setKeyVal('nteeCode', nonprofit.nteeCode));
    }
    return dispatch({ type: Types.SET_NONPROFIT, nonprofit });
  },

  setLeadEmployee: (employee) => (dispatch, getState) => {
    dispatch(Actions.setKeyVal('leadEmployeeId', employee.id));
    return dispatch({ type: Types.SET_LEAD_EMPLOYEE, employee });
  },

  addShift: () => {
    return { type: Types.ADD_SHIFT };
  },
  removeShift: (index) => {
    return { type: Types.REMOVE_SHIFT, index };
  },
  updateShift: (index, attrs) => {
    return { type: Types.UPDATE_SHIFT, index, attrs };
  },

  setGroup: (group, index) => (dispatch, getState) => {
    dispatch({ type: Types.SET_GROUP, group, index });
    dispatch(Actions.defaultPrimaryGroup());
  },
  removeGroup: (index) => (dispatch, getState) => {
    dispatch({ type: Types.REMOVE_GROUP, index });
    dispatch(Actions.defaultPrimaryGroup());
  },
  setPrimaryGroup: (index) => (dispatch, getState) => {
    const state = getState();
    const gids = BuilderSlx.groupIds(getState());
    const primaryGroupId = gids[index] || null;
    dispatch({ type: Types.SET_PRIMARY_GROUP, primaryGroupId });
  },
  defaultPrimaryGroup: () => {
    return { type: Types.DEFAULT_PRIMARY_GROUP };
  },

  addLink: () => {
    return { type: Types.ADD_LINK };
  },
  removeLink: (index) => {
    return { type: Types.REMOVE_LINK, index };
  },
  updateLink: (index, link) => {
    return { type: Types.UPDATE_LINK, index, link };
  },

  setHasSubmitted: (hasSubmitted=true) => {
    return { type: Types.SET_HAS_SUBMITTED, hasSubmitted };
  },

  save: () => (dispatch, getState) => {
    dispatch(Actions.setHasSubmitted(true));
    const state = getState();
    const isValid = BuilderSlx.isValid(state);
    if (!isValid) return;
    const shifts = BuilderSlx.shifts(state);
    const attrs = BuilderSlx.saveAttrs(state);
    const volEventId = BuilderSlx.volEventId(state);
    const company = CadminSlx.company(state);
    const promise = volEventId
      ? dispatch(VolEventsDuck.Ax.update(company.id, volEventId, {...attrs, shifts}))
      : dispatch(VolEventsDuck.Ax.create(company.id, {...attrs, shifts}));
    promise.then(({volEvent}) => {
      const toastMsg = volEventId ? 'Changes saved.' : 'Event draft created.';
      dispatch(ToastAx.success(toastMsg));
      history.push(paths.cadminVolEventsView(company.slug, volEvent.id));
    });
    return dispatch({ type: Types.SAVE, promise, volEventId });
  },

};

export {
  Types,
};

export default Actions;
