import { createSelector } from 'reselect';

import EntitiesSlx from 'app/selectors/entities';
import GroupsSlx   from 'app/selectors/groups';

const selLoadPending      = state => state.pageGroup.loadPending;
const selGroupId          = state => state.pageGroup.groupId;
const selSocialListingIds = state => state.pageGroup.socialListingIds;
const selSocialMoreCount  = state => state.pageGroup.socialMoreCount;

const selGroup = createSelector(
  [selGroupId, EntitiesSlx.groups],
  (id, groups) => {
    return groups[id];
  }
);

const selCompany = createSelector(
  [selGroup, EntitiesSlx.companies],
  (group, companiesMap) => {
    if (!group) return null;
    return companiesMap[group.companyId] || null;
  }
);

const selMembership = createSelector(
  [selGroupId, GroupsSlx.membershipByGroupId],
  (id, membershipByGroupId) => {
    if (!membershipByGroupId) return null;
    const membership = membershipByGroupId[id];
    return membership ? {isMember: true, ...membership} : {isMember: false};
  }
);

export default {
  loadPending: selLoadPending,
  group: selGroup,
  company: selCompany,
  membership: selMembership,
  socialListingIds: selSocialListingIds,
  socialMoreCount: selSocialMoreCount,
};
