
import CadminCompaniesAx  from 'app/actions/company-admin/companies';
import CadminDashboardsAx from 'app/actions/company-admin/company-dashboards';
import CadminBuilderDbAx  from 'app/actions/company-admin/builder-dashboard';
// import CadminSlx          from 'app/selectors/company-admin/';

const Types = {
  LOAD: 'CADMIN_PAGE_DB_EDIT_LOAD',
};

const Actions = {

  load: ({params: {companySlug, companyDashboardId}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      dispatch(CadminDashboardsAx.getData(companySlug, companyDashboardId)),
      dispatch(CadminDashboardsAx.get(companySlug, companyDashboardId, {activeOnly: false})).then(({companyDashboard}) => {
        dispatch(CadminBuilderDbAx.setDashboard(companyDashboard));
        return {companyDashboard};
      }),
      dispatch(CadminBuilderDbAx.setDashboardId(companyDashboardId)),
    ]);
    return dispatch({type: Types.LOAD, promise, companyDashboardId});
  },

};

export {
  Types,
};

export default Actions;
