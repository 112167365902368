import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import history              from 'app/history';
import paths                from 'app/paths';
import reducerUtils         from 'app/reducers/utils';
import CadminSlx            from 'app/selectors/company-admin/';
import EntitiesSlx          from 'app/selectors/entities';
import RoutingSlx           from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_APPR_LOAD',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(params.companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading = state => state.companyAdmin.pageSettingsApprovals.isLoading;


  const selSettings = createSelector(
    [CadminSlx.company],
    (company) => {
      return company?.settingsApprovals || null;
    }
  );

  return {
    isLoading: selIsLoading,
    settings: selSettings,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
