import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CadminCampaignsAx   from 'app/actions/company-admin/campaigns';
import CadminCompaniesAx   from 'app/actions/company-admin/companies';
import DriveDonationsAx    from 'app/actions/company-admin/drive-donations';
import CadminEmployeesDuck from 'app/ducks/company-admin/employees';
import history             from 'app/history';
import paths               from 'app/paths';
import reducerUtils        from 'app/reducers/utils';
import CadminSlx           from 'app/selectors/company-admin/';
import EntitiesSlx         from 'app/selectors/entities';
import RoutingSlx          from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_DRV_DONS_LOAD',
  SEARCH: 'CADMIN_PAGE_DRV_DONS_SEARCH',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(Ax.search(params.companySlug, query)),
      dispatch(CadminCompaniesAx.loadCommon(params.companySlug)),
      ...(query.campaignId ? [
        dispatch(CadminCampaignsAx.get(params.companySlug, query.campaignId)),
      ] : []),
      ...(query.employeeId ? [
        dispatch(CadminEmployeesDuck.Ax.get(params.companySlug, query.employeeId)),
      ] : []),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const key = `${(new Date()).getTime()}`;
    const promise = dispatch(DriveDonationsAx.search(companyId, params));
    return dispatch({type: Types.SEARCH, promise, key});
  },

  setQueryParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = Slx.queryParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminDriveDonations(companySlug, params);
    history.push(path);
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
  searchPending: false,
  searchKey: null,
  ddIds: null,
  pagination: null,
  summary: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

  [`${Types.SEARCH}_PENDING`]: (state, {key}) => {
    return {...state,
      searchPending: true,
      searchKey: key,
      ddIds: null,
      pagination: null,
      summary: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {key, result}) => {
    if (state.searchKey !== key) return state;
    const ddIds = result.driveDonations.map(dd => dd.id);
    return {...state,
      searchPending: false,
      ddIds,
      pagination: result.pagination,
      summary: result.summary,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, {key}) => {
    if (state.searchKey !== key) return state;
    return {...state,
      searchPending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading     = (state) => state.companyAdmin.pageDriveDonations.isLoading;
  const selSearchPending = (state) => state.companyAdmin.pageDriveDonations.searchPending;
  const selSearchKey     = (state) => state.companyAdmin.pageDriveDonations.searchKey;
  const selDdIds         = (state) => state.companyAdmin.pageDriveDonations.ddIds;
  const selPagination    = (state) => state.companyAdmin.pageDriveDonations.pagination;
  const selSummary       = (state) => state.companyAdmin.pageDriveDonations.summary;

  const selQueryParams = createSelector(
    [RoutingSlx.query],
    (query) => {
      return _.pick(query, ['startDate', 'endDate', 'page', 'campaignId', 'driveGoodId', 'employeeId', 'entryType']);
    }
  );

  const selDriveDonations = createSelector(
    [selDdIds, EntitiesSlx.driveDonations, EntitiesSlx.driveGoods, EntitiesSlx.campaigns, EntitiesSlx.employees],
    (ids, donations, goods, campaigns, employees) => {
      if (!ids) return null;
      return ids.map((id) => {
        const dd = donations[id];
        return {
          ...dd,
          driveGood: goods[dd.driveGoodId],
          campaign: campaigns[dd.campaignId],
          employee: employees[dd.employeeId],
        };
      });
    }
  );

  const selSelectedCampaign = createSelector(
    [RoutingSlx.query, EntitiesSlx.campaigns],
    (query, campaigns) => {
      return campaigns[query.campaignId];
    }
  );

  const selSelectableGoods = createSelector(
    [RoutingSlx.query, EntitiesSlx.campaigns, EntitiesSlx.driveGoods],
    (query, campaigns, goods) => {
      const campaign = campaigns[query.campaignId];
      if (!campaign) return null;
      return (campaign.driveGoodIds || []).map(id => goods[id]).filter(dg => dg);
    }
  );

  return {
    isLoading: selIsLoading,
    searchPending: selSearchPending,
    searchKey: selSearchKey,
    ddIds: selDdIds,
    pagination: selPagination,
    summary: selSummary,
    queryParams: selQueryParams,
    driveDonations: selDriveDonations,
    selectedCampaign: selSelectedCampaign,
    selectableGoods: selSelectableGoods,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
