import _ from 'lodash';
import { createSelector } from 'reselect';

import CampaignsAx       from 'app/actions/company-admin/campaigns';
import CadminCompaniesAx from 'app/actions/company-admin/companies';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_CAMPS_VIEW_LOAD',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const {companySlug, campaignId} = params;
    const promise = Promise.all([
      dispatch(CampaignsAx.get(companySlug, campaignId)),
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading = (state) => state.companyAdmin.pageCampaignsView.isLoading;

  const selCampaign = createSelector(
    [RoutingSlx.params, EntitiesSlx.campaigns],
    (params, campaignMap) => {
      return campaignMap[params.campaignId] || null;
    }
  );

  const selChangeLogs = createSelector(
    [selCampaign, EntitiesSlx.changeLogs],
    (campaign, clMap) => {
      return (campaign?.changeLogIds || []).map(id => clMap[id]).filter(cl => cl);
    }
  );

  return {
    isLoading: selIsLoading,
    campaign: selCampaign,
    changeLogs: selChangeLogs,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
