import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import groupHelpers from 'app/helpers/groups';
import EntitiesSlx from 'app/selectors/entities';

const selGroupId         = state => state.companyAdmin.builderGroup.groupId;
const selHasTouched      = state => state.companyAdmin.builderGroup.hasTouched;
const selSavePending     = state => state.companyAdmin.builderGroup.savePending;
const selHasSubmitted    = state => state.companyAdmin.builderGroup.hasSubmitted;

const selName            = state => state.companyAdmin.builderGroup.name;
const selGroupTypeId     = state => state.companyAdmin.builderGroup.groupTypeId;
const selDescription     = state => state.companyAdmin.builderGroup.description;
const selBannerImgPath   = state => state.companyAdmin.builderGroup.bannerImgPath;
const selLogoImgPath     = state => state.companyAdmin.builderGroup.logoImgPath;
const selHighlights      = state => state.companyAdmin.builderGroup.highlights;
const selStats           = state => state.companyAdmin.builderGroup.stats;
const selResources       = state => state.companyAdmin.builderGroup.resources;
const selNonprofitIds    = state => state.companyAdmin.builderGroup.nonprofitIds;
const selHasSocialFeed   = state => state.companyAdmin.builderGroup.hasSocialFeed;
const selTeamsChannelUrl = state => state.companyAdmin.builderGroup.teamsChannelUrl;

const selSaveAttrs = (state) => {
  const attrs = _.pick(state.companyAdmin.builderGroup, groupHelpers.editAttrs);
  groupHelpers.textAttrs.forEach((attr) => {
    attrs[attr] = (attrs[attr] || '').trim();
  });
  if (!attrs.teamsChannelUrl) attrs.teamsChannelUrl = null;
  attrs.nonprofitIds = _.uniq(attrs.nonprofitIds.filter(id => id));
  return attrs;
};

const selValidations = createSelector(
  [selSaveAttrs],
  (saveAttrs) => {
    const v = {};
    if (!saveAttrs.name) v.name = ['required'];
    if (!saveAttrs.description) v.description = ['required'];
    if (!saveAttrs.bannerImgPath) v.bannerImgPath = ['required'];
    if (!saveAttrs.logoImgPath) v.logoImgPath = ['required'];
    if (!saveAttrs.groupTypeId) v.groupTypeId = ['required'];
    return Object.keys(v).length ? v : null;
  }
);

const selVisibleValidations = createSelector(
  [selValidations, selHasSubmitted],
  (validations, hasSubmitted) => hasSubmitted ? validations : null
);

const selIsValid = createSelector(
  [selValidations, selHighlights, selStats, selResources],
  (validations, highlights, stats, resources) => {
    if (validations) return false;
    const hasInvalidHighlight = highlights.some(groupHelpers.getHighlightValidations);
    if (hasInvalidHighlight) return false;
    const hasInvalidStat = stats.some(groupHelpers.getStatValidations);
    if (hasInvalidStat) return false;
    const hasInvalidResource = resources.some(groupHelpers.getResourceValidations);
    if (hasInvalidResource) return false;
    return true;
  }
);

const selNonprofits = createSelector(
  [selNonprofitIds, EntitiesSlx.nonprofits],
  (npids, nonprofits) => {
    return npids.map(npid => nonprofits[npid]);
  }
);

const selSaveNonprofitIds = createSelector(
  [selNonprofits],
  (nonprofits) => {
    return nonprofits.filter(n => n).map(n => n.id);
  }
);

const selStoredGroup = createSelector(
  [selGroupId, EntitiesSlx.groups],
  (id, groupMap) => (groupMap[id] || null)
);

export default {
  name: selName,
  groupTypeId: selGroupTypeId,
  description: selDescription,
  bannerImgPath: selBannerImgPath,
  logoImgPath: selLogoImgPath,

  highlights: selHighlights,
  stats: selStats,
  resources: selResources,
  hasSocialFeed: selHasSocialFeed,
  teamsChannelUrl: selTeamsChannelUrl,

  groupId: selGroupId,
  nonprofits: selNonprofits,
  saveNonprofitIds: selSaveNonprofitIds,
  storedGroup: selStoredGroup,
  
  saveAttrs: selSaveAttrs,
  hasSubmitted: selHasSubmitted,
  savePending: selSavePending,
  hasTouched: selHasTouched,
  visibleValidations: selVisibleValidations,
  validations: selValidations,
  isValid: selIsValid,
};
