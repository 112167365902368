import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-vol-tracking';
import Checkbox           from 'app/components/common/checkbox';
import Confirm            from 'app/components/common/confirm';
import DateRangePicker    from 'app/components/common/date-range-picker';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import ScrollTable        from 'app/components/common/scroll-table';
import StandardSelect     from 'app/components/common/standard-select';
import EntityInput        from 'app/components/company-admin/common/entity-input';
import CadminLayout       from 'app/components/company-admin/layout/';
import ModalVteEdit       from 'app/components/company-admin/volunteer/modal-vte-edit';
import ModalVteNew        from 'app/components/company-admin/volunteer/modal-vte-new';
import PageLoading        from 'app/components/layout/page-loading';
import {
  VolTimeEntryReviewStatuses as Statuses,
  VolTimeEntryEntryTypes as EntryTypes,
  VolEventTypes as EventTypes,
  EmployeeRoles,
}                         from 'app/constants';
import ModalVteEditDuck   from 'app/ducks/company-admin/modal-vte-edit';
import ModalVteNewDuck    from 'app/ducks/company-admin/modal-vte-new';
import format             from 'app/helpers/format';
import eventHelpers       from 'app/helpers/vol-events';
import paths              from 'app/paths';
import AuthSlx            from 'app/selectors/auth';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-vol-tracking';

const statusLabelMap = {
  [Statuses.APPROVED]: 'Approved',
  [Statuses.PENDING]: 'Pending',
  [Statuses.REJECTED]: 'Restricted',
  future: 'Upcoming',
};
const statusColorMap = {
  [Statuses.PENDING]: 'yellow',
  [Statuses.REJECTED]: 'red',
};

const entryTypeLabelMap = {
  [EntryTypes.MANUAL]: 'Manual',
  [EntryTypes.RSVP]: 'RSVP',
  [EntryTypes.BATCH]: 'Batch',
  [EntryTypes.ADMIN]: 'Admin',
};
const entryTypeColorMap = {
  [EntryTypes.MANUAL]: 'blue',
};

const eventTypeOptions = [
  {label: 'Volunteer', value: EventTypes.VOL_OPP},
  {label: 'Group', value: EventTypes.EVENT},
];

const entryTypeOptions = [
  {label: 'Form Submission', value: EntryTypes.MANUAL},
  {label: 'Event Sign Up', value: EntryTypes.RSVP},
  {label: 'Batch Update', value: EntryTypes.BATCH},
  {label: 'Admin Created', value: EntryTypes.ADMIN},
];

class PageCadminVolTracking extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedVteIds: {},
      showBulkApproveConfirm: false,
      showBulkRejectConfirm: false,
    };

    this.onSelectPage = this.onSelectPage.bind(this);
    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onChangeEmployee = this.onChangeEmployee.bind(this);
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.onClickTrack = this.onClickTrack.bind(this);
    this.onClickTrackVol = this.onClickTrackVol.bind(this);
    this.onClickTrackEvent = this.onClickTrackEvent.bind(this);
    // this.onCloseTrackModal = this.onCloseTrackModal.bind(this);
    this.onClickApproveSelected = this.onClickApproveSelected.bind(this);
    this.onClickRejectSelected = this.onClickRejectSelected.bind(this);
    this.onResponseApproveSelected = this.onResponseApproveSelected.bind(this);
    this.onResponseRejectSelected = this.onResponseRejectSelected.bind(this);
    this.onSelectEventType = this.onSelectEventType.bind(this);
    this.onSelectEntryType = this.onSelectEntryType.bind(this);
    this.onSelectShift = this.onSelectShift.bind(this);
    this.onClickClearFilters = this.onClickClearFilters.bind(this);
  }

  get effectivelySelectedIds() {
    return (this.props.volTimeEntries || []).filter(vte => this.state.selectedVteIds[vte.id]).map(vte => vte.id);
  }

  get showBulkActions() {
    return this.props.queryParams.reviewStatus === Statuses.PENDING;
  }

  get isTypeVol() {
    return this.props.queryParams.eventType === EventTypes.VOL_OPP;
  }

  get isTypeEvent() {
    if (this.props.queryParams.eventType === EventTypes.EVENT) return true;
    // if (this.props.selectedEvent?.type === EventTypes.EVENT) return true;
    return false;
  }

  get navActiveItem() {
    if (this.isTypeEvent) return 'event-tracking';
    if (this.isTypeVol) return 'vol-tracking';
    return null;
  }

  get showColDfd() {
    if (this.isTypeEvent) return false;
    if ([Statuses.PENDING, Statuses.REJECTED].includes(this.props.queryParams.reviewStatus)) return false;
    return true;
  }

  get showColSubmitDate() {
    return [Statuses.PENDING, Statuses.REJECTED].includes(this.props.queryParams?.reviewStatus);
  }

  get showColHours() {
    return !this.isTypeEvent;
  }

  get showColStatus() {
    return !this.isTypeEvent;
  }

  get isAdmin() {
    return this.props.currentUser?.employment?.employee?.role === EmployeeRoles.ADMIN;
  }

  onClickTrack(eventType) {
    const {queryParams, selectedEvent} = this.props;
    const {volEventShiftId} = queryParams;
    const axParams = {eventType};
    if (selectedEvent) {
      axParams.eventId = selectedEvent.id;
      if (selectedEvent.volEventShiftIds?.length === 1) {
        axParams.shiftId = selectedEvent.volEventShiftIds[0];
      } else if (volEventShiftId) {
        axParams.shiftId = volEventShiftId;
      }
    }
    this.props.modalVteNewOpen(axParams);
  }

  onClickTrackVol() {
    this.onClickTrack(EventTypes.VOL_OPP);
  }
  onClickTrackEvent() {
    this.onClickTrack(EventTypes.EVENT);
  }

  // onCloseTrackModal() {
  //   this.props.modalVteNewClose();
  // }

  onSelectPage(page) {
    this.props.setQueryParams({page});
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.props.setQueryParams({
      startDate: startDateStr,
      endDate: endDateStr,
      page: null,
    });
  }

  onSelectEventType(eventType) {
    const {selectedEvent} = this.props;
    const newParams = {eventType, page: null};
    if (eventType && selectedEvent && (eventType !== selectedEvent.type)) {
      newParams.volEventId = null;
      newParams.volEventShiftId = null;
    }
    if (eventType === EventTypes.EVENT) {
      newParams.reviewStatus = null;
    }
    this.props.setQueryParams(newParams);
  }

  onSelectEntryType(entryType) {
    const newParams = {entryType, page: null};
    this.props.setQueryParams(newParams);
  }

  onSelectShift(volEventShiftId) {
    this.props.setQueryParams({volEventShiftId, page: null});
  }

  onClickClearFilters() {
    this.props.setQueryParams({startDate: null, endDate: null, employeeId: null, entryType: null, volEventId: null, volEventShiftId: null, page: null});
  }

  onClickApprove(volTimeEntry, event) {
    event.target.textContent = 'Approving...';
    this.props.approve(volTimeEntry.id);
  }

  onClickReject(volTimeEntry, event) {
    event.target.textContent = 'Restricting...';
    this.props.reject(volTimeEntry.id);
  }

  onChangeEvent(volEvent) {
    const {queryParams} = this.props;
    const newParams = {volEventId: volEvent?.id, page: null, volEventShiftId: null};
    if (queryParams.eventType && volEvent?.type && (queryParams.eventType !== volEvent.type)) {
      newParams.eventType = volEvent.type;
    }
    this.props.setQueryParams(newParams);
  }

  onChangeSelected(vteId, event) {
    const isSelected = event.target.checked;
    this.setState((state) => {
      const selectedVteIds = {...state.selectedVteIds};
      selectedVteIds[vteId] = isSelected;
      return {selectedVteIds};
    });
  }

  onChangeSelectAll(event) {
    const isSelected = event.target.checked;
    const selectedVteIds = {};
    if (isSelected) {
      (this.props.volTimeEntries || []).forEach((vte) => {
        selectedVteIds[vte.id] = true;
      })
    }
    this.setState({selectedVteIds});
  }

  onClickApproveSelected() {
    this.setState({showBulkApproveConfirm: true});
  }
  onClickRejectSelected() {
    this.setState({showBulkRejectConfirm: true});
  }

  onResponseApproveSelected(didConfirm) {
    this.setState({showBulkApproveConfirm: false});
    if (didConfirm) {
      this.props.bulkApprove(this.effectivelySelectedIds);
    }
  }
  onResponseRejectSelected(didConfirm) {
    this.setState({showBulkRejectConfirm: false});
    if (didConfirm) {
      this.props.bulkReject(this.effectivelySelectedIds);
    }
  }

  onChangeEmployee(employee) {
    this.props.setQueryParams({
      employeeId: employee?.id,
      page: null,
    });
  }

  onClickEditEntry(entry, event) {
    event.preventDefault();
    this.props.modalVteEditOpen(entry);
  }

  renderRow(entry) {
    const {company} = this.props;
    const {employee, volEvent: event, nonprofit, volEventShift: shift} = entry;
    const isSelected = !!this.state.selectedVteIds[entry.id];
    const dfd = numeral((entry.dfdAmountInCents || 0) / 100).format('$0,0');
    const entryTypeLabel = (entryTypeLabelMap[entry.entryType] || '');
    const entryTypeColor =  entryTypeColorMap[entry.entryType] || '';
    const isFuture = (entry.reviewStatus === Statuses.PENDING) && moment.utc().isBefore(entry.date);
    const reviewStatus = isFuture ? 'future' : entry.reviewStatus;
    const statusLabel = (statusLabelMap[reviewStatus] || '');
    const statusColor =  statusColorMap[reviewStatus] || '';

    let dateTimeStr = moment.utc(entry.date).format('MMM D, YYYY');
    if (shift) {
      dateTimeStr += ' ' + moment.utc(shift.startTimeStr, 'HH:mm').format('h:mma');
    }

    const empName = employee ? `${employee.firstName} ${employee.lastName}` : null;

    return (
      <tr key={entry.id}>
        <td>
          <div className="menu-cell">
            <div className="ca-tracking-table-cb-emp-con">
              {this.showBulkActions && (
                <Checkbox onChange={this.onChangeSelected.bind(this, entry.id)} checked={isSelected} />
              )}
              {employee && (
                this.isAdmin ? (
                  <Link href={paths.cadminEmployee(company.slug, employee.id)} className="pink-hover">{empName}</Link>
                ) : (
                  <span>{empName}</span>
                )
              )}
            </div>
            <EllipsisMenu usePortal>
              <Link onClick={this.onClickEditEntry.bind(this, entry)}><Icon.Pencil />Edit</Link>
            </EllipsisMenu>
          </div>
        </td>
        <td>
          {event ? (
            <Link className="pink-hover" href={paths.cadminVolEventsView(company.slug, event.id)}>{event.title}</Link>
          ) : (
            !!entry.custom && `Custom input: ${entry.custom}`
          )}
          <br />
          <span className="faint">{dateTimeStr}</span>
        </td>
        {this.showColHours && (
          <td className="right nowrap">{format.hours(entry.minutes)}</td>
        )}
        <td className="right">{numeral(entry.attendeeCount).format('0,0')}</td>
        {this.showColDfd && (
          <td className="right">{dfd}</td>
        )}
        <td><span className={`tag ${entryTypeColor}`}>{entryTypeLabel}</span></td>
        {this.showColStatus && (
          <td><span className={`tag ${statusColor}`}>{statusLabel}</span></td>
        )}
        {this.showColSubmitDate && (
          <td className="right nowrap">{moment.utc(entry.createdAt).format('MMM D, YYYY')}</td>
        )}
        <td>
          {nonprofit && <Link className="pink-hover" href={paths.nonprofit(nonprofit)}>{nonprofit.name}</Link>}
        </td>
      </tr>
    );
  }

  renderSelectedActions() {
    const {bulkRejectPending, bulkApprovePending} = this.props;
    if (!this.showBulkActions) return null;
    const count = this.effectivelySelectedIds.length;
    const disabled = !count || bulkRejectPending || bulkApprovePending;
    const selectionClass = count ? 'strong' : 'faint';

    return (<>
      <p className={selectionClass}>{`${count} ${format.pluralize('entry', count)} selected:`}</p>
      <button disabled={disabled} className="btn small green" onClick={this.onClickApproveSelected}>{bulkApprovePending ? 'Approving...' : 'Approve'}</button>
      <button disabled={disabled} className="btn small danger" onClick={this.onClickRejectSelected}>{bulkRejectPending ? 'Restricting...' : 'Restrict'}</button>
      <div className="ca-box-header-controls-sep" />
    </>);
  }

  renderModals() {
    const {company} = this.props;
    const {showBulkApproveConfirm, showBulkRejectConfirm} = this.state;
    const {modalVteNewIsOpen, modalVteEditIsOpen} = this.props;
    const selectedCount = this.effectivelySelectedIds.length;
    const hasDfd = !!company.dfdPerHourInCents;

    return (<>
      {modalVteNewIsOpen && <ModalVteNew />}
      {modalVteEditIsOpen && <ModalVteEdit />}
      {showBulkApproveConfirm && (
        <Confirm onResponse={this.onResponseApproveSelected} confirmBtnLabel="Approve" confirmBtnColor="green">
          <p>Approve the {selectedCount} selected time {format.pluralize('entry', selectedCount)}?</p>
          {hasDfd && (<>
            <p>The Dollars For Doers amount for each entry will be calculated upon approval. The amount is subject to per-employee limit and company budget.</p>
            <p>The company will be charged for the DFD amounts each Wednesday, and the gifts will be sent then.</p>
          </>)}
        </Confirm>
      )}
      {showBulkRejectConfirm && (
        <Confirm onResponse={this.onResponseRejectSelected} confirmBtnLabel="Restrict" confirmBtnColor="danger">
          <p>Restrict the {selectedCount} selected time {format.pluralize('entry', selectedCount)}?</p>
        </Confirm>
      )}
    </>);
  }

  renderFilters() {
    const {currentFiscalYear, queryParams, company, selectedEvent} = this.props;
    const {startDate, endDate, employeeId, volEventId, volEventShiftId, eventType, entryType} = queryParams;
    const hasManyShifts = selectedEvent?.volEventShifts?.length > 1;
    const shiftToOpt = (shift) => ({label: eventHelpers.formatShiftTime(shift, false, true), value: shift.id});
    const showReset = !!(startDate || endDate || volEventId || volEventShiftId || employeeId || entryType);

    return (
      <div className="ca-main-filters">
        <div className={`ca-main-filters-filter ${(startDate || endDate) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Date Range</label>
          <DateRangePicker leftAlign onSelect={this.onSelectDateRange} currentFiscalYear={currentFiscalYear} startDateStr={startDate} endDateStr={endDate} label="All Dates" />
        </div>
        <div className={`ca-main-filters-filter ${(eventType) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Event Type</label>
          <StandardSelect options={eventTypeOptions} value={eventType} onSelect={this.onSelectEventType} label="All Types" allowClear />
        </div>
        <div className={`ca-main-filters-filter ${(volEventId) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Event</label>
          <EntityInput.VolEvent volEventId={volEventId} onChange={this.onChangeEvent} />
        </div>
        <div className={`ca-main-filters-filter ${(volEventShiftId) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Shift</label>
          <StandardSelect options={(selectedEvent?.volEventShifts || []).map(shiftToOpt)} value={volEventShiftId} onSelect={this.onSelectShift} disabled={!hasManyShifts} allowClear label="All Shifts" />
        </div>
        <div className={`ca-main-filters-filter ${(employeeId) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Employee</label>
          <EntityInput.Employee employeeId={employeeId} onChange={this.onChangeEmployee} />
        </div>
        <div className={`ca-main-filters-filter ${(entryType) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Entry Type</label>
          <StandardSelect options={entryTypeOptions} value={entryType} onSelect={this.onSelectEntryType} label="All Types" allowClear />
        </div>
        {showReset && (
          <div className="ca-main-filters-filter">
            <label className="ca-main-filters-filter-label">&nbsp;</label>
            <button onClick={this.onClickClearFilters} className="btn secondary blue">Clear Filters</button>
          </div>
        )}
      </div>
    );
  }

  renderMain() {
    const {volTimeEntries, searchPending, currentFiscalYear, queryParams, pagination, company, attnNums} = this.props;
    const isAllSelected = volTimeEntries && !volTimeEntries.find(vte => !this.state.selectedVteIds[vte.id]);
    const pendingCount = attnNums?.pendingVolTimeEntries || 0;
    const showZeroMsg = volTimeEntries && !volTimeEntries.length;

    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Entries</h1>
          <div className="ca-box-header-controls">
            {this.renderSelectedActions()}
            <a className="btn secondary small icon" href={paths.cadminVolTimeEntriesCsv(company.slug, queryParams)}><Icon.CommonFileTextDownload /> CSV</a>
          </div>
        </div>
        {!this.isTypeEvent && (
          <div className="ca-box-tabs">
            <div className="ca-box-tabs-tabs">
              {[null, Statuses.PENDING, Statuses.APPROVED, Statuses.REJECTED].map((reviewStatus) => {
                const label = statusLabelMap[reviewStatus] || 'All';
                const activeClass = (queryParams.reviewStatus == reviewStatus) ? 'active' : '';
                const sepClass = (reviewStatus === Statuses.REJECTED) ? 'separate' : '';
                return (
                  <Link key={reviewStatus} className={`ca-box-tabs-tab ${activeClass} ${sepClass}`} href={paths.cadminVolTracking(company.slug, {...queryParams, page: null, reviewStatus})}>
                    {label}
                    {!!((reviewStatus === Statuses.PENDING) && pendingCount) && <span className="ca-box-tabs-tab-attn">{pendingCount}</span>}
                  </Link>
                );
              })}
            </div>
          </div>
        )}
        <div className="ca-box-body">
          {searchPending ? <>
            <Icon.Loading className="ca-box-loading" />
          </> : <>
            <ScrollTable tableClassName="ca-box-table ca-tracking-table">
              <thead>
                <tr>
                  <th>
                    <div className="ca-tracking-table-cb-emp-con">
                      {this.showBulkActions && (
                        <Checkbox checked={isAllSelected} onChange={this.onChangeSelectAll.bind(this)} />
                      )}
                      <span>Employee</span>
                    </div>
                  </th>
                  <th className="min-200">Event</th>
                  {/* <th className="right nowrap">Date</th> */}
                  {this.showColHours && (
                    <th className="right nowrap">Vol Hrs</th>
                  )}
                  <th className="right"># Ppl</th>
                  {this.showColDfd && (
                    <th className="right">Dollars for Doers</th>
                  )}
                  <th>Entry Type</th>
                  {this.showColStatus && (
                    <th>Status</th>
                  )}
                  {this.showColSubmitDate && (
                    <th className="right nowrap">Submit Date</th>
                  )}
                  <th className="min-200">Nonprofit</th>
                </tr>
              </thead>
              <tbody>
                {volTimeEntries && volTimeEntries.map(this.renderRow)}
              </tbody>
            </ScrollTable>
            {showZeroMsg && (
              <p>No results to show. You may need to deselect some filters.</p>
            )}
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </>}
        </div>
      </div>
    );
  }

  render() {
    const {company} = this.props;
    if (!company) return <PageLoading />;

    const title = this.isTypeVol
      ? 'Volunteers & Hours'
      : this.isTypeEvent
        ? 'Event Attendees'
        : 'Volunteers & Attendees';

    return (
      <CadminLayout className="ca-tracking" company={company} activeItem={this.navActiveItem}>
        <Meta title={`${title} | Millie`} />
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">{title}</h1>
          <div className="ca-main-head-actions">
            {!this.isTypeEvent && (
              <button className="btn special orange icon" onClick={this.onClickTrackVol}><Icon.AthleticsJumpingPerson /> Track Volunteers & Hours</button>
            )}
            {!this.isTypeVol && (
              <button className="btn special groups icon" onClick={this.onClickTrackEvent}><Icon.AthleticsJumpingPerson /> Track Attendees</button>
            )}
          </div>
        </div>
        {/* <pre>{JSON.stringify({queryParams, selectedEvent}, null, 4)}</pre> */}
        {this.renderFilters()}
        {this.renderMain()}
        {this.renderModals()}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  company: CadminSlx.company(state),
  attnNums: CadminSlx.attnNums(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  loadPending: PageSlx.loadPending(state),
  searchPending: PageSlx.searchPending(state),
  queryParams: PageSlx.queryParams(state),
  pagination: PageSlx.pagination(state),
  volTimeEntries: PageSlx.volTimeEntries(state),
  selectedEvent: PageSlx.selectedVolEvent(state),
  bulkApprovePending: PageSlx.bulkApprovePending(state),
  bulkRejectPending: PageSlx.bulkRejectPending(state),

  modalVteNewIsOpen: ModalVteNewDuck.Slx.isOpen(state),
  modalVteEditIsOpen: ModalVteEditDuck.Slx.isOpen(state),
});

const dispatchToProps = (dispatch) => ({
  setQueryParams: (newParams) => dispatch(PageAx.setQueryParams(newParams)),
  approve: (volTimeEntryId) => dispatch(PageAx.approve(volTimeEntryId)),
  reject: (volTimeEntryId) => dispatch(PageAx.reject(volTimeEntryId)),
  bulkApprove: (volTimeEntryIds) => dispatch(PageAx.bulkApprove(volTimeEntryIds)),
  bulkReject: (volTimeEntryIds) => dispatch(PageAx.bulkReject(volTimeEntryIds)),

  modalVteNewOpen: (params) => dispatch(ModalVteNewDuck.Ax.open(params)),
  modalVteEditOpen: (vte) => dispatch(ModalVteEditDuck.Ax.open(vte)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminVolTracking);
