import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import ClientTimestamp    from 'app/components/common/client-timestamp';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import ModalSlackGroup    from 'app/components/company-admin/slack/modal-channel-group';
import GroupCard          from 'app/components/groups/group-card';
import PageLoading        from 'app/components/layout/page-loading';
import GroupsDuck         from 'app/ducks/company-admin/groups';
import PageDuck           from 'app/ducks/company-admin/page-groups-view';
import Ps                 from 'app/helpers/publish-statuses';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import AuthSlx            from 'app/selectors/auth';
import CadminSlx          from 'app/selectors/company-admin/';

class PageCadminGroupsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      deletePending: false,
      archivePending: false,
      unarchivePending: false,
      markEndedPending: false,
      showSlackModal: false,
    };

    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickArchive = this.onClickArchive.bind(this);
    this.onClickUnarchive = this.onClickUnarchive.bind(this);
    this.onClickSlackSettings = this.onClickSlackSettings.bind(this);
    this.onCloseSlackSettings = this.onCloseSlackSettings.bind(this);
  }

  async onClickDelete(event) {
    event.preventDefault();
    const msg = `Are you sure you want to delete this group? The group will no longer be visible to employees or to admins anywhere.\nThis will negatively affect analytics/reporting. We recommend archiving instead.\nThis can not be undone.`;
    const didConfirm = await prompts.confirm({msg, danger: 'Delete Group', doubleConfirm: true});
    if (!didConfirm) return;
    this.setState({deletePending: true});
    await this.props.delete(this.props.company.id, this.props.group.id);
    this.setState({deletePending: false});
  }
  async onClickArchive(event) {
    event.preventDefault();
    const didConfirm = await prompts.confirm({confirmBtnLabel: 'Archive Group', msg: 'Are you sure you want to archive this group?\nIt will still show up in the admin area and analytics, but will no longer be discoverable by employees.'});
    if (!didConfirm) return;
    this.setState({archivePending: true});
    await this.props.archive(this.props.company.id, this.props.group.id);
    this.setState({archivePending: false});
  }
  async onClickUnarchive(event) {
    event.preventDefault();
    const didConfirm = await prompts.confirm({confirmBtnLabel: 'Restore Group', msg: 'Are you sure you want to restore this group back to the published state?\nIt will become discoverable by employees again.'});
    if (!didConfirm) return;
    this.setState({unarchivePending: true});
    await this.props.unarchive(this.props.company.id, this.props.group.id);
    this.setState({unarchivePending: false});
  }

  onClickSlackSettings() {
    this.setState({showSlackModal: true});
  }
  onCloseSlackSettings() {
    this.setState({showSlackModal: false});
  }

  get role() {
    return this.props.currentUser?.employment?.employee?.role || undefined;
  }

  get isActive() {
    return this.props.group?.publishStatus === Ps.ACTIVE;
  }

  get isActiveish() {
    return Ps.isActiveish(this.props.group?.publishStatus);
  }

  get status() {
    const {group} = this.props;
    if (!group) return ['status', 'gray'];
    const ps = group.publishStatus;
    return [Ps.name(ps), Ps.color(ps)];
  }

  get hasSlack() {
    const {company} = this.props;
    return !!(company && company.isSlackSetup && company.mainSlackChannelId);
  }

  renderStatusPicker() {
    const {deletePending, archivePending, unarchivePending, markEndedPending} = this.state;
    if (deletePending) return 'deleting...';
    if (archivePending) return 'archiving...';
    if (markEndedPending) return 'ending...';
    if (unarchivePending) return 'restoring...';

    const {company, group} = this.props;
    if (!group) return null;
    const ps = group.publishStatus;
    const perms = Ps.getPerms('Group', this.role, company.settingsApprovals, ps);
    const [label, color] = this.status;
    const showPublish = perms.canEdit && (ps === Ps.DRAFT);
    const showArchive = perms.canEdit && (ps === Ps.ACTIVE);
    const showUnarchive = perms.canEdit && (ps === Ps.ARCHIVED);
    const showReview = perms.canPublish && (ps === Ps.PENDING);
    const showDelete = perms.canDelete && (ps !== Ps.DELETED);

    const target = (
      <div className="ca-ps-picker">
        <span>Status</span>
        <div className={`ca-ps-picker-tag ${color}`}>{label}</div>
        {(ps !== Ps.DELETED) && <Icon.Caret className="ca-ps-picker-caret" direction="down" />}
      </div>
    );
    return (
      <EllipsisMenu target={target}>
        {showPublish   && <Link href={paths.group(group.id, {psAction: 'publish'})} onClick={this.onClickPublish}>Publish</Link>}
        {showReview    && <Link href={paths.group(group.id, {psAction: 'review'})}  onClick={this.onClickReview}>Approve or Restrict</Link>}
        {showUnarchive && <a href="#" onClick={this.onClickUnarchive}>Restore (un-archive)</a>}
        {showArchive   && <a href="#" onClick={this.onClickArchive}>Archive</a>}
        {showDelete    && <a href="#" onClick={this.onClickDelete}>Delete</a>}
      </EllipsisMenu>
    );
  }

  renderChangeLog() {
    const {company, changeLogs} = this.props;
    if (!changeLogs?.length) return null;

    return (<>
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">History</h1>
        </div>
        <div className="ca-box-body">
          <table className="ca-box-table">
            <thead>
              <tr>
                <th>Event</th>
                <th>Date</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              {changeLogs.map((change) => {
                return (
                  <tr key={change.id}>
                    <td>{Ps.changeName(change)}</td>
                    <td><ClientTimestamp.DateTimeTz timestamp={change.createdTs} /></td>
                    <td>
                      {!!change.employeeName && <Link className="pink-hover" href={paths.cadminEmployee(company?.slug, change.employeeId)}>{change.employeeName}</Link>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>);
  }

  renderMain() {
    const {company, group, changeLogs} = this.props;
    if (!group) return null;

    return (<>
      <div className="ca-main-ctas">
        <Link className="btn small icon secondary" href={paths.group(group.id)}><Icon.BrowserPageText />Employee View</Link>
        <Link className="btn small icon secondary" href={paths.cadminGroupsEdit(company.slug, group.id)}><Icon.Pencil />Edit</Link>
        {this.isActiveish && (<>
          <Link className="btn small icon secondary" href={paths.cadminGroupEmployees(company.slug, {groupId: group.id})}><Icon.UserMultipleNeutral2 /> Members</Link>
          <Link className="btn small icon secondary" href={paths.cadminVolEvents(company.slug, {groupId: group.id})}><Icon.CalendarDate /> Events</Link>
          {this.hasSlack && (
            <button className="btn small icon secondary" onClick={this.onClickSlackSettings}><Icon.LogoSlack />Slack Settings</button>
          )}
        </>)}
      </div>

      <div className="ca-main-entity-preview group">
        <h2 className="ca-main-entity-preview-title">Group Listing Preview</h2>
        <GroupCard group={group} />
      </div>

      {this.renderChangeLog()}
    </>);
  }

  render() {
    const {company, group} = this.props;
    const {showSlackModal} = this.state;
    if (!company) return <PageLoading />;
    const name = group?.name || 'Group';

    return (
      <CadminLayout className="ca-group-vw" company={company} activeItem="Groups">
        <Meta title={`${name} | Millie`} />
        <div className="ca-main-head">
          <Link className="ca-main-head-back" href={paths.cadminGroups(company.slug)}><Icon.Caret direction="left" /> Back to Groups</Link>
          <h1 className="ca-main-head-h1">{name}</h1>
          <div className="ca-main-head-actions">
            {this.renderStatusPicker()}
          </div>
        </div>
        {this.renderMain()}
        {showSlackModal && (
          <ModalSlackGroup group={group} onClose={this.onCloseSlackSettings} />
        )}
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentUser: AuthSlx.currentUser(state),
  group: PageDuck.Slx.group(state),
  changeLogs: PageDuck.Slx.changeLogs(state),
});

const dispatchToProps = (dispatch) => ({
  delete:    (companyId, volEventId) => dispatch(GroupsDuck.Ax.delete(companyId, volEventId)),
  archive:   (companyId, volEventId) => dispatch(GroupsDuck.Ax.archive(companyId, volEventId)),
  unarchive: (companyId, volEventId) => dispatch(GroupsDuck.Ax.unarchive(companyId, volEventId)),
  markEnded: (companyId, volEventId) => dispatch(GroupsDuck.Ax.markEnded(companyId, volEventId)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminGroupsView);
