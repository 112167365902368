import PropTypes from 'prop-types';
import React from 'react';

import MillieApi         from 'app/apis/millie';
import AutocompleteInput from 'app/components/common/autocomplete-input';

const searchFn = async (searchStr) => {
  const response = await MillieApi.groupsFetch({searchStr});
  return response.groups;

  // .filter((group) => {
  //   const sanitizedSearch = (searchStr || '').trim().toLowerCase();
  //   if (!sanitizedSearch) return true;
  //   return (group.name || '').toLowerCase().includes(sanitizedSearch);
  // });
};

const renderResultFragment = (group) => {
  return (<>
    <div className="aci-result-main">{group.name}</div>
    <div className="aci-result-subtext">
      <div className="aci-result-left">{(group.description || '').substring(0, 100)}</div>
    </div>
  </>);
};

class GroupInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(searchStr) {
    return searchFn(searchStr);
  }

  render() {
    const { name, label, validations, onChange, selectedGroup, allowClear } = this.props;

    return (
      <AutocompleteInput
        className="aci-group"
        name={name}
        label={label}
        validations={validations}
        searchFn={this.onSearch}
        renderResultFragment={renderResultFragment}
        onChange={onChange}
        result={selectedGroup}
        searchOnEmpty
        allowClear={allowClear}
      />
    );
  }

}

GroupInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
  allowClear: PropTypes.bool,
};

GroupInput.defaultProps = {
  name: 'groupId',
  label: 'Group',
  validations: {},
  className: '',
  allowClear: false,
};

export default GroupInput;
