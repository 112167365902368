
import _ from 'lodash';

import history    from 'app/history';
import RoutingSlx from 'app/selectors/routing';

const Types = {
  SET_LOCATION: 'ROUTING_SET_LOCATION',
  SET_PAGE: 'ROUTING_SET_PAGE',
  SET_HAS_CHANGES: 'ROUTING_SET_HAS_CHANGES',
  CONFIRM_NAV_TO: 'ROUTING_CONFIRM_NAV_TO',
  CANCEL_NAV: 'ROUTING_CANCEL_NAV',
};

let getHasChanges = () => false;

const catchUnsavedChanges = () => {
  if (!__IS_CLIENT__) return;
  window.addEventListener('beforeunload', (event) => {
    const hasChanges = getHasChanges();
    if (!hasChanges) {
      delete event.returnValue;
      return;
    }
    const msg = 'You have unsaved changes. Are you sure you want to leave this page?';
    event.preventDefault();
    event.returnValue = msg;
    return msg;
  });
};
catchUnsavedChanges();

const Actions = {

  setLocation: (location) => (dispatch, getState) => {
    // clear the changes check upon navigating to new path
    const prevPath = RoutingSlx.path(getState());
    const isSameRoute = (prevPath === location.path) || location.path.startsWith('?');
    if (!isSameRoute) {
      getHasChanges = () => false;
    }

    return dispatch({ type: Types.SET_LOCATION, location });
  },

  setPage: (page) => {
    return { type: Types.SET_PAGE, page };
  },

  hasChanges: (hasChanges=true) => (dispatch, getState) => {
    getHasChanges = _.isBoolean(hasChanges) ? (() => hasChanges) : hasChanges;
  },

  navToQuery: (attrs) => (dispatch, getState) => {
    const newPath = RoutingSlx.currentPathWithNewParams(getState(), attrs);
    return dispatch(Actions.navTo(newPath));
  },

  navTo: (path, {skipConfirm=false, replace=false} = {}) => (dispatch, getState) => {
    const hasChanges = getHasChanges();
    if (!hasChanges || skipConfirm) {
      replace ? history.replace(path) : history.push(path);
    } else {
      dispatch(Actions.confirmNavTo(path));
    }
  },

  confirmNavTo: (path) => {
    return { type: Types.CONFIRM_NAV_TO, path };
  },

  cancelNav: () => {
    return { type: Types.CANCEL_NAV };
  },

};

export {
  Types,
};

export default Actions;
