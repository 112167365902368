
import CadminCompaniesAx  from 'app/actions/company-admin/companies';
import CadminSlx          from 'app/selectors/company-admin/';

const Types = {
  LOAD: 'CADMIN_PAGE_DASH_LOAD',
  UPDATE: 'CADMIN_PAGE_DASH_UPDATE',
};

const Actions = {

  load: (companyId) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companyId)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  updateCompany: (params) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminCompaniesAx.update(companyId, params));
    return dispatch({type: Types.UPDATE, promise});
  },

};

export {
  Types,
};

export default Actions;
