import PropTypes from 'prop-types';
import React from 'react';

import CheckBox from 'app/components/common/checkbox';
import Modal    from 'app/components/common/modal';

class Confirm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      hasUnderstood: false,
    };

    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChangeUnderstood = this.onChangeUnderstood.bind(this);
  }

  onChangeUnderstood(event) {
    const hasUnderstood = event.target.checked;
    this.setState({hasUnderstood});
  }

  onClickConfirm() {
    this.props.onResponse(true);
  }

  onClickCancel() {
    this.props.onResponse(false);
  }

  onCloseModal() {
    this.props.onResponse(false);
  }

  render() {
    const {title, children, className, cancelBtnLabel, confirmBtnColor, confirmBtnLabel, doubleConfirm} = this.props;
    const {hasUnderstood} = this.state;
    const confirmDisabled = doubleConfirm && !hasUnderstood;

    return (
      <Modal className={`confirm-modal ${className}`} onClose={this.onCloseModal}>
        <h1 className="confirm-modal-title">{title}</h1>
        {children}
        {doubleConfirm && (
          <div className="confirm-modal-understand">
            <CheckBox onChange={this.onChangeUnderstood} checked={hasUnderstood} id="cb-has-understood" />
            <label htmlFor="cb-has-understood">I understand, let's proceed.</label>
          </div>
        )}
        <div className="confirm-modal-actions">
          <button className="btn secondary" onClick={this.onClickCancel}>{cancelBtnLabel}</button>
          <button className={`btn ${confirmBtnColor}`} disabled={confirmDisabled} onClick={this.onClickConfirm}>{confirmBtnLabel}</button>
        </div>
      </Modal>
    );
  }

}

Confirm.propTypes = {
  onResponse: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  confirmBtnLabel: PropTypes.string,
  confirmBtnColor: PropTypes.string,
  doubleConfirm: PropTypes.bool,
};

Confirm.defaultProps = {
  className: '',
  title: 'Please Confirm',
  cancelBtnLabel: 'Cancel',
  confirmBtnLabel: 'Confirm',
  confirmBtnColor: 'blue',
  doubleConfirm: false,
};

export default Confirm;
