
import CadminCompaniesAx     from 'app/actions/company-admin/companies';
import CadminSlackChannelsAx from 'app/actions/company-admin/slack-channels';
import CadminSlackTokensAx   from 'app/actions/company-admin/slack-tokens';
import SlackTokensAx         from 'app/actions/slack-tokens';

const Types = {
  LOAD: 'CADMIN_PAGE_SLACK_SETTINGS_LOAD',
};

const Actions = {

  load: ({params: {companySlug}, query: {slackTokenId}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      dispatch(CadminSlackChannelsAx.fetchCompany(companySlug)),
      (slackTokenId ? dispatch(SlackTokensAx.get(slackTokenId)).catch(() => {}) : Promise.resolve()),
    ]).then(([[{company}]]) => {
      if (!company.slackTokenId) return null;
      return dispatch(CadminSlackTokensAx.get(company.id, company.slackTokenId));
    });
    return dispatch({type: Types.LOAD, promise});
  },

};

export {
  Types,
};

export default Actions;
