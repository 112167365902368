import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-vol-events';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import Popper             from 'app/components/common/popper';
import ScrollTable        from 'app/components/common/scroll-table';
import StandardSelect     from 'app/components/common/standard-select';
import EntityInput        from 'app/components/company-admin/common/entity-input';
import CadminLayout       from 'app/components/company-admin/layout/';
import RequireRole        from 'app/components/gating/require-role';
import PageLoading        from 'app/components/layout/page-loading';
import {
  VolEventStatuses as Statuses,
  VolEventTypes as Types,
}                         from 'app/constants';
import Ps                 from 'app/helpers/publish-statuses';
import Metrics            from 'app/metrics';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-vol-events';

const StatusLabels = {
  [null]: 'All Events',
  [Statuses.LIVE]: 'Live',
  [Statuses.ENDED]: 'Ended',
};

const getFormattedDate = (event) => {
  if (event.isOngoing) return 'Ongoing';
  if (!event.startDate) return null;
  // drop timezone info so moment doesn't convert it to local
  const startDateOnly = event.startDate.split('T')[0];
  return moment(startDateOnly).format('l');
};

const TypeLabels = {
  [Types.VOL_OPP]: 'Volunteer',
  [Types.EVENT]: 'Group',
};

const statusOptions = [
  {label: 'Live', value: Statuses.LIVE},
  {label: 'Ended', value: Statuses.ENDED},
];

const typeOptions = [
  {label: 'Volunteer', value: Types.VOL_OPP},
  {label: 'Group', value: Types.EVENT},
];

class PageCadminVolEvents extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelectStatus = this.onSelectStatus.bind(this);
    this.onSelectType = this.onSelectType.bind(this);
    this.onChangeGroup = this.onChangeGroup.bind(this);
    this.onChangePrimaryGroup = this.onChangePrimaryGroup.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
  }

  get activeItem() {
    const {type} = this.props.searchParams;
    if (type === Types.VOL_OPP) return 'vol-events';
    if (type === Types.EVENT) return 'events';
    return null;
  }

  get hasGroups() {
    return !!this.props.company?.features?.groups;
  }

  get attnNum() {
    const {type} = this.props.searchParams;
    const {pendingGroupEvents, pendingVolEvents} = this.props.attnNums || {};
    if (type === Types.VOL_OPP) return pendingVolEvents || 0;
    if (type === Types.EVENT) return pendingGroupEvents || 0;
    return (pendingVolEvents || 0) + (pendingGroupEvents || 0);
  }

  get tabList() {
    const reviewRequired = !!this.props.company?.settingsApprovals?.eventsRequired;
    if (reviewRequired || this.attnNum) return Ps.tabList;
    return Ps.tabList.filter(ps => ps !== Ps.PENDING);
  }

  onSelectStatus(status) {
    this.props.setSearchParams({status, page: 1});
  }

  onSelectType(type) {
    this.props.setSearchParams({type, page: 1});
  }

  onChangeGroup(group) {
    this.props.setSearchParams({groupId: group?.id, page: 1});
  }

  onChangePrimaryGroup(group) {
    this.props.setSearchParams({primaryGroupId: group?.id, page: 1});
  }

  onChangeNonprofit(nonprofit) {
    this.props.setSearchParams({nonprofitId: nonprofit?.id, page: 1});
  }

  onSelectPage(page) {
    this.props.setSearchParams({page});
  }

  renderNewButton() {
    const {company, searchParams: {type}} = this.props;

    return (
      <>
        {(type !== Types.EVENT) && <Link href={paths.cadminNewVolEvent(company.slug)} className="btn special orange">New Volunteer Event</Link>}
        {(type !== Types.VOL_OPP) && <Link href={paths.cadminNewVolEvent(company.slug, {type: Types.EVENT})} className="btn special groups">New Group Event</Link>}
      </>
    );
  }

  renderVolEvents() {
    const { company, volEvents } = this.props;
    return (
      <ScrollTable tableClassName="ca-box-table events">
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
            <th></th>
            <th>Date</th>
            <th>Status</th>
            <th className="min-200">Nonprofit</th>
            {this.hasGroups && (
              <th className="min-200">Primary Group</th>
            )}
            <th>Registered</th>
            <th className="min-140">Location</th>
          </tr>
        </thead>
        <tbody>
          {volEvents.map((ve) => {
            const location = ve.isRemote ? 'Remote' : `${ve.city}, ${ve.state}`;
            let spots = `${ve.totalParticipants}`;
            if (Number.isFinite(ve.totalCapacity)) {
              spots += ` / ${ve.totalCapacity}`;
            }
            const isVol = ve.type === Types.VOL_OPP;
            const isActive = ve.publishStatus === Ps.ACTIVE;
            const vteLinkText = isVol ? 'Manage Volunteers & Hours' : 'Manage Attendees';
            const analHref = isVol
              ? paths.cadminAnalytics(company.slug, {groupBy: 'employeeId', volEventId: ve.id},   Metrics.presets.volunteering.key)
              : paths.cadminAnalytics(company.slug, {groupBy: 'employeeId', groupEventId: ve.id}, Metrics.presets.groupEvents.key);
            return (
              <tr key={ve.id}>
                <td>
                  <div className="menu-cell">
                    <Link href={paths.cadminVolEventsView(company.slug, ve.id)} className="pink-hover">{ve.title}</Link>
                    <EllipsisMenu usePortal>
                      {!ve.isHidden && (<>
                        <Link href={paths.volEvent(ve.id)}><Icon.BrowserPageText />Employee View</Link>
                      </>)}
                      <Link href={paths.cadminVolEventsView(company.slug, ve.id)}><Icon.Cog1 />Manage</Link>
                      <Link href={paths.cadminEditVolEvent(company.slug, ve.id)}><Icon.Pencil />Edit</Link>
                      <Link href={paths.cadminVolTracking(company.slug, {volEventId: ve.id, eventType: ve.type})}><Icon.TaskChecklistCheck /> {vteLinkText}</Link>
                      <RequireRole>
                        <Link href={analHref}><Icon.AnalyticsBars/>Analytics</Link>
                      </RequireRole>
                    </EllipsisMenu>
                  </div>
                </td>
                <td className="icon">
                  {ve.isHidden && (
                    <Popper tagType="div" hoverTrigger popContent="Hidden Event - For Tracking Only">
                      <Icon.ViewOff />
                    </Popper>
                  )}
                </td>
                <td>
                  <span className={`ca-vol-events-type-${ve.type}`}>{TypeLabels[ve.type]}</span>
                </td>
                <td>{getFormattedDate(ve)}</td>
                {isActive
                  ? <td className={`status ${ve.status}`}>{StatusLabels[ve.status]}</td>
                  : <td className={`status`}>{Ps.name(ve.publishStatus)}</td>
                }
                <td>
                  {ve.nonprofit && (
                    <Link href={paths.nonprofit(ve.nonprofit)} className="pink-hover">{ve.nonprofit.name}</Link>
                  )}
                </td>
                {this.hasGroups && (
                  <td className="">
                    {ve.primaryGroup && (
                      <Link href={paths.group(ve.primaryGroup.id)} className="pink-hover">{ve.primaryGroup.name}</Link>
                    )}
                  </td>
                )}
                <td>{spots}</td>
                <td>{location}</td>
              </tr>
            );
          })}
        </tbody>
      </ScrollTable>
    );
  }

  renderFilters() {
    const {searchParams} = this.props;
    const {type, status, groupId, primaryGroupId, nonprofitId} = searchParams;

    return (
      <div className="ca-main-filters">
        {this.hasGroups && (
          <div className={`ca-main-filters-filter ${(type) ? 'filled' : ''}`}>
            <label className="ca-main-filters-filter-label">Type</label>
            <StandardSelect
              options={typeOptions}
              value={type || null}
              onSelect={this.onSelectType}
              allowClear
              label="All"
            />
          </div>
        )}
        <div className={`ca-main-filters-filter ${(status) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Status</label>
          <StandardSelect
            options={statusOptions}
            value={status || null}
            onSelect={this.onSelectStatus}
            allowClear
            label="All"
          />
        </div>
        {this.hasGroups && (<>
          <div className={`ca-main-filters-filter ${(primaryGroupId) ? 'filled' : ''}`}>
            <label className="ca-main-filters-filter-label">Primary Group</label>
            <EntityInput.Group onChange={this.onChangePrimaryGroup} groupId={primaryGroupId} />
          </div>
          <div className={`ca-main-filters-filter ${(groupId) ? 'filled' : ''}`}>
            <label className="ca-main-filters-filter-label">Group</label>
            <EntityInput.Group onChange={this.onChangeGroup} groupId={groupId} />
          </div>
        </>)}
        <div className={`ca-main-filters-filter ${(nonprofitId) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Nonprofit</label>
          <EntityInput.Nonprofit onChange={this.onChangeNonprofit} nonprofitId={nonprofitId} />
        </div>
      </div>
    );
  }

  render() {
    const {company, searchParams, pagination} = this.props;
    const {type} = searchParams;
    if (!company) return <PageLoading />;
    const title = (type === Types.VOL_OPP)
      ? 'Volunteer Events'
      : (type === Types.EVENT)
        ? 'Group Events'
        : 'Events';

    return (
      <CadminLayout className="ca-vol-events" company={company} activeItem={this.activeItem}>
        <Meta title="Events | Millie" />

        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">{title}</h1>
          <div className="ca-main-head-actions">
            {this.renderNewButton()}
          </div>
        </div>

        {this.renderFilters()}

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Events</h1>
            <div className="ca-box-header-controls">
              <a className="btn secondary small icon" href={paths.cadminVolEventsCsv(company.slug, searchParams)}><Icon.CommonFileTextDownload /> CSV</a>
            </div>
          </div>
          <div className="ca-box-tabs">
            <div className="ca-box-tabs-tabs">
              {this.tabList.map((ps) => {
                const isActive = searchParams.publishStatus === ps;
                const showNum = !!(this.attnNum && (ps === Ps.PENDING));
                return (
                  <Link key={ps} className={`ca-box-tabs-tab ${isActive ? 'active' : ''}`} href={paths.cadminVolEvents(company.slug, {...searchParams, page: null, publishStatus: ps})}>
                    {Ps.name(ps)}
                    {showNum && <span className="ca-box-tabs-tab-attn">{this.attnNum}</span>}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="ca-box-body">
            {this.renderVolEvents()}
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  attnNums: CadminSlx.attnNums(state),

  searchParams: PageSlx.searchParams(state),
  volEvents: PageSlx.volEvents(state),
  pagination: PageSlx.pagination(state),
});

const dispatchToProps = (dispatch) => ({
  setSearchParams: (newParams) => dispatch(PageAx.setSearchParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminVolEvents);
