
import CadminBracketsAx          from 'app/actions/company-admin/brackets';
import CadminBuilderBracketAx    from 'app/actions/company-admin/builder-bracket';
import CadminCompaniesAx         from 'app/actions/company-admin/companies';
import CadminSlx                 from 'app/selectors/company-admin/';

const Types = {
  LOAD: 'CADMIN_PAGE_BRACKETS_EDIT_LOAD',
};

const Actions = {

  load: ({params: {companySlug, bracketId}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminBuilderBracketAx.setBracketId(bracketId)),
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      dispatch(CadminBracketsAx.get(companySlug, bracketId)).then(({bracket}) => {
        dispatch(CadminBuilderBracketAx.setBracket(bracket));
        return {bracket};
      }),
      dispatch(CadminBuilderBracketAx.fetchCollections()),
    ]);
    return dispatch({type: Types.LOAD, promise, bracketId});
  },

};

export {
  Types,
};

export default Actions;
