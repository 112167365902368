
import CadminBuilderVeAx from 'app/actions/company-admin/builder-vol-event';
import CadminCompaniesAx from 'app/actions/company-admin/companies';
import {
  VolEventTypes,
}                        from 'app/constants';

const Types = {
  LOAD: 'CADMIN_PAGE_NEW_VOL_EVENT_LOAD',
};

const Actions = {

  load: ({params: {companySlug}, query: {type=VolEventTypes.VOL_OPP}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      dispatch(CadminBuilderVeAx.setVolEventId(null)),
      dispatch(CadminBuilderVeAx.setVolEvent({type}, [{volMinutes: 0}])),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};

export {
  Types,
};

export default Actions;
