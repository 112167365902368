import _ from 'lodash';
import { createSelector } from 'reselect';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import EventsDuck        from 'app/ducks/company-admin/vol-events';
import helpers           from 'app/helpers/vol-events';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_VOL_EVENTS_VIEW_LOAD',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const {companySlug, volEventId} = params;
    const promise = Promise.all([
      dispatch(EventsDuck.Ax.get(companySlug, volEventId)),
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading = (state) => state.companyAdmin.pageVolEventsView.isLoading;

  const selVolEvent = createSelector(
    [RoutingSlx.params, EntitiesSlx.volEvents, EntitiesSlx.volEventShifts, EntitiesSlx.nonprofits, EntitiesSlx.groups],
    (params, eventMap, shiftMap, nonprofitMap, groupMap) => {
      // return eventMap[params.volEventId] || null;
      const rawEvent = eventMap[params.volEventId];
      if (!rawEvent) return null;
      const volEvent = {...rawEvent};
      const nonprofit = nonprofitMap[volEvent.nonprofitId];
      volEvent.volEventShifts = (volEvent.volEventShiftIds || []).map(id => shiftMap[id]).filter(s => s);
      volEvent.nonprofit = nonprofit;
      volEvent.status = helpers.getEventStatus(volEvent);
      volEvent.primaryGroup = groupMap[volEvent.primaryGroupId] || null;
      return volEvent;
    }
  );

  const selChangeLogs = createSelector(
    [selVolEvent, EntitiesSlx.changeLogs],
    (volEvent, clMap) => {
      return (volEvent?.changeLogIds || []).map(id => clMap[id]).filter(cl => cl);
    }
  );

  return {
    isLoading: selIsLoading,
    volEvent: selVolEvent,
    changeLogs: selChangeLogs,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
