import moment from 'moment';
// import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi         from 'app/apis/backstage';
import BtnMasqueradeCompany from 'app/components/backstage/common/btn-masquerade-company';
import BackstageLayout      from 'app/components/backstage/layout';
import ModalEditCompany     from 'app/components/backstage/modal-edit-company';
import Link                 from 'app/components/common/link';
import StandardInput        from 'app/components/common/standard-input';
import StandardSelect       from 'app/components/common/standard-select';
import config               from 'app/config';
import {
  CompanyCustomerTypes as CustomerTypes,
  CompanyCustomerStatuses as CustomerStatuses,
  CompanyCustomerTypeNames as TypeNames,
  CompanyCustomerStatusNames as StatusNames,
}                           from 'app/constants';
import history              from 'app/history';
import paths                from 'app/paths';
import RoutingSlx           from 'app/selectors/routing';

const custTypeOpts = Object.values(CustomerTypes).map((ct) => {
  const name = TypeNames[ct] || ct;
  return {value: ct, label: name};
});
const custStatusOpts = Object.values(CustomerStatuses).map((cs) => {
  const name = StatusNames[cs] || cs;
  return {value: cs, label: name};
});

class BackstageCompaniesPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      companies: null,
      showNewCompanyModal: false,
      searchStr: null,
    };

    this.onClickNewCompany = this.onClickNewCompany.bind(this);
    this.onCloseNewCompanyModal = this.onCloseNewCompanyModal.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onChangeCustomerType = this.onChangeCustomerType.bind(this);
    this.onChangeCustomerStatus = this.onChangeCustomerStatus.bind(this);
  }

  componentDidMount() {
    backstageApi.companiesFetch().then(({companies}) => {
      this.setState({companies});
    });
  }

  pathWithNewParams(newParams={}) {
    return paths.bsCompanies({...this.props.query, page: 1, ...newParams});
  }

  updateParams(newParams) {
    const path = this.pathWithNewParams(newParams);
    history.push(path);    
  }

  get customerType() {
    if (this.props.query.customerType == null) return CustomerTypes.CUSTOMER;
    if (this.props.query.customerType === '*') return null;
    return this.props.query.customerType || null;
  }
  get customerStatus() {
    if (this.props.query.customerStatus == null) return null;
    if (this.props.query.customerStatus === '*') return null;
    return this.props.query.customerStatus || null;
  }

  onClickNewCompany() {
    this.setState({showNewCompanyModal: true});
  }

  onCloseNewCompanyModal() {
    this.setState({showNewCompanyModal: false});
  }

  onChangeSearch(event) {
    const searchStr = event.target.value || '';
    this.setState({searchStr});
  }

  onChangeCustomerType(customerType) {
    if (customerType == null) customerType = '*';
    this.updateParams({customerType});
  }
  onChangeCustomerStatus(customerStatus) {
    if (customerStatus == null) customerStatus = '*';
    this.updateParams({customerStatus});
  }

  getVisibleCompanies() {
    const {companies, searchStr} = this.state;
    const {customerStatus, customerType} = this;
    if (!companies) return null;
    const searchStrFixed = (searchStr || '').trim().toUpperCase().replace(/[\W_]+/g, '');
    return companies.filter((company) => {
      if (customerStatus != null && company.customerStatus !== customerStatus) return false;
      if (customerType   != null && company.customerType   !== customerType)   return false;
      if (searchStrFixed) {
        if (!(company.name || '').toUpperCase().replace(/[\W_]+/g, '').includes(searchStrFixed)) return false;
      }
      return true;
    });
  }

  renderTable() {
    const companies = this.getVisibleCompanies();
    if (!companies) return 'Loading...';
    if (!companies.length) return 'No results.';

    return (<>
      <div>{`${companies.length} results`}</div>
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th>Logo</th>
            <th>Name</th>
            <th className="right">Employees</th>
            <th>Customer Type</th>
            <th>Customer Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => {
            const customerUrl = company.stripeCustomerId && `${config.stripeDashboardUrl}/customers/${company.stripeCustomerId}`;

            return (<tr key={company.id}>
              <td className="page-bs-companies-img-field">
                <img src={company.logoUrl} alt={company.name} />
              </td>
              <td><Link className="pink-hover" href={paths.bsCompany(company.id)}>{company.name}</Link></td>
              <td className="right">{company.employeeCount} / {company.saasEmployeeCap}</td>
              <td>{TypeNames[company.customerType] || company.customerType}</td>
              <td>{StatusNames[company.customerStatus] || company.customerStatus}</td>
              <td>
                <Link className="btn small secondary" href={paths.bsCompany(company.id)}>Manage</Link>
                {/* <a className="btn small secondary" target="blank" href={customerUrl || '#'}>View in Stripe</a> */}
                {/* <BtnMasqueradeCompany id={company.id} /> */}
                {/* <Link className="btn small secondary danger" href={paths.cadminDashboardView(company.slug, company.companyDashboardId)}>Admin</Link> */}
              </td>
            </tr>);
          })}
        </tbody>
      </table>
    </>);
  }

  renderFilters() {
    const {searchStr} = this.state;

    return (
      <div className="bs-page-filters">
        <div className="bs-page-filters-filter">
          <label>Search</label>
          <StandardInput value={searchStr || ''} onChange={this.onChangeSearch} label="Search..." name="searchStr" />
        </div>
        <div className="bs-page-filters-filter">
          <label>Customer Type</label>
          <StandardSelect value={this.customerType} onSelect={this.onChangeCustomerType} options={custTypeOpts} allowClear />
        </div>
        <div className="bs-page-filters-filter">
          <label>Customer Status</label>
          <StandardSelect value={this.customerStatus} onSelect={this.onChangeCustomerStatus} options={custStatusOpts} allowClear />
        </div>
      </div>
    );
  }

  render() {
    const { showNewCompanyModal } = this.state;
    return (
      <BackstageLayout>
        <div className="bs-page page-bs-companies">
          <div className="page-bs-companies-head">
            <h1 className="bs-page-h1">Companies</h1>
            <div className="page-bs-companies-head-spacer" />
            <button className="btn blue small" onClick={this.onClickNewCompany}>New Company</button>
          </div>
          {this.renderFilters()}
          <br /><br /><br />
          {this.renderTable()}
        </div>
        {showNewCompanyModal && (
          <ModalEditCompany onClose={this.onCloseNewCompanyModal} />
        )}
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  url: RoutingSlx.url(state),
  path: RoutingSlx.path(state),
  query: RoutingSlx.query(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageCompaniesPage);
