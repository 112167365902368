
import CadminBuilderAx     from 'app/actions/company-admin/builder-campaign';
import CadminCompaniesAx   from 'app/actions/company-admin/companies';
import CadminCampaignsAx   from 'app/actions/company-admin/campaigns';
import CadminVolEventsDuck from 'app/ducks/company-admin/vol-events';
import Ps                  from 'app/helpers/publish-statuses';
import RoutingSlx          from 'app/selectors/routing';

const Types = {
  LOAD: 'CADMIN_PAGE_EDIT_CAMPAIGN_LOAD',
};

const Actions = {

  load: ({params: {companySlug, campaignId}, path}) => (dispatch, getState) => {
    // skip when page is already loaded
    const prevPath = RoutingSlx.path(getState());
    if (prevPath === path) return;

    const promise = Promise.all([
      dispatch(CadminBuilderAx.setCampaignId(campaignId)),
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      dispatch(CadminCampaignsAx.get(companySlug, campaignId)).then(({campaign, driveGoods=[]}) => {
        dispatch(CadminBuilderAx.setCampaign(campaign, driveGoods));
        return {campaign};
      }),
      dispatch(CadminVolEventsDuck.Ax.search(companySlug, {campaignId, publishStatus: Ps.nonDeleted.join(',')})),
    ]);
    return dispatch({type: Types.LOAD, promise, campaignId});
  },

};

export {
  Types,
};

export default Actions;
