import { createSelector } from 'reselect';

import config      from 'app/config';
import Ps          from 'app/helpers/publish-statuses';
import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-vol-event';
import EntitiesSlx from 'app/selectors/entities';

const selVolEvent = createSelector(
  [BuilderSlx.saveAttrs, BuilderSlx.shifts, CadminSlx.company, EntitiesSlx.employees, EntitiesSlx.groups],
  (saveAttrs, shifts, company, employees, groups) => {
    return {
      ...saveAttrs,
      publishStatus: Ps.DRAFT,
      isSystemEvent: !!(company && company.id === config.systemEventsCompanyId),
      shifts,
      company,
      leadEmployee: employees[saveAttrs.leadEmployeeId],
      groups: saveAttrs.groupIds.map(id => groups[id]).filter(g => g),
    };
  }
);

export default {
  volEvent: selVolEvent,
};
