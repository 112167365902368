import { createSelector } from 'reselect';
import _ from 'lodash';

import Ps          from 'app/helpers/publish-statuses';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selLoadPending       = state => state.companyAdmin.pageCampaigns.loadPending;
const selCampaignIds       = state => state.companyAdmin.pageCampaigns.campaignIds;
const selLiveCampaignIds   = state => state.companyAdmin.pageCampaigns.liveCampaignIds;
const selSearchPending     = state => state.companyAdmin.pageCampaigns.searchPending;
const selPagination        = state => state.companyAdmin.pageCampaigns.pagination;

const getCampaigns = (ids, campaigns, nonprofits, groups) => {
  return ids && ids.map((id) => {
    const campaign = {...campaigns[id]};
    campaign.aNonprofit = nonprofits[campaign.aNonprofitId];
    campaign.primaryGroup = groups[campaign.primaryGroupId];
    return campaign;
  });
};

const selCampaigns = createSelector(
  [selCampaignIds, EntitiesSlx.campaigns, EntitiesSlx.nonprofits, EntitiesSlx.groups],
  getCampaigns
);

const selLiveCampaigns = createSelector(
  [selLiveCampaignIds, EntitiesSlx.campaigns, EntitiesSlx.nonprofits, EntitiesSlx.groups],
  getCampaigns
);

const selQueryParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    const obj = _.pick(query, ['status','activity','primaryGroupId']);
    obj.publishStatus = Ps.isValid(query.publishStatus) ? query.publishStatus : Ps.ACTIVE;
    return obj;
  }
);

export default {
  loadPending: selLoadPending,
  campaigns: selCampaigns,
  liveCampaigns: selLiveCampaigns,
  queryParams: selQueryParams,
  pagination: selPagination,
  searchPending: selSearchPending,
};
