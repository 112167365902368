
import CadminTransactionsAx from 'app/actions/company-admin/balance-transactions';
import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import history              from 'app/history';
import paths                from 'app/paths';
import CadminSlx            from 'app/selectors/company-admin/';
import PageSlx              from 'app/selectors/company-admin/page-transactions2';

const Types = {
  LOAD: 'CADMIN_PAGE_TRANSACTIONS2_LOAD',
  SEARCH: 'CADMIN_PAGE_TRANSACTIONS2_SEARCH',
};

const Actions = {

  load: ({params: {companySlug}, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      dispatch(Actions.search(query, companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (params, companyId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const key = `page-${params.page || 1}-start-${params.startDate}-end-${params.endDate}`;
    const promise = dispatch(CadminTransactionsAx.search(companyId, params));
    return dispatch({type: Types.SEARCH, promise, key});
  },

  setQueryParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = PageSlx.queryParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminTransactions2(companySlug, params);
    history.push(path);
  },

};

export {
  Types,
};

export default Actions;
