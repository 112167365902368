import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import CampaignCard from 'app/components/common/campaign-card';

class SectionCampaigns extends React.PureComponent {
  render() {
    const { dashboard, editMode } = this.props;
    if (!dashboard.campaignIds || !dashboard.campaignIds.length) return null;
    return (
      <div className="cdash-campaigns">
        {dashboard.campaignIds.map((id) => {
          return (
            <div key={id} className="cdash-campaigns-campaign">
              <CampaignCard {...{id}} ghostInactive={editMode} />
            </div>
          );
        })}
      </div>
    );
  }
}

SectionCampaigns.propTypes = {
  dashboard: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default SectionCampaigns;
