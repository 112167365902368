
import CadminBuilderGroupAx from 'app/actions/company-admin/builder-group';
import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminGroupsAx       from 'app/actions/company-admin/groups';
import RoutingSlx           from 'app/selectors/routing';

const Types = {
  LOAD: 'CADMIN_PAGE_EDIT_GROUP_LOAD',
};

const Actions = {

  load: ({params: {groupId, companySlug}, path}) => (dispatch, getState) => {
    // skip when page is already loaded
    const prevPath = RoutingSlx.path(getState());
    if (prevPath === path) return;

    const promise = Promise.all([
      dispatch(CadminBuilderGroupAx.setGroupId(groupId)),
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      dispatch(CadminGroupsAx.get(companySlug, groupId)).then(({group}) => {
        dispatch(CadminBuilderGroupAx.setGroup(group));
        return {group};
      }),
      dispatch(CadminGroupsAx.typesFetch(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise, groupId});
  },

};

export {
  Types,
};

export default Actions;
