import React from 'react';
import PropTypes from 'prop-types';

import resizeMonitor from 'app/helpers/resize-monitor';

class ScrollTable extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refCon = React.createRef();
    this.refTable = React.createRef();

    this.state = {
      isScrollable: false,
    };

    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    resizeMonitor.addEventListener('resize', this.onResize);
  }
  componentWillUnmount() {
    resizeMonitor.removeEventListener('resize', this.onResize);
  }

  onResize({detail: {width, height}}) {
    const isScrollable = this.refTable.current.clientWidth > this.refCon.current.clientWidth;
    this.setState({isScrollable});
  }

  render() {
    const {className, tableClassName, children, rightMargin} = this.props;
    const {isScrollable} = this.state;
    const scrollableClass = isScrollable ? 'scrollable' : 'not-scrollable';

    return (
      <div className={`scrolltable ${className} ${scrollableClass}`} style={{marginRight: rightMargin * -1}}>
        <div className="scrolltable-2" style={{paddingRight: rightMargin}} ref={this.refCon}>
          <table className={tableClassName} ref={this.refTable}>
            {children}
          </table>
        </div>
      </div>
    );
  }

}

ScrollTable.propTypes = {
  className: PropTypes.string,
  tableClassName: PropTypes.string,
  rightMargin: PropTypes.number,
};

ScrollTable.defaultProps = {
  className: '',
  tableClassName: '',
  rightMargin: 36,
};

export default ScrollTable;
