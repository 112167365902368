import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Confirm from 'app/components/common/confirm';
import Icon    from 'app/components/common/icon';
import Duck    from 'app/ducks/prompts';

class Prompts extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onConfirmResponse = this.onConfirmResponse.bind(this);
  }

  onConfirmResponse(didConfirm) {
    const {confirm, confirmRespond} = this.props;
    confirmRespond(confirm.key, didConfirm);
  }

  render() {
    const {confirm} = this.props;
    if (!confirm) return null;
    const {key, opts} = confirm;
    const {msg, danger} = opts;
    const confirmOpts = _.pick(opts, ['className', 'title', 'cancelBtnLabel', 'confirmBtnLabel', 'confirmBtnColor', 'doubleConfirm']);
    if (danger) {
      confirmOpts.confirmBtnLabel = confirmOpts.confirmBtnLabel || danger;
      confirmOpts.confirmBtnColor = confirmOpts.confirmBtnColor || 'danger';
    }

    return (
      <Confirm {...confirmOpts} onResponse={this.onConfirmResponse}>
        {(msg || '').split('\n').map((p, i) => <p key={i}>{p}</p>)}
      </Confirm>
    );
  }

}

const stateToProps = (state) => ({
  confirm: Duck.Slx.confirm(state),
});

const dispatchToProps = (dispatch) => ({
  confirmRespond: (key, didConfirm) => dispatch(Duck.Ax.confirmRespond(key, didConfirm)),
});

export default connect(stateToProps, dispatchToProps)(Prompts);
