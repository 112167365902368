
import CadminCompaniesAx from 'app/actions/company-admin/companies';
import CadminGiftsDuck   from 'app/ducks/company-admin/gifts';
import history           from 'app/history';
import paths             from 'app/paths';
import CadminSlx         from 'app/selectors/company-admin/';
import PageSlx           from 'app/selectors/company-admin/page-gifts';

const Types = {
  LOAD: 'CADMIN_PAGE_GIFTS_LOAD',
  SEARCH: 'CADMIN_PAGE_GIFTS_SEARCH',
};

const Actions = {

  load: ({params: {companySlug}, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      dispatch(Actions.search(companySlug, query)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminGiftsDuck.Ax.search(companyId, params));
    return dispatch({type: Types.SEARCH, promise});
  },

  setSearchParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = PageSlx.searchParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminGifts(companySlug, params);
    history.push(path);
  },

};

export {
  Types,
};

export default Actions;
