import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import OffDonsAx            from 'app/actions/company-admin/offline-donations';
import PageAx               from 'app/actions/company-admin/page-offline-donations';
import Link                 from 'app/components/common/link';
import Icon                 from 'app/components/common/icon';
import OfflineDonationModal from 'app/components/common/offline-donation-modal';
import ScrollTable          from 'app/components/common/scroll-table';
import ModalNonprofit       from 'app/components/company-admin/match-settings/modal-nonprofit';
import PageLayout           from 'app/components/company-admin/matches/page-layout';
import PageLoading          from 'app/components/layout/page-loading';
import {
  OfflineDonationStatuses as Statuses,
}                           from 'app/constants';
import prompts              from 'app/prompts';
import CadminSlx            from 'app/selectors/company-admin/';
import PageSlx              from 'app/selectors/company-admin/page-offline-donations';

const { PENDING, APPROVED, REJECTED } = Statuses;

class PageCadminOfflineDonations extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      viewReceiptOd: null,
      imgPath: null,
      viewNonprofit: null,
    };

    this.renderStatusActions = this.renderStatusActions.bind(this);
    this.onCloseOffDonModal = this.onCloseOffDonModal.bind(this);
    this.onCloseModalNonprofit = this.onCloseModalNonprofit.bind(this);
  }

  async onClickApprove(od, event) {
    const msg = 'Approve this offline donation?'
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Approve', confirmBtnColor: 'green'});
    if (didConfirm) {
      this.props.approve(od.id);
    }
  }

  async onClickReject(od, event) {
    const msg = 'Restrict this offline donation?'
    const didConfirm = await prompts.confirm({msg, danger: 'Restrict'});
    if (didConfirm) {
      this.props.reject(od.id);
    }
  }

  onClickNonprofit(viewNonprofit, event) {
    event.preventDefault();
    this.setState({viewNonprofit});
  }

  onCloseModalNonprofit() {
    this.setState({viewNonprofit: null});
  }

  onClickReceipt(od) {
    this.setState({viewReceiptOd: od});
  }

  onCloseOffDonModal() {
    this.setState({viewReceiptOd: null});
  }

  renderStatusActions(od) {
    // return null;
    const { company } = this.props;
    const { employee, nonprofit, status, isApproving, isRejecting } = od;
    const isDisabled = isApproving || isRejecting || !company.isLaunched;
    if (status === PENDING) return (<>
      <button className="btn small secondary green" onClick={this.onClickApprove.bind(this, od)} disabled={isDisabled}>{isApproving ? 'Approving...' : 'Approve'}</button>
      <button className="btn small secondary danger" onClick={this.onClickReject.bind(this, od)} disabled={isDisabled}>{isRejecting ? 'Restricting...' : 'Restrict'}</button>
    </>);
    if (status === APPROVED) return 'Approved';
    if (status === REJECTED) return 'Restricted';
    return null;
  }

  renderList() {
    const { offlineDonations } = this.props;
    if (!offlineDonations) return null;

    return (
      <ScrollTable tableClassName="ca-box-table">
        <thead>
          <tr>
            <th>Employee</th>
            <th className="min-200">Nonprofit</th>
            <th className="right">Amount</th>
            <th className="right">Donated</th>
            <th className="right">Submitted</th>
            <th></th>
            <th className="right"></th>
          </tr>
        </thead>
        <tbody>
          {offlineDonations.map((od) => {
            const { employee, nonprofit } = od;
            return (<tr key={od.id}>
              <td>{`${employee?.firstName || ''} ${employee?.lastName || ''}`}</td>
              <td><Link onClick={this.onClickNonprofit.bind(this, nonprofit)} className="pink-hover">{nonprofit.name}</Link></td>
              <td className="right nowrap">{numeral(od.amountInCents / 100).format('$0,0.00')}</td>
              <td className="right nowrap">{moment.utc(od.donatedDate).format('MM/DD/YYYY')}</td>
              <td className="right nowrap">{moment.utc(od.createdAt).format('MM/DD/YYYY')}</td>
              <td className="receipt-img">
                {od.receiptImgPath && (
                  <Icon.ReceiptDollar onClick={this.onClickReceipt.bind(this, od)} />
                )}
              </td>
              <td className={`status-actions right nowrap match-status-${od.status}`}>{this.renderStatusActions(od)}</td>
            </tr>);
          })}
        </tbody>
      </ScrollTable>
    );
  }


  render() {
    const {company, pagination, searchParams, setSearchParams} = this.props;
    const {viewReceiptOd, viewNonprofit} = this.state;
    if (!company) return <PageLoading />;

    return (
      <PageLayout tableTitle="Offline Donations" searchParams={searchParams} setSearchParams={setSearchParams} pagination={pagination} offDonsActive>
        {this.renderList()}
        {viewNonprofit && (
          <ModalNonprofit nonprofit={viewNonprofit} onClose={this.onCloseModalNonprofit} />
        )}
        {viewReceiptOd && (
          <OfflineDonationModal imgPath={viewReceiptOd.receiptImgPath} note={viewReceiptOd.note} onClose={this.onCloseOffDonModal} />
        )}
      </PageLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  searchParams: PageSlx.searchParams(state),
  offlineDonations: PageSlx.offlineDonations(state),
  pagination: PageSlx.pagination(state),
});

const dispatchToProps = (dispatch) => ({
  approve: (odId) => dispatch(OffDonsAx.approve(odId)),
  reject: (odId) => dispatch(OffDonsAx.reject(odId)),
  refresh: (offset) => dispatch(PageAx.refresh(offset)),
  setSearchParams: (newParams) => dispatch(PageAx.setSearchParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminOfflineDonations);
