import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CompaniesAx       from 'app/actions/company-admin/companies';
import RoutingAx         from 'app/actions/routing';
import Checkbox          from 'app/components/common/checkbox';
import Meta              from 'app/components/common/meta';
import CadminLayout      from 'app/components/company-admin/layout/';
import PageLoading       from 'app/components/layout/page-loading';
import PageDuck          from 'app/ducks/company-admin/page-settings-approvals';
import CadminSlx         from 'app/selectors/company-admin/';

class PageCadminSettingsApprovals extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      campaignsRequired: null,
      eventsRequired: null,
      groupsRequired: null,
      savePending: false,
    };

    this.onChangeCampaigns = this.onChangeCampaigns.bind(this);
    this.onChangeEvents = this.onChangeEvents.bind(this);
    this.onChangeGroups = this.onChangeGroups.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  componentDidMount() {
    this.props.setHasChanges(() => this.hasChanges);
  }

  componentWillUnmount() {
    this.props.setHasChanges(false);
  }

  get currentCampaignsRequired() {
    return this.props.settings?.campaignsRequired || false;
  }
  get currentEventsRequired() {
    return this.props.settings?.eventsRequired || false;
  }
  get currentGroupsRequired() {
    return this.props.settings?.groupsRequired || false;
  }

  get campaignsRequired() {
    if (_.isBoolean(this.state.campaignsRequired)) return this.state.campaignsRequired;
    return this.currentCampaignsRequired;
  }
  get eventsRequired() {
    if (_.isBoolean(this.state.eventsRequired)) return this.state.eventsRequired;
    return this.currentEventsRequired;
  }
  get groupsRequired() {
    if (_.isBoolean(this.state.groupsRequired)) return this.state.groupsRequired;
    return this.currentGroupsRequired;
  }

  get saveAttrs() {
    return {
      campaignsRequired: this.campaignsRequired,
      eventsRequired: this.eventsRequired,
      groupsRequired: this.groupsRequired,
    };
  }

  get hasChanges() {
    if (this.currentCampaignsRequired !== this.campaignsRequired) return true;
    if (this.currentEventsRequired    !== this.eventsRequired)    return true;
    if (this.currentGroupsRequired    !== this.groupsRequired)    return true;
    return false;
  }

  onChangeCampaigns(event) {
    const campaignsRequired = event.target.checked;
    this.setState({campaignsRequired});
  }
  onChangeEvents(event) {
    const eventsRequired = event.target.checked;
    this.setState({eventsRequired});
  }
  onChangeGroups(event) {
    const groupsRequired = event.target.checked;
    this.setState({groupsRequired});
  }

  async onClickSave() {
    this.setState({savePending: true});
    await this.props.save(this.props.company.id, this.saveAttrs);
    this.setState({savePending: false, campaignsRequired: null, eventsRequired: null, groupsRequired: null});
  }

  renderSettings() {
    const {settings} = this.props;
    if (!settings) return <p>loading...</p>;

    const items = [
      {title: 'Campaigns Approvals Flow', champAllowed: true,  entityName: 'campaigns', checked: this.campaignsRequired, onChange: this.onChangeCampaigns},
      {title: 'Events Approvals Flow',    champAllowed: true,  entityName: 'events',    checked: this.eventsRequired,    onChange: this.onChangeEvents},
      {title: 'Groups Approvals Flow',    champAllowed: false, entityName: 'groups',    checked: this.groupsRequired,    onChange: this.onChangeGroups},
    ];

    return (
      <>
        {items.map((item) => {
          const onMsg  = `The approvals flow has been turned on for ${item.entityName}. ${item.champAllowed ? 'Impact Champions and ' : ''}Group Leads can submit ${item.entityName} for approval. All Access Admins can approve and publish.`;
          const offMsg = `Approvals not required for ${item.entityName}.`;
          return (<React.Fragment key={item.entityName}>
            <div className="ca-box">
            <div className="ca-box-header">
              <h1 className="ca-box-header-title">{item.title}</h1>
              <div className="ca-box-header-controls">
                <Checkbox isToggle offOk checked={item.checked} onChange={item.onChange} />
              </div>
            </div>
            <div className="ca-box-body">
              <p>{item.checked ? onMsg : offMsg}</p>
            </div>
            </div>
          </React.Fragment>);
        })}
      </>
    );
  }

  render() {
    const {company} = this.props;
    if (!company) return <PageLoading />;
    const {savePending} = this.state;
    const btnDisabled = !!(!this.hasChanges || savePending);
    const btnLabel = savePending ? 'Saving...' : 'Save Changes';

    return (
      <CadminLayout className="ca-appr" company={company} activeItem="settings-approvals">
        <Meta title="Approvals Settings | Millie" />

        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Approvals Settings</h1>
          <div className="ca-main-head-actions">
            {this.hasChanges && (
              <button className="btn small blue" disabled={btnDisabled} onClick={this.onClickSave}>{btnLabel}</button>
            )}
          </div>
        </div>

        <p>The approvals flow adds an extra layer of oversight, allowing companies to review and approve content before it's published on Millie. When enabled, Impact Champions and Group Leads can submit campaigns, groups, or events for approval, while All-Access Admins have the authority to review, approve, and publish.</p>

        {this.renderSettings()}

      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  settings: PageDuck.Slx.settings(state),
});

const dispatchToProps = (dispatch) => ({
  save: (id, attrs) => dispatch(CompaniesAx.updateSettingsApprovals(id, attrs)),
  setHasChanges: (cb) => dispatch(RoutingAx.hasChanges(cb)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminSettingsApprovals);
