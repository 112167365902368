
import { Types } from 'app/actions/company-admin/page-groups';
import utils from 'app/reducers/utils';

const initialState = {
  groupIds: null,
  summary: null,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, _action) => {
    return {...state,
      summary: null,
      groupIds: null,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, {result: {summary, groups}}) => {
    return {...state,
      summary,
      groupIds: groups.map(groups => groups.id),
    };
  },

});

export default reducer;
