import _ from 'lodash';
import { createSelector } from 'reselect';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import GroupsAx          from 'app/actions/company-admin/groups';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_GROUPS_VIEW_LOAD',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const {companySlug, groupId} = params;
    const promise = Promise.all([
      dispatch(GroupsAx.get(companySlug, groupId)),
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading = (state) => state.companyAdmin.pageGroupsView.isLoading;

  const selGroup = createSelector(
    [RoutingSlx.params, EntitiesSlx.groups],
    (params, groupMap) => {
      return groupMap[params.groupId] || null;
    }
  );

  const selChangeLogs = createSelector(
    [selGroup, EntitiesSlx.changeLogs],
    (group, clMap) => {
      return (group?.changeLogIds || []).map(id => clMap[id]).filter(cl => cl);
    }
  );

  return {
    isLoading: selIsLoading,
    group: selGroup,
    changeLogs: selChangeLogs,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
