import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminGroupsDuck     from 'app/ducks/company-admin/groups';
import history              from 'app/history';
import paths                from 'app/paths';
import reducerUtils         from 'app/reducers/utils';
import CadminSlx            from 'app/selectors/company-admin/';
import EntitiesSlx          from 'app/selectors/entities';
import RoutingSlx           from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_GROUP_EMPLOYEES_LOAD',
  SEARCH: 'CADMIN_PAGE_GROUP_EMPLOYEES_SEARCH',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(params.companySlug)),
      dispatch(Ax.search(params.companySlug, query)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params={}) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const key = `${Math.random()}`;
    const promise = dispatch(CadminGroupsDuck.Ax.groupEmployeesSearch(companyId, params));
    return dispatch({type: Types.SEARCH, key, promise});
  },

  setQueryParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = Slx.queryParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminGroupEmployees(companySlug, params);
    history.push(path);
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
  searchPending: false,
  searchKey: null,
  searchPagination: null,
  searchIds: null,
  searchFailed: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.SEARCH}_PENDING`]: (state, action) => {
    return {...state,
      searchPending: true,
      searchKey: action.key,
      searchIds: null,
      searchFailed: false,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, action) => {
    if (action.key !== state.searchKey) return state;
    return {...state,
      searchPending: false,
      searchIds: action.result.groupEmployees.map(ge => ge.id),
      searchPagination: action.result.pagination,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, action) => {
    if (action.key !== state.searchKey) return state;
    return {...state,
      searchPending: false,
      searchFailed: true,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading        = state => state.companyAdmin.pageGroupEmployees.isLoading;
  const selSearchPending    = state => state.companyAdmin.pageGroupEmployees.searchPending;
  const selSearchKey        = state => state.companyAdmin.pageGroupEmployees.searchKey;
  const selSearchPagination = state => state.companyAdmin.pageGroupEmployees.searchPagination;
  const selSearchIds        = state => state.companyAdmin.pageGroupEmployees.searchIds;
  const selSearchFailed     = state => state.companyAdmin.pageGroupEmployees.searchFailed;

  const selGroupEmployees = createSelector(
    [selSearchIds, EntitiesSlx.groupEmployees, EntitiesSlx.groups, EntitiesSlx.employees, EntitiesSlx.groupTypes],
    (ids, geMap, groupsMap, empsMap, groupTypesMap) => {
      if (!ids) return null;
      return ids.map((id) => {
        const ge = {...geMap[id]};
        ge.employee  = empsMap[ge.employeeId];
        ge.group     = groupsMap[ge.groupId];
        ge.groupType = groupTypesMap[ge.groupTypeId];
        return ge;
      });
    }
  );

  const selQueryParams = createSelector(
    [RoutingSlx.query],
    (query) => {
      const obj = _.pick(query, ['page', 'groupId', 'employeeId']);
      if (query.isAdmin === 'true')  obj.isAdmin = true;
      if (query.isAdmin === 'false') obj.isAdmin = false;
      return obj;
    }
  );

  return {
    isLoading: selIsLoading,
    queryParams: selQueryParams,
    groupEmployees: selGroupEmployees,
    pagination: selSearchPagination,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
