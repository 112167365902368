import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import EllipsisMenu    from 'app/components/common/ellipsis-menu';
import Link            from 'app/components/common/link';
import Meta            from 'app/components/common/meta';
import ScrollTable     from 'app/components/common/scroll-table';
import CadminLayout    from 'app/components/company-admin/layout/';
import PageLoading     from 'app/components/layout/page-loading';
import Duck            from 'app/ducks/company-admin/charge-errors';
import helpers         from 'app/helpers/charge-errors';
import format          from 'app/helpers/format';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';

class PageCadminChargeErrors extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  renderTable() {
    const {company, cces} = this.props;
    if (!cces) return null;
    if (!cces.length) return 'No notifications at this time.';

    return (
      <ScrollTable tableClassName="ca-box-table">
        <thead>
          <tr>
            <th>Date</th>
            <th className="right">Amount</th>
            <th className="min-200">Charge Type</th>
            <th className="min-200">Error Reason</th>
            <th className="min-200">Payment Method</th>
          </tr>
        </thead>
        <tbody>
          {cces.map((cce) => {
            return (
              <tr key={cce.id}>
                <td>
                  <div className="menu-cell">
                    <span>{moment.utc(cce.createdAt).format('MMM DD, YYYY')}</span>
                    <EllipsisMenu usePortal>
                      <Link href={paths.cadminChargeError(company.slug, cce.id)}>View Details</Link>
                    </EllipsisMenu>
                  </div>
                </td>
                <td className="right">{format.usd(cce.totalInCents)}</td>
                <td>{helpers.chargeReasonLabel(cce.chargeReason)}</td>
                <td>{helpers.errorReasonLabel(cce.errorReason)}</td>
                <td>{cce.paymentMethodName}</td>
              </tr>
            );
          })}
        </tbody>
      </ScrollTable>
    );
  }

  render() {
    const {company, cces} = this.props;
    if (!company || !cces) return <PageLoading />;

    return (
      <CadminLayout className="ca-charge-errors" company={company} activeItem="billing-notifications">
        <Meta title="Billing Notifications | Millie" />
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Billing Notifications</h1>
          </div>
          <div className="ca-box-body">
            {this.renderTable()}
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  cces: Duck.Slx.indexPageCces(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminChargeErrors);
