
import companyAdminApi from 'app/apis/company-admin';

const Types = {
  GET: 'CADMIN_DASHBOARDS_GET',
  GET_DATA: 'CADMIN_DASHBOARDS_GET_DATA',
  UPDATE: 'CADMIN_DASHBOARDS_UPDATE',
};

const Actions = {

  get: (companyId, dashboardId, params={}) => {
    const promise = companyAdminApi.dashboardsGet(companyId, dashboardId, params);
    return { type: Types.GET, promise };
  },

  getData: (companyId, dashboardId, {startDate, endDate} = {}) => {
    const promise = companyAdminApi.dashboardsGetData(companyId, dashboardId, {startDate, endDate});
    return { type: Types.GET_DATA, promise };
  },

  update: (companyId, dashboardId, attrs) => {
    const promise = companyAdminApi.dashboardsUpdate(companyId, dashboardId, attrs);
    return { type: Types.UPDATE, promise };
  },

};

export {
  Types,
};

export default Actions;
