import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import utils  from 'app/helpers/utils';
import AppSlx from 'app/selectors/app';

class ClientTimestamp extends React.PureComponent {

  render() {
    const {className, timestamp, format, sameYearFormat, isServer, relative, showTz} = this.props;
    if (isServer) return <span className={className}>&nbsp;</span>;
    const formatStr = (() => {
      if (relative) {
        return moment.unix(timestamp).fromNow();
      }
      const isoStr = utils.timestampToLocalIsoStr(timestamp);
      const mom = moment.utc(isoStr);
      const sameYear = mom.year() === moment().year();
      const useFormat = (sameYear && sameYearFormat) ? sameYearFormat : format;
      let str = moment.utc(isoStr).format(useFormat);
      if (showTz) str += ` ${utils.getLocalTimezoneAbbr(timestamp)}`;
      return str;
    })();
    return (
      <span className={className}>{formatStr}</span>
    );
  }

}

ClientTimestamp.propTypes = {
  timestamp: PropTypes.number.isRequired,
  format: PropTypes.string,
  sameYearFormat: PropTypes.string,
  showTz: PropTypes.bool,
  relative: PropTypes.bool,
  dateTimeTz: PropTypes.bool,
};

ClientTimestamp.defaultProps = {
  format: 'MMM D, YYYY',
  sameYearFormat: null,
  showTz: false,
  relative: false,
  dateTimeTz: false,
};

const stateToProps = (state) => ({
  isServer: AppSlx.isServer(state),
});

const ConnectClientTimestamp = connect(stateToProps)(ClientTimestamp);

ConnectClientTimestamp.DateTimeTz = (props={}) => <ConnectClientTimestamp {...props} format="MMM D, YYYY h:mma" showTz />;

export default ConnectClientTimestamp;
