
import CadminCompaniesAx         from 'app/actions/company-admin/companies';
import CadminOffDonsAx           from 'app/actions/company-admin/offline-donations';
import history                   from 'app/history';
import paths                     from 'app/paths';
import RoutingSlx                from 'app/selectors/routing';
import CadminSlx                 from 'app/selectors/company-admin/';
import PageSlx                   from 'app/selectors/company-admin/page-matches';

const Types = {
  LOAD: 'CADMIN_PAGE_OFF_DONS_LOAD',
  SEARCH: 'CADMIN_PAGE_OFF_DONS_SEARCH',
  APPROVE: 'CADMIN_PAGE_OFF_DONS_APPROVE',
  BAN: 'CADMIN_PAGE_OFF_DONS_BAN',
};

const Actions = {

  load: ({params: {companySlug}, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(Actions.search(companySlug, query)),
      dispatch(CadminCompaniesAx.getAttentionNumbers(companySlug, {refresh: true})),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  search: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminOffDonsAx.search(companyId, params));
    return dispatch({type: Types.SEARCH, promise});
  },

  refresh: () => (dispatch, getState) => {
    const state = getState();
    const companyId = CadminSlx.companyId(state);
    const params = {...PageSlx.searchParams(state)};
    const promise = dispatch(Actions.search(companyId, params));
  },

  approve: (offlineDonationId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = Promise.resolve();
    promise.then(() => {
      dispatch(Actions.refresh());
    });
    return dispatch({type: Types.APPROVE, promise});
  },

  ban: (offlineDonationId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = Promise.resolve();
    promise.then(() => {
      dispatch(Actions.refresh());
    });
    return dispatch({type: Types.BAN, promise});
  },

  setSearchParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = PageSlx.searchParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminOfflineDonations(companySlug, params);
    history.push(path);
  },

};

export {
  Types,
};

export default Actions;
