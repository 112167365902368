import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import CadminBracketsAx       from 'app/actions/company-admin/brackets';
import CadminCampaignsAx      from 'app/actions/company-admin/campaigns';
import CadminCompaniesAx      from 'app/actions/company-admin/companies';
import NonprofitsAx           from 'app/actions/nonprofits';
import BackstageApi           from 'app/apis/backstage';
import CadminApi              from 'app/apis/company-admin';
import MillieApi              from 'app/apis/millie';
import AutocompleteInput      from 'app/components/common/autocomplete-input';
import ModalNonprofitSelector from 'app/components/nonprofits/modal-selector';
import {
  VolEventTypes,
  PublishStatuses,
}                             from 'app/constants';
import CadminEmployeesDuck    from 'app/ducks/company-admin/employees';
import CadminGroupsDuck       from 'app/ducks/company-admin/groups';
import CadminVolEventsDuck    from 'app/ducks/company-admin/vol-events';
import bracketsHelpers        from 'app/helpers/brackets';
import campaignsHelpers       from 'app/helpers/campaigns';
import countries              from 'app/helpers/countries';
import Ps                     from 'app/helpers/publish-statuses';
import utils                  from 'app/helpers/utils';
import eventsHelpers          from 'app/helpers/vol-events';
import CadminSlx              from 'app/selectors/company-admin/';
import EntitiesSlx            from 'app/selectors/entities';

const ENTITY_INPUT_SELECTED = 'entity-input-selection';

class EntityInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refAci = React.createRef();

    this.onChange = this.onChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.renderResultFragment = this.renderResultFragment.bind(this);
    this.fetchEntityIfNeeded = this.fetchEntityIfNeeded.bind(this);
  }

  get searchStr() {
    return this.refAci.current?.inputVal || '';
  }

  componentDidMount() {
    this.fetchEntityIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const entIdChanged = this.props.selectedEntityId && (prevProps.selectedEntityId !== this.props.selectedEntityId);
    this.fetchEntityIfNeeded();
  }

  fetchEntityIfNeeded() {
    const {selectedEntityId, selectedEntity, dispatch, getFn} = this.props;
    const needsFetch = !!(selectedEntityId && (selectedEntity?.id !== selectedEntityId));
    if (!needsFetch) return;
    getFn && getFn(selectedEntityId, this.props);
  }

  onChange(entity) {
    const {onChange, dispatch, entityName} = this.props;
    if (entity?.id) {
      // use the entity middleware to get the entity into the store
      dispatch({type: ENTITY_INPUT_SELECTED, _entities: [entityName], result: {[entityName]: [entity]}});
    }
    return onChange(entity);
  }

  onSearch(searchStr) {
    return this.props.searchFn(searchStr, this.props);
  }

  renderResultFragment(result, isSelected) {
    const {name, subText1, subText2} = this.props.toLabelsFn(result, this.props);
    const showSubText = !isSelected && (subText1 || subText2);

    return (<>
      <div className="aci-result-main">{name}</div>
      {showSubText && (
        <div className="aci-result-subtext">
          <div className="aci-result-left">{subText1}</div>
          <div className="aci-result-right">{subText2}</div>
        </div>
      )}
    </>);
  }

  render() {
    const {name, label, labelFocused, validations, selectedEntity, searchOnEmpty, allowClear, disabled, className, noResultsMsg, staticActions} = this.props;

    return (
      <AutocompleteInput
        name={name}
        label={label}
        labelFocused={labelFocused}
        validations={validations}
        searchOnEmpty={searchOnEmpty}
        allowClear={allowClear}
        searchFn={this.onSearch}
        renderResultFragment={this.renderResultFragment}
        onChange={this.onChange}
        result={selectedEntity}
        disabled={disabled}
        className={className}
        noResultsMsg={noResultsMsg}
        staticActions={staticActions}
        ref={this.refAci}
      />
    );
  }

};

EntityInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  searchFn: PropTypes.func.isRequired,
  toLabelsFn: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // used to pull entity out of store `state.entities[entityName][id]`
  // used to put entity into store `dispatch({_entities: [entityName]})`
  entityName: PropTypes.string.isRequired,
  labelFocused: PropTypes.string,
  getFn: PropTypes.func,
  selectedEntityId: PropTypes.string,
  className: PropTypes.string,
  validations: PropTypes.object,
  searchOnEmpty: PropTypes.bool,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  noResultsMsg: PropTypes.string,
  staticActions: PropTypes.node,
};
EntityInput.defaultProps = {
  className: '',
  validations: null,
  searchOnEmpty: true,
  allowClear: true,
  labelFocused: 'Type to search...',
  disabled: false,
  noResultsMsg: 'No results found.',
  staticActions: null,
};
const stateToProps = (state, ownProps) => ({
  companyId: ownProps.companyId || CadminSlx.companyId(state),
  selectedEntity: (state.entities[ownProps.entityName] || {})[ownProps.selectedEntityId],
});
const ConnectedEntityInput = connect(stateToProps, undefined, undefined, {forwardRef: true})(EntityInput);



/*
 *  Campaigns
 */

const campaignSearchFn = async (search, {companyId, hasDrive, publishStatuses}) => {
  const params = {search, limit: 20, publishStatus: publishStatuses.join(',')};
  if (hasDrive) params.hasDrive = 'true';
  const response = await CadminApi.campaignsFetch(companyId, params);
  return response.campaigns;
};
const campaignToLabelsFn = (campaign) => {
  const isActive = campaign.publishStatus === Ps.ACTIVE;
  const status = Ps.name(campaign.publishStatus);
  // const status = isActive
  //   ? campaignsHelpers.getStatus(campaign)
  //   : Ps.name(campaign.publishStatus);
  return {name: campaign.name, subText1: status};
};
const campaignGetFn = (id, {dispatch, companyId}) => {
  dispatch(CadminCampaignsAx.get(companyId, id));
};
class CampaignEntityInput extends React.PureComponent {
  render() {
    const {campaignId, ...restProps} = this.props;
    return (
      <ConnectedEntityInput
        searchFn={campaignSearchFn}
        toLabelsFn={campaignToLabelsFn}
        getFn={campaignGetFn}
        {...restProps}
        entityName="campaigns"
        selectedEntityId={campaignId}
      />
    );
  }
}
CampaignEntityInput.propTypes = {
  campaignId: PropTypes.string,
  hasDrive: PropTypes.bool,
  publishStatuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PublishStatuses))),
};
CampaignEntityInput.defaultProps = {
  name: 'campaignId',
  label: 'All Campaigns',
  hasDrive: false,
  publishStatuses: [Ps.ACTIVE, Ps.ARCHIVED],
};



/*
 *  Brackets
 */

const bracketSearchFn = async (search, {companyId}) => {
  const params = {search, limit: 20};
  const response = await CadminApi.bracketsFetch(companyId, params);
  return response.brackets;
};
const bracketToLabelsFn = (bracket) => {
  // const startDateStr = bracketsHelpers.startDateStr(bracket);
  const subText1 = moment(bracket.startAt).format('MMM D, YYYY');
  return {name: bracket.name, subText1};
};
const bracketGetFn = (id, {dispatch, companyId}) => {
  dispatch(CadminBracketsAx.get(companyId, id));
};
class BracketEntityInput extends React.PureComponent {
  render() {
    const {bracketId, ...restProps} = this.props;
    return (
      <ConnectedEntityInput
        searchFn={bracketSearchFn}
        toLabelsFn={bracketToLabelsFn}
        getFn={bracketGetFn}
        {...restProps}
        entityName="brackets"
        selectedEntityId={bracketId}
      />
    );
  }
}
BracketEntityInput.propTypes = {
  bracketId: PropTypes.string,
};
BracketEntityInput.defaultProps = {
  name: 'bracketId',
  label: 'All Brackets',
};



/*
 *  Groups
 */

const groupSearchFn = async (search, {companyId, publishStatuses}) => {
  const params = {search, limit: 20, publishStatus: publishStatuses.join(',')};
  const response = await CadminApi.groupsFetch(companyId, params);
  return response.groups;
};
const groupToLabelsFn = (group) => {
  return {name: group.name, subText1: Ps.name(group.publishStatus)};
};
const groupGetFn = (id, {dispatch, companyId}) => {
  dispatch(CadminGroupsDuck.Ax.get(companyId, id));
};
class GroupEntityInput extends React.PureComponent {
  render() {
    const {groupId, ...restProps} = this.props;
    return (
      <ConnectedEntityInput
        searchFn={groupSearchFn}
        toLabelsFn={groupToLabelsFn}
        getFn={groupGetFn}
        {...restProps}
        entityName="groups"
        selectedEntityId={groupId}
      />
    );
  }
}
GroupEntityInput.propTypes = {
  groupId: PropTypes.string,
  publishStatuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PublishStatuses))),
};
GroupEntityInput.defaultProps = {
  name: 'groupId',
  label: 'All Groups',
  publishStatuses: [Ps.ACTIVE, Ps.ARCHIVED],
};



/*
 *  Employees
 */

const employeeSearchFn = async (search, {companyId, targetType}) => {
  const limit = 20;
  const {employees} = await CadminApi.employeesSearch(companyId, {search, limit});
  if ((targetType === 'email') && !employees.length && utils.isEmail(search)) {
    employees.unshift({email: search});
  }
  return employees;
};
const employeeToLabelsFn = (employee) => {
  if (!employee.id) return {name: employee.email, subText1: 'Non-employee email'};
  return {name: `${employee.firstName} ${employee.lastName}`, subText1: employee.email, subText2: employee.status};
};
const employeeGetFn = (id, {dispatch, companyId}) => {
  dispatch(CadminEmployeesDuck.Ax.get(companyId, id));
};
class EmployeeEntityInput extends React.PureComponent {
  render() {
    const {employeeId, ...restProps} = this.props;
    const noResultsMsg = (restProps.targetType === 'email') ? 'Please enter a valid email address.' : undefined;
    return (
      <ConnectedEntityInput
        searchFn={employeeSearchFn}
        toLabelsFn={employeeToLabelsFn}
        getFn={employeeGetFn}
        searchOnEmpty={false}
        {...restProps}
        noResultsMsg={noResultsMsg}
        entityName="employees"
        selectedEntityId={employeeId}
      />
    );
  }
}
EmployeeEntityInput.propTypes = {
  employeeId: PropTypes.string,
  targetType: PropTypes.oneOf(['employee', 'email']),
};
EmployeeEntityInput.defaultProps = {
  name: 'employeeId',
  label: 'All Employees',
  targetType: 'employee',
};



/*
 *  Nonprofits
 */

const nonprofitSearchFn = async (intl, search) => {
  const countryCode = intl ? '*' : undefined;
  const params = {search, forceIncludeUnapproved: true, limit: 20, countryCode};
  const response = await MillieApi.nonprofitsSearch(params);
  return response.nonprofits;
};
const nonprofitToLabelsFn = (nonprofit) => {
  const locParts = [nonprofit.city]
  const showState = nonprofit.countryCode === 'US' && nonprofit.state;
  locParts.push(showState ? nonprofit.state : nonprofit.countryCode);
  const country = countries.byCode[nonprofit.countryCode];
  const subText2 = locParts.filter(p => p).join(', ') + (country.flag ? ` ${country.flag}` : '');
  const subText1 = nonprofit.ein || nonprofit.registeredNumber;
  return {name: nonprofit.name, subText1, subText2};
};
const nonprofitGetFn = (id, {dispatch}) => {
  dispatch(NonprofitsAx.get(id));
};
class NonprofitEntityInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAdvanced: false,
      advancedSearchStr: '',
    };
    this.refEntityInput = React.createRef();
    this.search = this.search.bind(this);
    this.onClickAdvanced = this.onClickAdvanced.bind(this);
    this.onCloseAdvanced = this.onCloseAdvanced.bind(this);
  }
  search(...args) {
    return nonprofitSearchFn.call(null, this.props.intl, ...args);
  }
  onClickAdvanced(event) {
    event.preventDefault();
    const advancedSearchStr = this.refEntityInput.current?.searchStr || '';
    const newState = {showAdvanced: true};
    if (advancedSearchStr) newState.advancedSearchStr = advancedSearchStr;
    this.setState(newState);
  }
  onCloseAdvanced(nonprofit) {
    this.setState({showAdvanced: false});
    if (nonprofit) {
      this.props.onChange(nonprofit);
    }
  }
  renderStaticActions() {
    return (<>
      <a href="#" className="blue-pink-hover" onClick={this.onClickAdvanced}>Advanced Search</a>
    </>);
  }
  render() {
    const {showAdvanced, advancedSearchStr} = this.state;
    const {nonprofitId, intl, ...restProps} = this.props;
    return (<>
      <ConnectedEntityInput
        searchFn={this.search}
        toLabelsFn={nonprofitToLabelsFn}
        getFn={nonprofitGetFn}
        searchOnEmpty={false}
        {...restProps}
        entityName="nonprofits"
        selectedEntityId={nonprofitId}
        staticActions={this.renderStaticActions()}
        ref={this.refEntityInput}
      />
      {showAdvanced && (
        <ModalNonprofitSelector onClose={this.onCloseAdvanced} searchStr={advancedSearchStr} intl={intl} />
      )}
    </>);
  }
}
NonprofitEntityInput.propTypes = {
  nonprofitId: PropTypes.string,
  intl: PropTypes.bool,
};
NonprofitEntityInput.defaultProps = {
  name: 'nonprofitId',
  label: 'All Nonprofits',
  hasDrive: false,
  intl: false,
};



/*
 *  VolEvents
 */

const eventSearchFn = async (search, {companyId, eventType, publishStatuses}) => {
  const params = {search, limit: 20, publishStatus: publishStatuses.join(',')};
  if (eventType) params.type = eventType;
  const response = await CadminApi.volEventsSearch(companyId, params);
  return response.volEvents;
};
const eventToLabelsFn = (event) => {
  const subText1 = Ps.name(event.publishStatus);
  const subText2 = eventsHelpers.getLocationLabel(event);
  return {name: event.title, subText1, subText2};
};
const eventGetFn = (id, {dispatch, companyId}) => {
  dispatch(CadminVolEventsDuck.Ax.get(companyId, id));
};
class VolEventEntityInput extends React.PureComponent {
  render() {
    const {volEventId, ...restProps} = this.props;
    return (
      <ConnectedEntityInput
        searchFn={eventSearchFn}
        toLabelsFn={eventToLabelsFn}
        getFn={eventGetFn}
        {...restProps}
        entityName="volEvents"
        selectedEntityId={volEventId}
      />
    );
  }
}
VolEventEntityInput.propTypes = {
  volEventId: PropTypes.string,
  eventType: PropTypes.oneOf(Object.values(VolEventTypes)),
  publishStatuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PublishStatuses))),
};
VolEventEntityInput.defaultProps = {
  name: 'volEventId',
  label: 'All Events',
  eventType: null,
  publishStatuses: [Ps.ACTIVE, Ps.ARCHIVED],
};



/*
 *  VolEventShifts
 *  TODO: this one is not entirely complete
 */

const shiftSearchFn = async (search, {companyId, volEventId}) => {
  const response = await CadminApi.volEventsFetchShifts(companyId, volEventId);
  return response.volEventShifts;
};
const shiftToLabelsFn = (shift) => {
  return {name: eventsHelpers.formatShiftTime(shift)};
};
// const shiftGetFn = (id, {dispatch, companyId}) => {
//   dispatch(CadminVolEventsDuck.Ax.get(companyId, id));
// };
class VolEventShiftEntityInput extends React.PureComponent {
  render() {
    const {volEventShiftId, ...restProps} = this.props;
    return (
      <ConnectedEntityInput
        searchFn={shiftSearchFn}
        toLabelsFn={shiftToLabelsFn}
        // getFn={shiftGetFn}
        {...restProps}
        entityName="volEventShifts"
        selectedEntityId={volEventShiftId}
      />
    );
  }
}
VolEventShiftEntityInput.propTypes = {
  volEventId: PropTypes.string.isRequired,
  volEventShiftId: PropTypes.string,
};
VolEventShiftEntityInput.defaultProps = {
  name: 'volEventShiftId',
  label: 'All Shifts',
};



/*
 *  Companies
 */

const companiesSearchFn = async (search, {enterprise}) => {
  const params = {search, limit: 10};
  const response = await (enterprise
    ? MillieApi.companiesFetchEnterprise({search})
    : BackstageApi.companiesFetch({search}));
  return response.companies;
};
const companyToLabelsFn = (company, {enterprise}) => {
  const subText1 = enterprise ? `${numeral(company.employeeCount || 0).format('0,0')} employees` : company.slug;
  return {name: company.name, subText1};
};
const companyGetFn = (id, {dispatch}) => {
  dispatch(CadminCompaniesAx.get(id));
};
class CompanyEntityInput extends React.PureComponent {
  render() {
    const {companyId, ...restProps} = this.props;
    return (
      <ConnectedEntityInput
        searchFn={companiesSearchFn}
        toLabelsFn={companyToLabelsFn}
        getFn={companyGetFn}
        {...restProps}
        entityName="companies"
        selectedEntityId={companyId}
      />
    );
  }
}
CompanyEntityInput.propTypes = {
  companyId: PropTypes.string,
  enterprise: PropTypes.bool,
};
CompanyEntityInput.defaultProps = {
  name: 'companyId',
  label: 'All Companies',
  enterprise: false,
};



EntityInput.Employee      = EmployeeEntityInput;
EntityInput.Campaign      = CampaignEntityInput;
EntityInput.Bracket       = BracketEntityInput;
EntityInput.Nonprofit     = NonprofitEntityInput;
EntityInput.VolEvent      = VolEventEntityInput;
EntityInput.VolEventShift = VolEventShiftEntityInput;
EntityInput.Company       = CompanyEntityInput;
EntityInput.Group         = GroupEntityInput;

export default EntityInput;
