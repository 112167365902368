import 'babel-polyfill';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';

// globals needs imported first
import globals from 'client/globals';
import setup from 'client/setup';

import AppAx from 'app/actions/app';
import RoutingAx from 'app/actions/routing';
import SessionStorageAx from 'app/actions/session-storage';
import App from 'app/components/app';
import { Pages } from 'app/constants';
import history from 'app/history';
import createRouter from 'app/router';
import RoutingSlx from 'app/selectors/routing';
import createStore from 'app/store';

const { serverState } = window.__MILLIE;
const store = createStore(serverState);
const router = createRouter(store);

setup(store);

// if (process.env.NODE_ENV === 'development') {
  require('./debug-globals').default({ store });
// }

const initializeReact = () => {
  const rootEl = document.getElementById('app');
  const rootComp = <Provider store={store}><App /></Provider>;
  hydrate(rootComp, rootEl);
};

const onRouteFinish = (location, data, redirect, error) => {
  if (redirect) {
    if (redirect.startsWith('http')) return window.location.href = redirect;
    return history.replace(redirect);
  }
  const currentPage = RoutingSlx.page(store.getState());
  store.dispatch(RoutingAx.setLocation(location));
  const page = (() => {
    if (error?.statusCode === 403) return Pages.FORBIDDEN;
    if (error?.statusCode === 404) return Pages.NOT_FOUND;
    if (error) return Pages.ERROR;
    if (data?.page) return data.page;
    return Pages.NOT_FOUND;
  })();
  store.dispatch(RoutingAx.setPage(page));
  if (error) throw error;
  // scroll to top if new page
  if (currentPage !== page) {
    window.scrollTo(0, 0);
  }
};

const clearIsServer = () => {
  store.dispatch(AppAx.clearIsServer());
};

const reconcileSessionStorage = () => {
  store.dispatch(SessionStorageAx.reconcile());
};

const startRouting = () => {
  router.startRouting(history, onRouteFinish);
};

const launch = () => {
  startRouting(); // needs to come first in case any components trigger history.push() upon mount
  initializeReact();
  clearIsServer();
  reconcileSessionStorage();
};

launch();
