
import ToastAx   from 'app/actions/toast';
import CadminApi from 'app/apis/company-admin';
import CadminSlx from 'app/selectors/company-admin/';

const Types = {
  FETCH: 'CADMIN_GROUPS_FETCH',
  GET: 'CADMIN_GROUPS_GET',
  DELETE: 'CADMIN_GROUPS_DELETE',
  CREATE: 'CADMIN_GROUPS_CREATE',
  UPDATE: 'CADMIN_GROUPS_UPDATE',
  TYPES_FETCH: 'CADMIN_GROUPS_TYPES_FETCH',
  TYPES_UPDATE: 'CADMIN_GROUPS_TYPES_UPDATE',
};

const Actions = {

  fetch: (companyId, params) => {
    const promise = CadminApi.groupsFetch(companyId, params);
    return { type: Types.FETCH, _entities: ['groups', 'employees'], promise };
  },

  get: (companyId, groupId) => {
    const promise = CadminApi.groupsGet(companyId, groupId);
    return { type: Types.GET, _entities: ['group', 'nonprofits', 'campaigns', 'volEvents', 'volEventShifts', 'changeLogs'], promise };
  },

  delete: (companyId, groupId) => (dispatch, getState) => {
    const promise = CadminApi.groupsDelete(companyId, groupId);
    promise.then(() => {
      dispatch(ToastAx.success('Group deleted.'));
    });
    return dispatch({ type: Types.DELETE, promise });
  },

  create: (companyId, attrs) => {
    const promise = CadminApi.groupsCreate(companyId, attrs);
    return { type: Types.CREATE, promise };
  },

  update: (companyId, groupId, attrs) => {
    const promise = CadminApi.groupsUpdate(companyId, groupId, attrs);
    return { type: Types.UPDATE, _entities: ['group', 'nonprofits', 'campaigns', 'volEvents'], promise };
  },

  typesFetch: (companyId) => {
    const promise = CadminApi.groupsTypesFetch(companyId);
    return { type: Types.TYPES_FETCH, promise, _entities: ['groupTypes'] };
  },

  typesUpdate: (companyId, data) => {
    const promise = CadminApi.groupsTypesUpdate(companyId, data);
    return { type: Types.TYPES_UPDATE, promise };
  },

};

export {
  Types,
};

export default Actions;
