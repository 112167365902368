import _ from 'lodash';
import { createSelector } from 'reselect';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import CadminApi         from 'app/apis/company-admin';
import reducerUtils      from 'app/reducers/utils';
import CadminSlx         from 'app/selectors/company-admin/';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  SEARCH: 'CADMIN_CCES_SEARCH',
  GET: 'CADMIN_CCES_GET',
  LOAD_PAGE_INDEX: 'CADMIN_CCES_LOAD_PAGE_INDEX',
  LOAD_PAGE_VIEW: 'CADMIN_CCES_LOAD_PAGE_VIEW',
};

const Ax = {

  loadPageIndex: (location) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(location.params.companySlug)),
      dispatch(Ax.search(location.params.companySlug))
    ]);
    return dispatch({type: Types.LOAD_PAGE_INDEX, promise});
  },

  loadPageView: (location) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(location.params.companySlug)),
      dispatch(Ax.get(location.params.companySlug, location.params.cceId))
    ]);
    return dispatch({type: Types.LOAD_PAGE_VIEW, promise});
  },

  get: (companyId, cceId) => {
    const promise = CadminApi.companyChargeErrorsGet(companyId, cceId);
    return { type: Types.GET, _entities: ['companyChargeError'], promise };
  },

  search: (companyId) => {
    const promise = CadminApi.companyChargeErrorsSearch(companyId);
    return { type: Types.SEARCH, _entities: ['companyChargeErrors'], promise };
  },

};



/*
 *  Reducer
 */

const initialState = {
  indexPageCceIds: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD_PAGE_INDEX}_PENDING`]: (state, action) => {
    return {...state,
      indexPageCceIds: null,
    };
  },
  [`${Types.LOAD_PAGE_INDEX}_RESOLVED`]: (state, action) => {
    return {...state,
      indexPageCceIds: action.result[1].companyChargeErrors.map(cce => cce.id),
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIndexPageCceIds  = state => state.companyAdmin.chargeErrors.indexPageCceIds;

  const selIndexPageCces = createSelector(
    [EntitiesSlx.companyChargeErrors, selIndexPageCceIds],
    (cceMap, cceIds) => {
      return cceIds && cceIds.map(id => cceMap[id]);
    }
  );

  const selViewCce = createSelector(
    [EntitiesSlx.companyChargeErrors, RoutingSlx.params],
    (cceMap, params) => {
      return cceMap[params.cceId] || null;
    }
  );

  return {
    indexPageCces: selIndexPageCces,
    viewCce: selViewCce,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
