import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import GrantsAx          from 'app/actions/company-admin/grants';
import PageAx            from 'app/actions/company-admin/page-grants';
import DateRangePicker   from 'app/components/common/date-range-picker';
import EllipsisMenu      from 'app/components/common/ellipsis-menu';
import EntityInput       from 'app/components/company-admin/common/entity-input';
import Icon              from 'app/components/common/icon';
import Link              from 'app/components/common/link';
import Meta              from 'app/components/common/meta';
import Pagination        from 'app/components/common/pagination';
import ScrollTable       from 'app/components/common/scroll-table';
import StandardSelect    from 'app/components/common/standard-select';
import ModalOfflineGrant from 'app/components/company-admin/grants/modal-offline-grant';
import CadminLayout      from 'app/components/company-admin/layout/';
import TopLineStats      from 'app/components/company-admin/top-line-stats';
import PageLoading       from 'app/components/layout/page-loading';
import history           from 'app/history';
import paths             from 'app/paths';
import prompts           from 'app/prompts';
import CadminSlx         from 'app/selectors/company-admin/';
import PageSlx           from 'app/selectors/company-admin/page-grants';

const platformOptions = [
  {label: 'Millie', value: 'false'},
  {label: 'Offline', value: 'true'},
];

class PageCadminGrantsIndex extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showOfflineGrantModal: false,
    };

    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onChangeSelectedCampaign = this.onChangeSelectedCampaign.bind(this);
    this.onChangeSelectedNonprofit = this.onChangeSelectedNonprofit.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onCloseOfflineModal = this.onCloseOfflineModal.bind(this);
    this.onClickTrackOffline = this.onClickTrackOffline.bind(this);
    this.onChangeIsOffline = this.onChangeIsOffline.bind(this);
  }

  onChangeIsOffline(isOffline) {
    this.props.setSearchParams({isOffline, page: 1});
  }

  onCloseOfflineModal(shouldRefresh) {
    if (shouldRefresh) history.millieRefresh();
    this.setState({showOfflineGrantModal: false});
  }

  onClickTrackOffline() {
    this.setState({showOfflineGrantModal: true});
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.props.setSearchParams({
      startDate: startDateStr,
      endDate: endDateStr,
      page: 1,
    });
  }

  onChangeSelectedCampaign(campaign) {
    this.props.setSearchParams({campaignId: campaign?.id, page: 1});
  }

  onChangeSelectedNonprofit(nonprofit) {
    this.props.setSearchParams({nonprofitId: nonprofit?.id, page: 1});
  }

  onSelectPage(page) {
    this.props.setSearchParams({page});
  }

  onClickEditGrant(grant, event) {
    event.preventDefault();
    this.setState({showOfflineGrantModal: grant});
  }

  async onClickDeleteGrant(grant, event) {
    event.preventDefault();
    const didConfirm = await prompts.confirm({danger: 'Delete', msg: 'Are you sure you want to delete this offline donation?'});
    if (didConfirm) {
      this.props.delete(grant.id);
    }
  }

  renderFilters() {
    const {currentFiscalYear, searchParams, company} = this.props;
    const {startDate, endDate, campaignId, nonprofitId, isOffline} = searchParams;

    return (
      <div className="ca-main-filters">
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Date Range</label>
          <DateRangePicker onSelect={this.onSelectDateRange} currentFiscalYear={currentFiscalYear} startDateStr={startDate} endDateStr={endDate} leftAlign allowClear label="All Dates" />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Campaign</label>
          <EntityInput.Campaign campaignId={campaignId} onChange={this.onChangeSelectedCampaign} />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Nonprofit</label>
          <EntityInput.Nonprofit nonprofitId={nonprofitId} onChange={this.onChangeSelectedNonprofit} intl={!!company?.features?.international} />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Platform</label>
          <StandardSelect options={platformOptions} onSelect={this.onChangeIsOffline} value={isOffline} allowClear label="All" />
        </div>
      </div>
    );
  }

  renderSummary() {
    const { summary } = this.props;
    const stats = [
      {icon: <Icon.LoveHeartHandsHold />, key: 'Total Grants Made', valType: 'int',   val: summary?.numGrants},
      {icon: <Icon.LoveItHandGive />,     key: 'Nonprofits Helped', valType: 'int',   val: summary?.numNonprofits},
      {icon: <Icon.LoveHeartHold />,      key: 'Dollars Granted',   valType: 'cents', val: summary?.sumAmountInCents},
    ];
    return <TopLineStats stats={stats} color="pink" />;
  }

  renderTable() {
    const { grants } = this.props;
    if (!grants) return null;

    return (
      <ScrollTable tableClassName="ca-box-table">
        <thead>
          <tr>
            <th>Date</th>
            <th className="right">Amount</th>
            <th>EIN</th>
            <th className="min-200">Recipient</th>
            <th className="min-200">Payment Method</th>
            <th className="min-200">Campaign</th>
          </tr>
        </thead>
        <tbody>
          {grants.map((grant) => {
            const paymentMethods = [];
            if (!grant.charge || grant.charge.amountInCents < grant.amountInCents) paymentMethods.push('Millie Wallet');
            if (grant.charge) paymentMethods.push(grant.charge.paymentMethodLabel);
            return (<tr key={grant.id}>
              <td>
                <div className="menu-cell">
                  <span>{moment.utc(grant.grantedAt).format('MM/DD/YYYY')}</span>
                  <EllipsisMenu usePortal>
                    <Link href="#" onClick={this.onClickEditGrant.bind(this, grant)}><Icon.Pencil />Edit Details</Link>
                    {grant.isOffline && (
                      <Link href="#" onClick={this.onClickDeleteGrant.bind(this, grant)}><Icon.Bin />Delete</Link>
                    )}
                  </EllipsisMenu>
                </div>
              </td>
              <td className="right">{numeral(grant.amountInCents / 100).format('$0,0.00')}</td>
              <td className="nowrap">{grant.donatable?.ein || null}</td>
              <td><Link className="pink-hover" href={paths.donatable(grant.donatable)}>{grant.donatable?.name}</Link></td>
              <td>{grant.isOffline ? 'Offline' : paymentMethods.join(' & ')}</td>
              <td>{grant.campaign && (
                <Link href={paths.campaign(grant.campaign.id)} className="pink-hover">{grant.campaign.name}</Link>
              )}</td>
            </tr>);
          })}
        </tbody>
      </ScrollTable>
    );
  }

  render() {
    const {showOfflineGrantModal} = this.state;
    const {company, currentFiscalYear, grants, searchParams, pagination} = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="cadmin-grants" company={company} activeItem="grants">
        <Meta title="Grants | Millie" />
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Grants</h1>
          <div className="ca-main-head-actions">
            <Link className="btn pink" href={paths.cadminGrantsCreate(company.slug)}>Make New Grant</Link>
            <button className="btn pink" onClick={this.onClickTrackOffline}>Track Offline Grant</button>
          </div>
        </div>

        {this.renderFilters()}
        {this.renderSummary()}

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Grants</h1>
            <div className="ca-box-header-controls">
              <a href={paths.cadminGrantsCsv(company.slug, searchParams)} className="btn secondary icon small"><Icon.CommonFileTextDownload /> CSV</a>
            </div>
          </div>
          <div className="ca-box-body">
            {this.renderTable()}
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </div>
        </div>

        {showOfflineGrantModal && (
          <ModalOfflineGrant onClose={this.onCloseOfflineModal} grant={(showOfflineGrantModal === true) ? null : showOfflineGrantModal} key={showOfflineGrantModal?.id || 'new'} />
        )}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  grants: PageSlx.grants(state),
  searchParams: PageSlx.searchParams(state),
  pagination: PageSlx.pagination(state),
  summary: PageSlx.summary(state),
});

const dispatchToProps = (dispatch) => ({
  setSearchParams: (newParams) => dispatch(PageAx.setSearchParams(newParams)),
  delete: (grantId) => dispatch(GrantsAx.delete(grantId)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminGrantsIndex);
