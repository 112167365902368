import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import CampaignsAx        from 'app/actions/company-admin/campaigns';
import CampaignCard       from 'app/components/common/campaign-card';
import ClientTimestamp    from 'app/components/common/client-timestamp';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import RequireRole        from 'app/components/gating/require-role';
import PageLoading        from 'app/components/layout/page-loading';
import PageDuck           from 'app/ducks/company-admin/page-campaigns-view';
import helpers            from 'app/helpers/campaigns';
import Ps                 from 'app/helpers/publish-statuses';
import Metrics            from 'app/metrics';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import AuthSlx            from 'app/selectors/auth';
import CadminSlx          from 'app/selectors/company-admin/';

class PageCadminCampaignsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      deletePending: false,
      archivePending: false,
      unarchivePending: false,
      markEndedPending: false,
      announceSlackPending: false,
    };

    this.onClickMarkEnded = this.onClickMarkEnded.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickArchive = this.onClickArchive.bind(this);
    this.onClickUnarchive = this.onClickUnarchive.bind(this);
    this.onClickAnnounceInSlack = this.onClickAnnounceInSlack.bind(this);
  }

  async onClickMarkEnded(event) {
    event.preventDefault();
    const msg = `Are you sure you want to end this campaign?\nThe campaign will end today at midnight in the company's default time zone.`;
    const didConfirm = await prompts.confirm({confirmBtnLabel: 'End Campaign', msg});
    if (!didConfirm) return;
    this.setState({markEndedPending: true});
    await this.props.markEnded(this.props.company.id, this.props.campaign.id);
    this.setState({markEndedPending: false});
  }
  async onClickDelete(event) {
    event.preventDefault();
    const msg = `Are you sure you want to delete this campaign? The campaign will no longer be visible to employees or to admins anywhere.\nThis will negatively affect analytics/reporting.${this.hasDrive ? '\nAssociated drive donation records will be deleted as well.' : ''}\nThis can not be undone. We recommend archiving instead.`;
    const didConfirm = await prompts.confirm({msg, danger: 'Delete Campaign', doubleConfirm: true});
    if (!didConfirm) return;
    this.setState({deletePending: true});
    await this.props.delete(this.props.company.id, this.props.campaign.id);
    this.setState({deletePending: false});
  }
  async onClickArchive(event) {
    event.preventDefault();
    const didConfirm = await prompts.confirm({confirmBtnLabel: 'Archive Campaign', msg: 'Are you sure you want to archive this campaign?\nIt will still show up in the admin area and analytics, but will no longer be discoverable by employees.'});
    if (!didConfirm) return;
    this.setState({archivePending: true});
    await this.props.archive(this.props.company.id, this.props.campaign.id);
    this.setState({archivePending: false});
  }
  async onClickUnarchive(event) {
    event.preventDefault();
    const didConfirm = await prompts.confirm({confirmBtnLabel: 'Restore Campaign', msg: 'Are you sure you want to restore this campaign back to the published state?\nIt will become discoverable by employees again.'});
    if (!didConfirm) return;
    this.setState({unarchivePending: true});
    await this.props.unarchive(this.props.company.id, this.props.campaign.id);
    this.setState({unarchivePending: false});
  }
  async onClickAnnounceInSlack() {
    const didConfirm = await prompts.confirm({confirmBtnLabel: 'Announce Campaign', msg: 'Announce this campaign in the main Millie channel?'});
    if (!didConfirm) return;
    this.setState({announceSlackPending: true});
    await this.props.announceInSlack(this.props.company.id, this.props.campaign.id);
    this.setState({announceSlackPending: false});
  }

  get role() {
    return this.props.currentUser?.employment?.employee?.role || undefined;
  }

  get isActive() {
    return this.props.campaign?.publishStatus === Ps.ACTIVE;
  }

  get isActiveish() {
    return Ps.isActiveish(this.props.campaign?.publishStatus);
  }

  get status() {
    const {campaign} = this.props;
    if (!campaign) return ['status', 'gray'];
    const ps = campaign.publishStatus;
    if (this.isActive) {
      const [label, color] = helpers.getStatusDetails(campaign);
      return [label, color];
    }
    return [Ps.name(ps), Ps.color(ps)];
  }

  get hasSlack() {
    const {company} = this.props;
    return !!(company && company.isSlackSetup && company.mainSlackChannelId);
  }

  get hasDrive() {
    return !!this.props.campaign?.hasDrive;
  }

  renderStatusPicker() {
    const {deletePending, archivePending, unarchivePending, markEndedPending} = this.state;
    if (deletePending) return 'deleting...';
    if (archivePending) return 'archiving...';
    if (markEndedPending) return 'ending...';
    if (unarchivePending) return 'restoring...';

    const {company, campaign} = this.props;
    if (!campaign) return null;
    const ps = campaign.publishStatus;
    const perms = Ps.getPerms('Campaign', this.role, company.settingsApprovals, ps);
    const [label, color] = this.status;
    const showMarkEnded = !!(perms.canEdit && campaign.isOngoing && !campaign.endDate && (ps === Ps.ACTIVE));
    const showPublish = perms.canEdit && (ps === Ps.DRAFT);
    const showArchive = perms.canEdit && (ps === Ps.ACTIVE);
    const showUnarchive = perms.canEdit && (ps === Ps.ARCHIVED);
    const showReview = perms.canPublish && (ps === Ps.PENDING);
    const showDelete = perms.canDelete && (ps !== Ps.DELETED);

    const target = (
      <div className="ca-ps-picker">
        <span>Status</span>
        <div className={`ca-ps-picker-tag ${color}`}>{label}</div>
        {(ps !== Ps.DELETED) && <Icon.Caret className="ca-ps-picker-caret" direction="down" />}
      </div>
    );
    return (
      <EllipsisMenu target={target}>
        {showPublish   && <Link href={paths.cadminEditCampaign(company.slug, campaign.id, {psAction: 'publish'})} onClick={this.onClickPublish}>Publish</Link>}
        {showReview    && <Link href={paths.cadminEditCampaign(company.slug, campaign.id, {psAction: 'review'})}  onClick={this.onClickReview}>Approve or Restrict</Link>}
        {showMarkEnded && <a href="#" onClick={this.onClickMarkEnded}>Mark as Ended</a>}
        {showUnarchive && <a href="#" onClick={this.onClickUnarchive}>Restore (un-archive)</a>}
        {showArchive   && <a href="#" onClick={this.onClickArchive}>Archive</a>}
        {showDelete    && <a href="#" onClick={this.onClickDelete}>Delete</a>}
      </EllipsisMenu>
    );
  }

  renderChangeLog() {
    const {company, changeLogs} = this.props;
    if (!changeLogs?.length) return null;

    return (<>
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">History</h1>
        </div>
        <div className="ca-box-body">
          <table className="ca-box-table">
            <thead>
              <tr>
                <th>Event</th>
                <th>Date</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              {changeLogs.map((change) => {
                return (
                  <tr key={change.id}>
                    <td>{Ps.changeName(change)}</td>
                    <td><ClientTimestamp.DateTimeTz timestamp={change.createdTs} /></td>
                    <td>
                      {!!change.employeeName && <Link className="pink-hover" href={paths.cadminEmployee(company?.slug, change.employeeId)}>{change.employeeName}</Link>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>);
  }

  renderMain() {
    const {company, campaign} = this.props;
    const {announceSlackPending} = this.state;
    if (!campaign) return null;

    return (<>
      <div className="ca-main-ctas">
        <Link className="btn small icon secondary" href={paths.campaign(campaign.id)}><Icon.BrowserPageText />Employee View</Link>
        <Link className="btn small icon secondary" href={paths.cadminEditCampaign(company.slug, campaign.id)}><Icon.Pencil />Edit</Link>
        {this.isActiveish && campaign.hasGive && (
          <Link className="btn small icon secondary" href={paths.cadminMatches(company.slug, {campaignId: campaign.id})}><Icon.AccountingBills />View Donations</Link>
        )}
        {this.isActiveish && campaign.hasDrive && (
          <Link className="btn small icon secondary" href={paths.cadminDriveDonations(company.slug, {campaignId: campaign.id})}><Icon.ProductsGiftGive />View Drive Donations</Link>
        )}
        {this.isActiveish && (
          <RequireRole>
            <Link className="btn small icon secondary" href={paths.cadminAnalytics(company.slug, {campaignId: campaign.id, groupBy: 'employeeId'}, Metrics.presets.campaigns.key)}><Icon.AnalyticsBars />Analytics</Link>
          </RequireRole>
        )}
        {this.isActive && this.hasSlack && (
          <button className="btn small icon secondary" onClick={this.onClickAnnounceInSlack} disabled={announceSlackPending}><Icon.LogoSlack />Announce in Slack</button>
        )}
      </div>

      <div className="ca-main-entity-preview">
        <h2 className="ca-main-entity-preview-title">Campaign Listing Preview</h2>
        <CampaignCard campaign={campaign} />
      </div>

      {this.renderChangeLog()}
    </>);
  }

  render() {
    const {company, campaign} = this.props;
    if (!company) return <PageLoading />;
    const name = campaign?.name || 'Campaign';

    return (
      <CadminLayout className="ca-camp-vw" company={company} activeItem="campaigns">
        <Meta title={`${name} | Millie`} />
        <div className="ca-main-head">
          <Link className="ca-main-head-back" href={paths.cadminCampaigns(company.slug)}><Icon.Caret direction="left" /> Back to Campaigns</Link>
          <h1 className="ca-main-head-h1">{name}</h1>
          <div className="ca-main-head-actions">
            {this.renderStatusPicker()}
          </div>
        </div>
        {this.renderMain()}
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentUser: AuthSlx.currentUser(state),
  campaign: PageDuck.Slx.campaign(state),
  changeLogs: PageDuck.Slx.changeLogs(state),
});

const dispatchToProps = (dispatch) => ({
  delete:          (companyId, campaignId) => dispatch(CampaignsAx.delete(companyId, campaignId)),
  archive:         (companyId, campaignId) => dispatch(CampaignsAx.archive(companyId, campaignId)),
  unarchive:       (companyId, campaignId) => dispatch(CampaignsAx.unarchive(companyId, campaignId)),
  markEnded:       (companyId, campaignId) => dispatch(CampaignsAx.markEnded(companyId, campaignId)),
  announceInSlack: (companyId, campaignId) => dispatch(CampaignsAx.announceInSlack(companyId, campaignId)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminCampaignsView);
