
import { Pages } from 'app/constants';
import utils from 'app/reducers/utils';
import { Types } from 'app/actions/routing';

const initialState = {
  page: Pages.HOME,
  location: {
    url: '/',
    path: '/',
    queryString: '',
    query: {},
    params: {},
  },
  confirmNavTo: null,
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_LOCATION]: (state, { location }) => {
    return {...state,
      location,
      confirmNavTo: null,
    };
  },

  [Types.SET_PAGE]: (state, { page }) => {
    return { ...state, page };
  },

  [Types.CONFIRM_NAV_TO]: (state, { path }) => {
    return { ...state, confirmNavTo: path };
  },
  [Types.CANCEL_NAV]: (state, action) => {
    return { ...state, confirmNavTo: null };
  },

});

export default reducer;
