import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CampaignsAx   from 'app/actions/company-admin/campaigns';
import Modal         from 'app/components/common/modal';
import RadioButton   from 'app/components/common/radio-button';
import StandardInput from 'app/components/common/standard-input';
import GroupsDuck    from 'app/ducks/company-admin/groups';
import EventsDuck    from 'app/ducks/company-admin/vol-events';
import Ps            from 'app/helpers/publish-statuses';

const OPTS = {
  APPROVE: 'approve',
  REJECT: 'reject',
  DELETE: 'delete',
};
const optTitleMap = {
  [OPTS.APPROVE]: 'Approve & Publish',
  [OPTS.REJECT]: 'Request Changes',
  [OPTS.DELETE]: 'Restrict & Delete',
};

class ModalReview extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      selectedOpt: null,
      note: null,
    };

    this.onChangeOpt = this.onChangeOpt.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickApprove = this.onClickApprove.bind(this);
    this.onClickReject = this.onClickReject.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
  }

  get perms() {
    const {company, entityType, currentUser} = this.props;
    const role = currentUser?.employment?.employee?.role || undefined;
    return Ps.getPerms(entityType, role, (company?.settingsApprovals || {}));
  }
  get canPublish() {
    return this.perms.canPublish;
  }
  get entityTypeName() {
    return this.perms.entityTypeName;
  }
  get entityTypeNameLower() {
    return this.entityTypeName.toLowerCase();
  }

  get optDescMap() {
    return {
      [OPTS.APPROVE]: `Publish the ${this.entityTypeNameLower} now.`,
      [OPTS.REJECT]: `Move the ${this.entityTypeNameLower} back to draft and notify the author.`,
      [OPTS.DELETE]: `Restrict the ${this.entityTypeNameLower} and delete the draft.`,
    };
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickCancel() {
    this.props.onClose();
  }

  onChangeOpt(event) {
    const {checked, value} = event.target;
    this.setState({selectedOpt: value});
  }

  onChangeNote(event) {
    const note = event.target.value;
    this.setState({note});
  }

  async onClickApprove() {
    this.setState({isSubmitting: true});
    await this.props.onApprove();
    this.props.onClose();
  }
  async onClickReject() {
    this.setState({isSubmitting: true});
    await this.props.onReject(this.state.note);
    this.props.onClose();
  }
  async onClickDelete() {
    this.setState({isSubmitting: true});
    await this.props.onDelete();
    this.props.onClose();
  }

  renderSubmitBtn() {
    const {isSubmitting, selectedOpt, note} = this.state;
    const title = optTitleMap[selectedOpt];
    const btnDisabled = isSubmitting || !this.canPublish;
    if (selectedOpt === OPTS.APPROVE) {
      return <button onClick={this.onClickApprove} disabled={btnDisabled} className="btn green">{isSubmitting ? 'Approving...' : title}</button>;
    }
    if (selectedOpt === OPTS.REJECT) {
      return <button onClick={this.onClickReject} disabled={btnDisabled || !note} className="btn blue">{isSubmitting ? 'Submitting...' : title}</button>;
    }
    if (selectedOpt === OPTS.DELETE) {
      return <button onClick={this.onClickDelete} disabled={btnDisabled} className="btn danger">{isSubmitting ? 'Deleting...' : title}</button>;
    }
    return <button disabled className="btn">Review</button>;
  }

  render() {
    const {entityName} = this.props;
    const {selectedOpt, isSubmitting, note} = this.state;

    return (
      <Modal className="bform ps-modal-review" onClose={this.onCloseModal}>
        <h1 className="bform-h1">Review {this.entityTypeName}</h1>
        <p>Approve or restrict {entityName}?</p>

        <div className="ps-modal-review-opts">
          {Object.values(OPTS).map((OPT) => {
            const checked = OPT === selectedOpt;
            const showNote = checked && (OPT === OPTS.REJECT);
            return (
              <div className={`ps-modal-review-opt ${checked ? 'checked' : 'unchecked'}`} key={OPT}>
                <RadioButton id={`rb-${OPT}`} value={OPT} checked={checked} onChange={this.onChangeOpt} disabled={isSubmitting} />
                <label htmlFor={`rb-${OPT}`}>
                  <div className="ps-modal-review-opt-title">{optTitleMap[OPT]}</div>
                  <p className="ps-modal-review-opt-desc">{this.optDescMap[OPT]}</p>
                  {showNote && (
                    <StandardInput className="ps-modal-review-note" type="textarea" name="note" value={note || ''} onChange={this.onChangeNote} label="Changes requested (required)" />
                  )}
                </label>
              </div>
            );
          })}
        </div>

        <div className="bform-actions">
          <div className="bform-actions-right">
            <button onClick={this.onClickCancel} className="btn secondary">Cancel</button>
            {this.renderSubmitBtn()}
          </div>
        </div>
      </Modal>
    );
  }
}

ModalReview.propTypes = {
  onClose: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  entityType: PropTypes.oneOf(['Campaign', 'VolEvent', 'Bracket', 'Group']).isRequired,
  entityName: PropTypes.string.isRequired,
};

ModalReview.defaultProps = {
};








class ModalReviewCampaign extends React.PureComponent {
  render() {
    const {entity: campaign, ...passProps} = this.props;
    return <ModalReview entityType="Campaign" entityName={campaign.name} {...passProps} />;
  }
}
const dispatchToPropsCamp = (dispatch, ownProps) => ({
  onApprove: ()     => dispatch(CampaignsAx.approve(ownProps.company.id, ownProps.entity.id)),
  onDelete:  ()     => dispatch(CampaignsAx.delete( ownProps.company.id, ownProps.entity.id)),
  onReject:  (note) => dispatch(CampaignsAx.reject( ownProps.company.id, ownProps.entity.id, note)),
});
ModalReview.Campaign = connect(undefined, dispatchToPropsCamp)(ModalReviewCampaign);



class ModalReviewVolEvent extends React.PureComponent {
  render() {
    const {entity: volEvent, ...passProps} = this.props;
    return <ModalReview entityType="VolEvent" entityName={volEvent.title} {...passProps} />;
  }
}
const dispatchToPropsEvent = (dispatch, ownProps) => ({
  onApprove: ()     => dispatch(EventsDuck.Ax.approve(ownProps.company.id, ownProps.entity.id)),
  onDelete:  ()     => dispatch(EventsDuck.Ax.delete( ownProps.company.id, ownProps.entity.id)),
  onReject:  (note) => dispatch(EventsDuck.Ax.reject( ownProps.company.id, ownProps.entity.id, note)),
});
ModalReview.VolEvent = connect(undefined, dispatchToPropsEvent)(ModalReviewVolEvent);



class ModalReviewGroup extends React.PureComponent {
  render() {
    const {entity: group, ...passProps} = this.props;
    return <ModalReview entityType="Group" entityName={group.name} {...passProps} />;
  }
}
const dispatchToPropsGroup = (dispatch, ownProps) => ({
  onApprove: ()     => dispatch(GroupsDuck.Ax.approve(ownProps.company.id, ownProps.entity.id)),
  onDelete:  ()     => dispatch(GroupsDuck.Ax.delete( ownProps.company.id, ownProps.entity.id)),
  onReject:  (note) => dispatch(GroupsDuck.Ax.reject( ownProps.company.id, ownProps.entity.id, note)),
});
ModalReview.Group = connect(undefined, dispatchToPropsGroup)(ModalReviewGroup);







export default ModalReview;
