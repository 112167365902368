import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import Checkbox            from 'app/components/common/checkbox';
import Dropdown            from 'app/components/common/dropdown';
import EllipsisMenu        from 'app/components/common/ellipsis-menu';
import Icon                from 'app/components/common/icon';
import Link                from 'app/components/common/link';
import Meta                from 'app/components/common/meta';
import Pagination          from 'app/components/common/pagination';
import ModalEdit           from 'app/components/company-admin/employees/modal-edit';
import ModalEditCattrs     from 'app/components/company-admin/employees/modal-edit-cattrs';
import ModalEditRole       from 'app/components/company-admin/employees/modal-edit-role';
import CadminLayout        from 'app/components/company-admin/layout/';
import RequireRole         from 'app/components/gating/require-role';
import PageLoading         from 'app/components/layout/page-loading';
import {
  EmployeeStatuses as Statuses,
  EmployeeRoles as Roles,
  EmployeeRoleLabels as RoleLabels,
}                          from 'app/constants';
import CadminEmployeesDuck from 'app/ducks/company-admin/employees';
import PageDuck            from 'app/ducks/company-admin/page-employee';
import Metrics             from 'app/metrics';
import paths               from 'app/paths';
import prompts             from 'app/prompts';
import CadminSlx           from 'app/selectors/company-admin/';
import FfSlx               from 'app/selectors/feature-flags';
import RoutingSlx          from 'app/selectors/routing';

const statusBadgeMap = {
  [Statuses.PENDING]:     {color: 'gray',   label: 'Pending'},
  [Statuses.ACTIVE]:      {color: 'green',  label: 'Active'},
  [Statuses.DEACTIVATED]: {color: 'danger', label: 'Deactivated'},
};

class PageCadminEmployee extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showModalRole: false,
      showModalEdit: false,
      showModalCattrs: false,
    };

    this.onClickEditRole = this.onClickEditRole.bind(this);
    this.onCloseEditRole = this.onCloseEditRole.bind(this);
    this.onClickEditCattrs = this.onClickEditCattrs.bind(this);
    this.onCloseEditCattrs = this.onCloseEditCattrs.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onCloseEdit = this.onCloseEdit.bind(this);
    this.onClickDeactivate = this.onClickDeactivate.bind(this);
    this.onClickReactivate = this.onClickReactivate.bind(this);
    this.onClickResend = this.onClickResend.bind(this);
  }

  get isProvisioned() {
    const {employee, company} = this.props;
    return !!(company.scimEnabled && employee.scimId);
  }

  get scimEnabled() {
    return !!this.props.company?.scimEnabled;
  }

  get scimBtnTitle() {
    return this.isProvisioned ? 'Controlled via SCIM Provisioning' : null;
  }

  onClickEditRole() {
    this.setState({showModalRole: true});
  }
  onCloseEditRole() {
    this.setState({showModalRole: false});
  }

  onClickEditCattrs() {
    this.setState({showModalCattrs: true});
  }
  onCloseEditCattrs() {
    this.setState({showModalCattrs: false});
  }

  onClickEdit() {
    this.setState({showModalEdit: true});
  }
  onCloseEdit() {
    this.setState({showModalEdit: false});
  }

  async onClickDeactivate() {
    const msg = 'Are you sure you want to deactivate this employee?';
    const didConfirm = await prompts.confirm({msg, danger: 'Deactivate'});
    if (didConfirm) {
      this.props.deactivate(this.props.employee.id);
    }
  }

  async onClickReactivate() {
    const msg = 'Are you sure you want to reactivate this employee?';
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Reactivate'});
    if (didConfirm) {
      this.props.reactivate(this.props.employee.id);
    }
  }

  async onClickResend() {
    const {employee, invite} = this.props;
    const msg = `Send an invite email to ${employee.firstName} ${employee.lastName} (${employee.email})?`;
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Send Invite'});
    if (didConfirm) {
      invite(employee.email);
    }
  }

  renderSectionDetails() {
    const {employee} = this.props;
    const roleLabel = RoleLabels[employee.role];

    return (<>
      <div className="ca-box-header">
        <h1 className="ca-box-header-title">Details</h1>
        <div className="ca-box-header-controls">
          <button className="btn small secondary blue" onClick={this.onClickEditRole}>Edit Role</button>
          <button className="btn small secondary blue" disabled={this.isProvisioned} title={this.scimBtnTitle} onClick={this.onClickEdit}>Edit Details</button>
        </div>
      </div>
      <div className="ca-box-body">
        <table className="attrs">
          <tbody>
            <tr>
              <th>First Name</th>
              <td>{employee.firstName}</td>
            </tr>
            <tr>
              <th>Last Name</th>
              <td>{employee.lastName}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{employee.email}</td>
            </tr>
            <tr>
              <th>Role</th>
              <td>{roleLabel}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>);
  }



  renderSectionStatus() {
    const {employee, deactivatePending, reactivatePending, invitePending} = this.props;
    if (!employee.status) return null;
    const statusBadge = statusBadgeMap[employee.status];

    const statusMsg = (() => {
      if (employee.status === Statuses.ACTIVE)      return `Activated on ${moment.utc(employee.activatedAt).format('MMMM DD, YYYY')}.`;
      if (employee.status === Statuses.DEACTIVATED) return `Deactivated on ${moment.utc(employee.deactivatedAt).format('MMMM DD, YYYY')}.`;
      if (employee.status === Statuses.PENDING)     return `Employee added on ${moment.utc(employee.createdAt).format('MMMM DD, YYYY')}.`;
    })();

    const showDeactivate = employee.status !== Statuses.DEACTIVATED;
    const showReactivate = employee.status === Statuses.DEACTIVATED;
    const showResend = employee.status === Statuses.PENDING;

    return (<>
      <div className="ca-box-header">
        <h1 className="ca-box-header-title">Status</h1>
        <div className="ca-box-header-controls">
          {showDeactivate && (
            <button className="btn small secondary danger" onClick={this.onClickDeactivate} disabled={this.isProvisioned || deactivatePending} title={this.scimBtnTitle}>{deactivatePending ? 'Deactivating...' : 'Deactivate'}</button>
          )}
          {showReactivate && (
            <button className="btn small secondary blue" onClick={this.onClickReactivate} disabled={this.isProvisioned || reactivatePending} title={this.scimBtnTitle}>{reactivatePending ? 'Reactivating...' : 'Reactivate'}</button>
          )}
          {showResend && (
            <button className="btn small secondary blue" onClick={this.onClickResend} disabled={invitePending}>{invitePending ? 'Sending...' : 'Resend Invite'}</button>
          )}
        </div>
      </div>
      <div className="ca-box-body">
        <div className={`ca-page-emp-badge ${statusBadge.color}`}>{statusBadge.label}</div>
        <p>{statusMsg}</p>
      </div>
    </>);
  }

  renderSectionScim() {
    const {employee, company} = this.props;
    if (!company.scimEnabled) return null;
    const isOn = !!employee.scimId;
    const msg = isOn
      ? 'This employee has been provisioned via SCIM. Some fields can not be changed here as they are set automatically via SCIM.'
      : 'This employee has not been provisioned via SCIM. Fields must be updated manually.';

    return (<>
      <div className="ca-box-header">
        <h1 className="ca-box-header-title">SCIM Provisioning</h1>
        <div className="ca-box-header-controls">
        </div>
      </div>
      <div className="ca-box-body">
        <div className={`ca-page-emp-badge ${isOn ? 'green' : 'danger'}`}>{isOn ? 'Provisioned' : 'Manual'}</div>
        <p>{msg}</p>
      </div>
    </>);
  }

  renderSectionCattrs() {
    const {cattrSet, employee, ffCattrs} = this.props;
    if (!ffCattrs) return null;
    const cattrs = cattrSet?.cattrs;
    if (!cattrs.length) return null;

    return (<>
      <div className="ca-box-header">
        <h1 className="ca-box-header-title">Custom Attributes</h1>
        <div className="ca-box-header-controls">
          <button className="btn small secondary blue" onClick={this.onClickEditCattrs}>Edit Attributes</button>
        </div>
      </div>
      <div className="ca-box-body">
        <table className="attrs">
          <tbody>
            {cattrs.map((cattr) => {
              if (!cattr.options.length) return null;
              const option = cattr.options.find((opt) => {
                const id = (employee.cattrs || {})[cattr.id];
                return opt.id === id;
              });
              return (
                <tr key={cattr.key}>
                  <th>{cattr.name}</th>
                  <td>{option?.name || ''}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>);
  }

  renderSectionMatch() {
    const {employee, company, matchEmpAmount} = this.props;
    const year = company.currentYear;
    if (employee.totalMatchedAmountInCentsByYear == null) return null;
    const allowedAmountInCents = matchEmpAmount;
    const usedAmountInCents = employee.totalMatchedAmountInCentsByYear[`${year}`] || 0;
    const leftAmountInCents = Math.max((allowedAmountInCents - usedAmountInCents), 0);

    return (<>
      <div className="ca-box-header">
        <h1 className="ca-box-header-title">Match ({year})</h1>
        <div className="ca-box-header-controls">
        </div>
      </div>
      <div className="ca-box-body">
        <table className="attrs">
          <tbody>
            <tr>
              <th>Offered</th>
              <td className="right">{numeral(allowedAmountInCents / 100).format('$0,0.00')}</td>
            </tr>
            <tr>
              <th>Used</th>
              <td className="right">{numeral(usedAmountInCents / 100).format('$0,0.00')}</td>
            </tr>
            <tr>
              <th>Left</th>
              <td className="right">{numeral(leftAmountInCents / 100).format('$0,0.00')}</td>
            </tr>            
          </tbody>
        </table>
      </div>
    </>);
  }

  render() {
    const {company, employee} = this.props;
    const {showModalRole, showModalEdit, showModalCattrs} = this.state;
    if (!company || !employee) return <PageLoading />;

    return (
      <CadminLayout className="ca-page-emp" company={company} activeItem="employees">
        <Meta title={`${employee ? (employee.firstName + ' ' + employee.lastName) : 'Employee'} | Millie`} />
        
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">{`Employee: ${employee.firstName} ${employee.lastName}`}</h1>
          <div className="ca-main-head-actions">
          </div>
        </div>

        <div className="ca-box">
          {this.renderSectionDetails()}
          {this.renderSectionStatus()}
          {this.renderSectionScim()}
          {this.renderSectionMatch()}
          {this.renderSectionCattrs()}
        </div>

        {showModalRole && (
          <ModalEditRole employee={employee} company={company} onClose={this.onCloseEditRole} />
        )}
        {showModalCattrs && (
          <ModalEditCattrs employee={employee} onClose={this.onCloseEditCattrs} />
        )}
        {showModalEdit && (
          <ModalEdit employee={employee} company={company} onClose={this.onCloseEdit} />
        )}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  cattrSet: CadminSlx.cattrSet(state),
  matchEmpAmount: CadminSlx.matchEmpAmount(state),
  query: RoutingSlx.query(state),
  ffCattrs: FfSlx['cattrs'](state),

  employee: PageDuck.Slx.employee(state),

  deactivatePending: CadminEmployeesDuck.Slx.deactivatePending(state),
  reactivatePending: CadminEmployeesDuck.Slx.activatePending(state),
  invitePending: CadminEmployeesDuck.Slx.invitePending(state),
});

const dispatchToProps = (dispatch) => ({
  deactivate: (empId) => dispatch(CadminEmployeesDuck.Ax.deactivate(undefined, empId)),
  reactivate: (empId) => dispatch(CadminEmployeesDuck.Ax.activate(undefined, empId)),
  invite: (email) => dispatch(CadminEmployeesDuck.Ax.invite(email)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminEmployee);
