import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageWalletAx       from 'app/actions/page-wallet';
import RecDonationsAx     from 'app/actions/recurring-donations';
import RecPayDedAx        from 'app/actions/recurring-payroll-deductions';
import WalletAx           from 'app/actions/wallet';
import AmountSelector     from 'app/components/common/amount-selector';
import CurrencyPicker     from 'app/components/common/currency-picker';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import StripeGuarantee    from 'app/components/common/stripe-guarantee';
import MainLayout         from 'app/components/layout/main-layout';
import PageLoading        from 'app/components/layout/page-loading';
import NonprofitCard      from 'app/components/nonprofits/nonprofit-card';
import MyActions          from 'app/components/wallet/actions';
import CreditCardSelector from 'app/components/wallet/credit-card-selector';
import ModalConfirmLoad   from 'app/components/wallet/modal-confirm-load';
import ModalLoadSuccess   from 'app/components/wallet/modal-load-success';
import ModalReceipts      from 'app/components/wallet/modal-receipts';
import {
  MIN_LOAD_AMOUNT_IN_CENTS,
  USER_DEFAULT_AVATAR_URL,
  RecurringDonationStatuses as Statuses,
  RecurringDonationPauseReasons as PauseReasons,
}                         from 'app/constants';
import dafs               from 'app/dafs';
import currencies         from 'app/helpers/currencies';
import format             from 'app/helpers/format';
import history            from 'app/history';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import AuthSlx            from 'app/selectors/auth';
import PageWalletSlx      from 'app/selectors/page-wallet';
import RecDonationsSlx    from 'app/selectors/recurring-donations';
import RoutingSlx         from 'app/selectors/routing';

const Tabs = {
  ACTIONS: 'activity',
  REC_DONS: 'recurring-donations',
  REC_PAY_DEDS: 'recurring-payroll-deductions',
};

const RecDonReasonMsgMap = {
  [PauseReasons.NO_PAYMENT_PROFILE]: 'missing payment method',
  [PauseReasons.PAYMENT_FAILED]: 'failed payment',
  [PauseReasons.ORG_INELIGIBLE]: 'org ineligible',
};

const multipliers = [10, 20, 50, 100, 200];

class PageWallet extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refCcSelector = React.createRef();

    this.state = {
      selectedAmount: null,
      selectedTab: Tabs.ACTIONS,
      showConfirm: false,
    };

    this.onClickLoad = this.onClickLoad.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onChangeSelectedAmount = this.onChangeSelectedAmount.bind(this);
    this.onCloseConfirmModal = this.onCloseConfirmModal.bind(this);
    this.onCloseSuccessModal = this.onCloseSuccessModal.bind(this);
    this.onClickTabActions = this.onClickTabActions.bind(this);
    this.onClickTabRecDons = this.onClickTabRecDons.bind(this);
    this.onClickTabRecPayDeds = this.onClickTabRecPayDeds.bind(this);
    this.onClickReceipts = this.onClickReceipts.bind(this);
    this.onCloseReceipts = this.onCloseReceipts.bind(this);
  }

  onClickLoad() {
    if (!this.selectedAmountValid) return;
    this.setState({showConfirm: true});
  }

  onConfirm() {
    if (!this.selectedAmountValid) return;
    const { selectedAmount } = this.state;
    const ccParams = this.refCcSelector.current.params;
    this.props.loadWallet(ccParams, selectedAmount.amount, selectedAmount.currencyCode).then(() => {
      this.setState({showConfirm: false});
    });
  }

  onChangeSelectedAmount(selectedAmount) {
    this.setState({selectedAmount});
  }

  onCloseConfirmModal() {
    this.setState({showConfirm: false});
    this.props.clearLoadError();
  }

  onCloseSuccessModal({goToPath}={}) {
    this.setState({selectedAmount: null});
    this.props.clearSuccess();
    if (goToPath) history.push(goToPath);
  }

  onClickTabActions() {
    this.setState({selectedTab: Tabs.ACTIONS});
  }
  onClickTabRecDons() {
    this.setState({selectedTab: Tabs.REC_DONS});
  }
  onClickTabRecPayDeds() {
    this.setState({selectedTab: Tabs.REC_PAY_DEDS});
  }

  onClickReceipts() {
    this.props.openReceiptsModal();
  }
  onCloseReceipts() {
    this.props.closeReceiptsModal();
  }

  async onClickCancelRecDon(recurringDonation, pendingText, event) {
    const isPaused = recurringDonation.status === Statuses.PAUSED;
    const didConfirm = await (isPaused
      ? true
      : prompts.confirm({msg: 'Are you sure you want to cancel this recurring donation?', danger: 'Cancel Recurring Donation', cancelBtnLabel: 'Nevermind'}));
    if (didConfirm) {
      event.target.disabled = true;
      event.target.innerHTML = pendingText;
      this.props.cancelRecDonation(recurringDonation.id);
    }
  }

  async onClickCancelRecPayDed(rpd, event) {
    const didConfirm = await prompts.confirm({msg: 'Are you sure you want to cancel this recurring payroll donation?', danger: 'Cancel Payroll Donation', cancelBtnLabel: 'Nevermind'});
    if (didConfirm) {
      event.target.disabled = true;
      event.target.innerHTML = 'Cancelling...';
      this.props.cancelRecPayDed(rpd.id);
    }
  }

  get selectedAmountValid() {
    const { selectedAmount: sa } = this.state;
    return !!(sa && (sa.amount >= this.currency.smallRoundAmount));
  }

  get hasMatch() {
    const company = _.get(this.props, 'currentUser.employment.company');
    if (!company) return false;
    return !!(company.currentMatchBudget?.employeeAmount && company.currentMatchPercent);
  }

  get currency() {
    return currencies.byCode[this.props.currentUser?.currencyCode || 'USD'];
  }

  get blockOnAmexSupport() {
    const {selectedCreditCard} = this.props;
    if (this.currency.amexSupport) return false;
    if (!selectedCreditCard) return false;
    if (selectedCreditCard.brand === 'american express') return true;
    return false;
  }

  renderHeader() {
    const { currentUser } = this.props;
    const { avatarUrl, firstName, employment } = currentUser;
    const matchLeftAmount = _.get(employment, 'employee.currentMatchAmounts.left', 0);

    return (
      <div className="page-wallet-header">
        <div className="page-wallet-header-title-con">
          <img className="page-wallet-header-image" src={avatarUrl ||  USER_DEFAULT_AVATAR_URL} alt={firstName} />
          <h1 className="page-wallet-header-title">My Giving Wallet</h1>
        </div>
        <div className="page-wallet-header-amount">
          <div className="page-wallet-header-amount-amount">{format.usd(currentUser.totalBalanceAmount, 'floor')}</div>
          <div className="page-wallet-header-amount-label">left to give</div>
        </div>
        {this.hasMatch && (
          <div className="page-wallet-header-amount match">
            <div className="page-wallet-header-amount-amount">{format.usd(matchLeftAmount, 'floor')}</div>
            <div className="page-wallet-header-amount-label">match left</div>
          </div>
        )}
      </div>
    );
  }

  renderOneOffSelections() {
    const { selectedAmount } = this.state;

    return (
      <div className="upload-box-one-offs">
        <h3><Icon.LoveHeartHandsHold />One Time Upload</h3>
        <AmountSelector
          multipliers={multipliers}
          onChange={this.onChangeSelectedAmount}
          selectedAmount={selectedAmount}
          type={AmountSelector.TYPE_ONE_OFF}
          currencyCode={this.currency.code}
        />
      </div>
    );
  }

  renderPayment() {
    const {canLoad, loadWalletPending, selectedCreditCard} = this.props;
    const loadButtonDisabled = !canLoad || !this.selectedAmountValid || loadWalletPending || this.blockOnAmexSupport;

    return (
      <div className="upload-box-payment">
        <CreditCardSelector onChangeCanSubmit={this.onChangeCanSubmitPp} ref={this.refCcSelector} />
        {this.blockOnAmexSupport && (
          <p className="upload-box-payment-amex-currency-block">American Express doesn't accept currency {this.currency.code}.</p>
        )}
        <button className="upload-box-payment-button btn special jungle" onClick={this.onClickLoad} disabled={loadButtonDisabled}>{loadWalletPending ? 'Submitting...' : 'Continue'}</button>
        <StripeGuarantee />
      </div>
    );
  }

  renderBalances() {
    const { currentUser } = this.props;
    const matchLeftAmount = _.get(currentUser, 'employment.employee.currentMatchAmounts.left', 0);
    const matchUsedAmount = _.get(currentUser, 'employment.employee.currentMatchAmounts.used', 0);

    return (
      <div className={`page-wallet-balances ${this.hasMatch ? 'show-match' : 'no-match'}`}>
        <div className="page-wallet-balances-box pwbb wallet">
          <h4 className="pwbb-title">Wallet Balance</h4>
          <div className="pwbb-amounts">
            <div className="pwbb-amounts-main">
              <div className="pwbb-amounts-main-val gradient-green-text">{format.usd(currentUser.totalBalanceAmount)}</div>
              <div className="pwbb-amounts-main-key">Total Balance</div>
            </div>
            <div className="pwbb-amounts-sub-row">
              <div className="pwbb-amounts-sub a">
                <div className="pwbb-amounts-sub-val">{format.usd(currentUser.balanceAmount)}</div>
                <div className="pwbb-amounts-sub-key">Uploaded</div>
              </div>
              <div className="pwbb-amounts-sub b">
                <div className="pwbb-amounts-sub-val">{format.usd(currentUser.giftBalanceAmount)}</div>
                <div className="pwbb-amounts-sub-key">Gifted</div>
              </div>
            </div>
          </div>
        </div>
        {this.hasMatch && (
          <div className="page-wallet-balances-spacer" />
        )}
        {this.hasMatch && (
          <div className="page-wallet-balances-box pwbb match">
          <h4 className="pwbb-title">Match Program</h4>
          <div className="pwbb-amounts">
            <div className="pwbb-amounts-main">
              <div className="pwbb-amounts-main-val gradient-purple-text">{format.usd(matchLeftAmount)}</div>
              <div className="pwbb-amounts-main-key">Match Left</div>
            </div>
            {/* <div className="pwbb-amounts-sub a"> */}
            {/*   <div className="pwbb-amounts-sub-val">$50</div> */}
            {/*   <div className="pwbb-amounts-sub-key">Pending</div> */}
            {/* </div> */}
            <div className="pwbb-amounts-sub b">
              <div className="pwbb-amounts-sub-val">{format.usd(matchUsedAmount)}</div>
              <div className="pwbb-amounts-sub-key">Used</div>
            </div>
          </div>
          </div>
        )}
      </div>
    );
  }

  renderTabs() {
    const { currentUser } = this.props;
    const hasPayroll = !!_.get(currentUser, 'employment.company.features.payroll');
    const activeClass = (tab) => {
      return (this.state.selectedTab === tab) ? 'active' : '';
    }
    return (
      <div className="actions-box-tabs">
        <button className={`actions-box-tabs-tab ${activeClass(Tabs.ACTIONS)}`}onClick={this.onClickTabActions}>Past Activity</button>
        <button className={`actions-box-tabs-tab ${activeClass(Tabs.REC_DONS)}`}onClick={this.onClickTabRecDons}>Recurring Donations</button>
        {hasPayroll && (
          <button className={`actions-box-tabs-tab ${activeClass(Tabs.REC_PAY_DEDS)}`}onClick={this.onClickTabRecPayDeds}>Payroll Donations</button>
        )}
      </div>
    );
  }

  renderRecurringDonations() {
    const { recurringDonations } = this.props;
    return (
      <div className="page-wallet-rds">
        <p className="page-wallet-rds-notice">Monthly recurring donations with the payment method set to Wallet Balance will be deducted from your Wallet Balance. If your Wallet Balance is too low, the difference will be charged to your default credit card.</p>
        {recurringDonations.map((rd) => {
          const currency = currencies.byCode[rd.currencyCode];
          const formattedAmount = `${currency.format(rd.amount)} ${rd.currencyCode === 'USD' ? '' : rd.currencyCode}`;
          const isPaused = rd.status === Statuses.PAUSED;
          const dueTo = RecDonReasonMsgMap[rd.pauseReason] || 'other';
          const cancelText = isPaused ? 'Remove' : 'Cancel';
          const pendingCancelText = isPaused ? 'Removing...' : 'Cancelling...';
          const ccLabel = rd.creditCardDetails
            ? `${rd.creditCardDetails.label}, ${rd.creditCardDetails.brand} **${rd.creditCardDetails.last4}`
            : null;
          return (
            <div key={rd.id} className="page-wallet-rd">
              <div className="page-wallet-rd-card">
                <div className="page-wallet-rd-card-amount">{formattedAmount}</div>
                <NonprofitCard id={rd.nonprofitId} small />
              </div>
              <div className="page-wallet-rd-details">
                <p className="page-wallet-rd-details-desc">
                  <Icon.SyncHeart />
                  Recurring donation of {formattedAmount} on the {moment(rd.nextDonationDate).format('Do')} of every month.
                </p>
                <p className="page-wallet-rd-details-desc">
                  Payment Method: {rd.useDefaultCc ? 'Wallet Balance' : ccLabel}
                </p>
                {isPaused && (
                  <p className="page-wallet-rd-details-error">
                    This donation has been cancelled due to {dueTo}.
                  </p>
                )}
                <button className="btn secondary small slate" onClick={this.onClickCancelRecDon.bind(this, rd, pendingCancelText)}>{cancelText}</button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderPayrollDeductions() {
    const { recurringPayrollDeductions: rpds } = this.props;
    return (
      <div className="page-wallet-rds">
        <p className="page-wallet-rds-notice">Payroll donations are made on a monthly recurring basis and will be deducted from your post-tax payroll via your employer. Payroll donations process on the 5th of every month.</p>
        {rpds.map((rpd) => {
          const formattedAmount = format.usd(rpd.amountInCents);// numeral(Math.round(rpd.amountInCents / 100)).format('$0,0');
          return (
            <div key={rpd.id} className="page-wallet-rd">
              <div className="page-wallet-rd-card">
                <div className="page-wallet-rd-card-amount">{formattedAmount}</div>
                <NonprofitCard id={rpd.donatableId} small />
              </div>
              <div className="page-wallet-rd-details">
                <p className="page-wallet-rd-details-desc">
                  <Icon.SyncHeart />
                  Recurring donation of {formattedAmount} on the 5th of every month
                </p>
                <button className="btn secondary small slate" onClick={this.onClickCancelRecPayDed.bind(this, rpd)}>Cancel</button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderOfflineDonationLink() {
    const { currentUser } = this.props;
    const hasMatch = !!_.get(currentUser, 'employment.company.features.match');
    if (!hasMatch) return null;
    return <Link className="btn small secondary" href={paths.offlineDonationsNew()}>Submit Offline Donation</Link>;
  }

  render() {
    const { loadPagePending, currentUser, loadWalletPending, loadWalletError, loadWalletSuccess, showReceiptsModal } = this.props;
    const { selectedTab, showConfirm, selectedAmount } = this.state;
    if (loadPagePending) return <PageLoading />;

    return (
      <MainLayout className="page-wallet">
        <Meta title="Giving Wallet | Millie" />
        <div className="widther">
          {this.renderHeader()}
          <div className="page-wallet-box upload-box">
            <CurrencyPicker className="page-wallet-box-currency-picker" />
            <h2>Add money to donate</h2>
            <div className="upload-box-selections">
              {this.renderOneOffSelections()}
            </div>
            {this.renderPayment()}
          </div>
          <p className="page-wallet-5-percent-a">Your donation is tax-deductible. You will get a receipt from our partner {dafs.current.name}, a 501(c)(3) nonprofit.</p>
          {this.renderBalances()}
          <div className="page-wallet-box actions-box">
            <div className="actions-box-controls">
              <Link className="btn small secondary" href={paths.creditCards}>Manage Payment Methods</Link>
              {this.renderOfflineDonationLink()}
              <button className="btn small secondary icon" onClick={this.onClickReceipts}><Icon.ReceiptDollar />Tax Receipts</button>
            </div>
            {this.renderTabs()}
            {selectedTab === Tabs.ACTIONS
              ? <MyActions />
              : selectedTab === Tabs.REC_DONS
                ? this.renderRecurringDonations()
                : this.renderPayrollDeductions()
            }
            <div className="actions-box-left">
              <div className="actions-box-left-amount">{format.usd(currentUser.totalBalanceAmount, 'floor')}</div>
              <div className="actions-box-left-label">left to give</div>
            </div>
          </div>
        </div>
        {loadWalletSuccess &&
          <ModalLoadSuccess loadSuccess={loadWalletSuccess} onClose={this.onCloseSuccessModal} />
        }
        {showConfirm &&
          <ModalConfirmLoad onClose={this.onCloseConfirmModal} amount={selectedAmount.amount} currencyCode={selectedAmount.currencyCode} loadPending={loadWalletPending} loadError={loadWalletError} onConfirm={this.onConfirm} />
        }
        {showReceiptsModal &&
          <ModalReceipts onClose={this.onCloseReceipts} />
        }
      </MainLayout>
    );
  }

}

const stateToProps = (state) => ({
  canLoad: PageWalletSlx.canLoad(state),
  loadPagePending: PageWalletSlx.loadPagePending(state),
  loadWalletPending: PageWalletSlx.loadWalletPending(state),
  loadWalletSuccess: PageWalletSlx.loadWalletSuccess(state),
  loadWalletError: PageWalletSlx.loadWalletError(state),
  recurringPayrollDeductions: PageWalletSlx.recurringPayrollDeductions(state),
  showReceiptsModal: PageWalletSlx.showReceiptsModal(state),
  selectedCreditCard: PageWalletSlx.selectedCreditCard(state),

  currentUser: AuthSlx.currentUser(state),
  recurringDonations: RecDonationsSlx.recurringDonations(state),
});

const dispatchToProps = (dispatch) => ({
  loadWallet: (ccParams, amount, currencyCode) => dispatch(PageWalletAx.loadWallet(ccParams, amount, currencyCode)),
  clearSuccess: () => dispatch(PageWalletAx.clearSuccess()),
  clearLoadError: () => dispatch(PageWalletAx.clearLoadError()),
  openReceiptsModal: () => dispatch(PageWalletAx.showReceiptsModal()),
  closeReceiptsModal: () => dispatch(PageWalletAx.closeReceiptsModal()),
  cancelRecDonation: (rdId) => dispatch(RecDonationsAx.cancel(rdId)),
  cancelRecPayDed: (rpdId) => dispatch(RecPayDedAx.cancel(rpdId)),
});

export default connect(stateToProps, dispatchToProps)(PageWallet);
