import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Ax as PageAx,
  Slx as PageSlx,
} from 'app/ducks/company-admin/page-groups-settings';

import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import StandardInput      from 'app/components/common/standard-input';
import CadminLayout       from 'app/components/company-admin/layout/';
import PageLoading        from 'app/components/layout/page-loading';
import format             from 'app/helpers/format';
import paths              from 'app/paths';
import CadminSlx          from 'app/selectors/company-admin/';

class PageCadminGroupsSettings extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      editingId: null,
      editingName: null,
    };

    this.refSections = React.createRef();

    this.onClickSave = this.onClickSave.bind(this);
    this.onClickAddType = this.onClickAddType.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onKeyPressName = this.onKeyPressName.bind(this);
    this.onBlurName = this.onBlurName.bind(this);
  }

  onClickSave() {
    this.props.saveTypes();
  }

  onClickAddType() {
    this.props.addType();
  }

  onClickMoveUp(groupType) {
    const fromIndex = this.props.groupTypes.indexOf(groupType);
    const toIndex = fromIndex - 1;
    if (toIndex < 0) return null;
    this.props.moveType(fromIndex, toIndex);
  }

  onClickMoveDown(groupType) {
    const fromIndex = this.props.groupTypes.indexOf(groupType);
    const toIndex = fromIndex + 1;
    if (toIndex >= this.props.groupTypes.length) return null;
    this.props.moveType(fromIndex, toIndex);
  }

  onClickRemove(groupType) {
    if (groupType.groupCount) return;
    const index = this.props.groupTypes.indexOf(groupType);
    this.props.removeType(index);
  }

  onClickRename(groupType) {
    this.setState({editingId: groupType.id, editingName: groupType.name}, () => {;
      const inputEl = this.refSections.current.querySelector('.standard-input input');
      if (inputEl) inputEl.focus();
    });
  }

  onChangeName(event) {
    const editingName = event.target.value;
    this.setState({editingName});
  }

  onKeyPressName(event) {
    if (event.key !== 'Enter') return;
    this.setName();
  }

  onBlurName() {
    this.setName();
  }

  setName() {
    const { editingId, editingName } = this.state;
    const { groupTypes, renameType } = this.props;
    const gt = groupTypes.find(gt => gt.id === editingId);
    const index = groupTypes.indexOf(gt);
    renameType(index, editingName);
    this.setState({editingId: null, editingName: null});
  }

  renderGroupTypes() {
    const { groupTypes, hasChanges } = this.props;
    const { editingId, editingName } = this.state;
    if (!groupTypes) return null;

    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Sections</h1>
          <div className="ca-box-header-controls">
            <button className="btn blue" onClick={this.onClickSave} disabled={!hasChanges}>Save Changes</button>
          </div>
        </div>
        <div className="ca-box-body">
          <div className="ca-grps-cfg-sections" ref={this.refSections}>
            {groupTypes.map((gt, i) => {
              const canRemove = !gt.groupCount;
              const isTop = !i;
              const isBottom = i >= (groupTypes.length - 1);
              const isEditing = editingId === gt.id;
              return (
                <div key={gt.id} className="ca-grps-cfg-section">
                  <div className="ca-grps-cfg-section-move">
                    <button className="icon-btn" onClick={this.onClickMoveUp.bind(this, gt)} disabled={isTop}><Icon.Caret direction="up" /></button>
                    <button className="icon-btn" onClick={this.onClickMoveDown.bind(this, gt)} disabled={isBottom}><Icon.Caret direction="down" /></button>
                  </div>
                  <div className="ca-grps-cfg-section-name">
                    {isEditing
                      ? <StandardInput name="name" label="Name" value={editingName || ''} onChange={this.onChangeName} onKeyPress={this.onKeyPressName} onBlur={this.onBlurName} />
                      : <div className="ca-grps-cfg-section-name-label" onClick={this.onClickRename.bind(this, gt)}>{gt.name}</div>
                    }
                    <div className="ca-grps-cfg-section-name-count">
                      {`${gt.groupCount || 0} ${format.pluralize('group', gt.groupCount || 0)}`}
                    </div>
                  </div>
                  <div className="ca-grps-cfg-section-btns">
                    <button className="icon-btn" onClick={this.onClickRename.bind(this, gt)}><Icon.Pencil /></button>
                    <button className="icon-btn" onClick={this.onClickRemove.bind(this, gt)} disabled={!canRemove}><Icon.Bin /></button>
                  </div>
                </div>
              );
            })}
          </div>

          <button className="btn blue small icon secondary" onClick={this.onClickAddType}><Icon.AddCircle />New Section</button>
        </div>
      </div>
    );
  }

  render() {
    const { company } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-grps-cfg" company={company} activeItem="groups">
        <Meta title="Groups Settings | Millie" />

        <div className="ca-main-head">
          <Link className="ca-main-head-back" href={paths.cadminGroups(company.slug)}><Icon.Caret direction="left" /> Back to Groups</Link>
          <h1 className="ca-main-head-h1">Group Settings</h1>
          <div className="ca-main-head-actions" />
        </div>

        {this.renderGroupTypes()}

      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  groupTypes: PageSlx.editTypes(state),
  hasChanges: PageSlx.hasChanges(state),
});

const dispatchToProps = (dispatch) => ({
  saveTypes: () => dispatch(PageAx.saveTypes()),
  addType: () => dispatch(PageAx.addType()),
  moveType: (fromIndex, toIndex) => dispatch(PageAx.moveType(fromIndex, toIndex)),
  removeType: (index) => dispatch(PageAx.removeType(index)),
  renameType: (index, name) => dispatch(PageAx.renameType(index, name)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminGroupsSettings);
