import moment from 'moment';
import _ from 'lodash';

import config        from 'app/config';
import {
  VolEventStatuses as Statuses,
}                    from 'app/constants';
import formatHelpers from 'app/helpers/format';
import utils         from 'app/helpers/utils';
import paths         from 'app/paths';

const editAttrs = [
  'imgPath',
  'title',
  'description',
  'externalUrl',
  'contact',
  'details',
  'addressLine1',
  'city',
  'state',
  'postalCode',
  'latitude',
  'longitude',
  'timezone',
  'isRemote',
  'isOngoing',
  'leadEmployeeId',
  'nonprofitId',
  'primaryGroupId',
  'type',
  'groupIds',
  'links',
  'nteeCode',
  'tags',
  'hasSocialFeed',
];

const textAttrs = [
  'title',
  'description',
  'contact',
  'details',
];

const formatShiftTime = (shift, editMode=false, short=false) => {
  const format = short ? 'ddd, MMM Do' : 'dddd, MMMM Do, YYYY'; 
  const {dateStr, startTimeStr, endTimeStr, timezoneAbbr} = shift;
  const dateMoment = moment.utc(dateStr, 'YYYY-MM-DD');
  const startTimeMoment = moment.utc(startTimeStr, 'HH:mm');
  const endTimeMoment = moment.utc(endTimeStr, 'HH:mm');
  const dateFmtd = dateMoment.isValid() ? dateMoment.format(format) : '';
  let timeFmtd = startTimeMoment.isValid() ? startTimeMoment.format('h:mma') : '';
  timeFmtd += (endTimeMoment.isValid() && timeFmtd) ? `-${endTimeMoment.format('h:mma')}` : '';
  let str = [dateFmtd, timeFmtd].filter(p => p).join(' | ');
  // don't show timezone when in editMode cuz timezoneAbbr is only calculated on server - it might not still be the same after the time or location is changed
  if (timezoneAbbr && !editMode) str += ` ${timezoneAbbr}`;
  return str;
};

const gcalHref = (event, shift) => {
  const dateFrmt = 'YYYYMMDDTHHmmss[Z]';
  const url = `${config.baseUrl}${paths.volEvent(event.id)}`;
  let details = `${url}\n\n`;
  if (event.details) details += `DETAILS\n${event.details}\n\n`;
  if (event.leadEmployee) details += `EVENT LEAD\n${event.leadEmployee.firstName} ${event.leadEmployee.lastName} (${event.leadEmployee.email})\n\n`;
  if (event.contact) details += `CONTACT\n${event.contact}\n\n`;
  const query = {
    action: 'TEMPLATE',
    text: event.title,
    dates: `${moment.utc(shift.startDate).format(dateFrmt)}/${moment.utc(shift.endDate).format(dateFrmt)}`,
    ctz: event.timezone,
    details,
  };
  if (!event.isRemote && event.city) {
    query.location = `${event.addressLine1}, ${event.city}, ${event.state} ${event.postalCode}`;
  }
  const queryString = utils.buildQueryString(query);
  return `https://calendar.google.com/calendar/render${queryString}`;
};

// {dateStr: "2020-11-09", startTimeStr: "01:00", endTimeStr: "02:00"}
const getShiftValidations = (shift) => {
  const validations = {};
  if (!shift.dateStr) validations['dateStr'] = ['required'];
  if (!shift.startTimeStr) validations['startTimeStr'] = ['required'];
  if (!shift.endTimeStr) validations['endTimeStr'] = ['required'];
  if (shift.startTimeStr && shift.endTimeStr && (shift.endTimeStr < shift.startTimeStr)) validations['endTimeStr'] = ['must be after start time'];
  return Object.keys(validations).length
    ? validations
    : null;
};

const getShiftDurMinutes = (shift) => {
  if (!shift?.startTimeStr || !shift?.endTimeStr) return null;
  const startMom = moment(shift.startTimeStr, 'HH:mm');
  const endMom   = moment(shift.endTimeStr,   'HH:mm');
  return endMom.diff(startMom, 'minutes');
};

const getEventStatus = (event) => {
  if (event.isOngoing) return event.endedAt ? Statuses.ENDED : Statuses.LIVE;
  if (!event.endDate) return Statuses.ENDED;
  const isOver = moment(event.endDate).isBefore();
  return isOver ? Statuses.ENDED : Statuses.LIVE;
};

const getStatusDetails = (event) => {
  const status = getEventStatus(event);
  if (status === Statuses.LIVE) return ['Live', 'green'];
  return ['Ended', 'gray'];
};

const getUpcomingShifts = (event) => {
  const {volEventShifts: shifts} = event;
  return shifts && shifts.filter((shift) => {
    return shift && moment.utc(shift.startDate).isAfter();
  });
};

const getDateLabel = (event) => {
  if (event.isOngoing) {
    return event.endedAt ? `Ended ${moment.utc(event.endedAt).format('MMM D, YYYY')}` : 'Ongoing';
  }
  const {volEventShifts: shifts} = event;
  if (!shifts.length) return null;
  const upcomingShifts = getUpcomingShifts(event);
  const shift = upcomingShifts[0] || shifts[0];
  if (!shift) return null;
  const startMoment = moment.utc(`${shift.dateStr}T${shift.startTimeStr}`);
  let timeStr = startMoment.format('ddd, MMM Do, YYYY, h:mma');
  timeStr += ` ${shift.timezoneAbbr}`;
  const othersCount = shifts.length - 1;
  if (othersCount) {
    timeStr += ` (+ ${othersCount} other ${formatHelpers.pluralize('time', othersCount)})`;
  }
  return timeStr;
};

const getLocationLabel = (event) => {
  if (event.isRemote) return 'Remote';
  if (!event.city) return null;
  let locationStr = event.city;
  if (event.state) {
    locationStr += `, ${event.state}`;
  }
  return locationStr;
};

const getSimpleDateLabel = (event) => {
  if (event.isOngoing) return 'Ongoing';
  if (!event.startDate) return null;
  return moment(event.startDate).format('MMM D, YYYY');
};

const sort = (events) => {
  const arrCopy = [...events];
  const endTs = (event) => {
    if (event.isOngoing && event.endedAt) return moment.utc(event.endedAt).unix();
    const lastShift = _.last(event.volEventShifts);
    if (lastShift) return moment.utc(lastShift.startDate).unix();
    return moment.utc('2100-01-01').unix();
  };
  const createdTs = (event) => {
    return moment.utc(event.createdAt).unix();
  };
  const isEnded = (event) => {
    return moment.unix(endTs(event)).isBefore();
  };
  return arrCopy.sort((a, b) => {
    // upcoming endTs asc, then ongoing createdTs desc, then ended endTs desc
    if (isEnded(a)) {
      if (!isEnded(b)) return 1;
      // both ended - sort endAt desc
      if (endTs(a) > endTs(b)) return -1;
      if (endTs(a) < endTs(b)) return 1;
      return 0;
    }
    if (isEnded(b)) return -1;
    // both active - ongoing last
    if (a.isOngoing && !b.isOngoing) return 1;
    if (!a.isOngoing && b.isOngoing) return -1;
    if (a.isOngoing && b.isOngoing) {
      // both active ongoing - sort createdAt desc
      if (createdTs(a) > createdTs(b)) return -1;
      if (createdTs(a) < createdTs(b)) return 1;
      return 0;
    }
    // both active upcoming - sort endAt asc
    if (endTs(a) > endTs(b)) return 1;
    if (endTs(a) < endTs(b)) return -1;
    return 0;
  });
};

export default {
  editAttrs,
  textAttrs,
  formatShiftTime,
  gcalHref,
  getShiftValidations,
  getShiftDurMinutes,
  getEventStatus,
  getStatusDetails,
  getDateLabel,
  getLocationLabel,
  getSimpleDateLabel,
  sort,
};
