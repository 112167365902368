
import CadminCompaniesAx  from 'app/actions/company-admin/companies';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-settings';
import RoutingSlx         from 'app/selectors/routing';

const Types = {
  LOAD: 'CADMIN_PAGE_SETTINGS_LOAD',
  SAVE_MATCH_AMOUNTS: 'CADMIN_PAGE_SETTINGS_SAVE_MATCH_AMOUNTS',
  SAVE_AUTO_MATCHES: 'CADMIN_PAGE_SETTINGS_SAVE_AUTO_MATCHES',
  SAVE_SEARCH_APPROVED_NONPROFITS_ONLY: 'CADMIN_PAGE_SETTINGS_SAVE_SEARCH_APPROVED_NONPROFITS_ONLY',
  TOGGLE_EDIT_MATCH: 'CADMIN_PAGE_SETTINGS_TOGGLE_EDIT_MATCH',
};

const Actions = {

  load: ({params: {companySlug}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  toggleEditMatchAmounts: (isEditingMatchAmounts) => ({
    type: Types.TOGGLE_EDIT_MATCH, isEditingMatchAmounts,
  }),

  saveAutomaticMatches: (automaticMatches) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = dispatch(CadminCompaniesAx.update(company.id, {automaticMatches}));
    return dispatch({type: Types.SAVE_AUTO_MATCHES, promise});
  },

  saveSearchApprovedNonprofitsOnly: (searchApprovedNonprofitsOnly) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = dispatch(CadminCompaniesAx.update(company.id, {searchApprovedNonprofitsOnly}));
    return dispatch({type: Types.SAVE_SEARCH_APPROVED_NONPROFITS_ONLY, promise});
  },

  saveMatchAmounts: ({matchPercent}) => (dispatch, getState) => {
    const company = CadminSlx.company(getState());
    const promise = dispatch(CadminCompaniesAx.updateMatchAmounts(company.id, company.currentYear, {matchPercent}));
    return dispatch({type: Types.SAVE_MATCH_AMOUNTS, promise});
  },

};

export {
  Types,
};

export default Actions;
