
import VolEventsAx from 'app/actions/vol-events';
import {
  SocialFeedTypes
}                  from 'app/constants';
import SocialDuck  from 'app/ducks/social';
import RoutingSlx  from 'app/selectors/routing';

const feedType = SocialFeedTypes.EVENT;

const Types = {
  LOAD: 'PAGE_VOL_EVENT_LOAD',
};


const Actions = {

  load: ({params, path}) => (dispatch, getState) => {
    // skip when page is already loaded
    const prevPath = RoutingSlx.path(getState());
    if (prevPath === path) return;

    const volEventId = params.id;
    const promise = Promise.all([
      dispatch(VolEventsAx.get(volEventId)),
      dispatch(SocialDuck.Ax.listingsSearch({feedType, feedId: volEventId, limit: 12})),
    ]);
    return dispatch({type: Types.LOAD, promise, volEventId});
  },
};

export {
  Types,
};

export default Actions;
