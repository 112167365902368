import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  VolEventStatuses as Statuses,
  VolEventTypes as Types,
}                  from 'app/constants';
import Ps          from 'app/helpers/publish-statuses';
import helpers     from 'app/helpers/vol-events';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selLoadPending       = state => state.companyAdmin.pageVolEvents.loadPending;
const selVolEventIds       = state => state.companyAdmin.pageVolEvents.volEventIds;
const selPagination        = state => state.companyAdmin.pageVolEvents.pagination;

const selVolEvents = createSelector(
  [selVolEventIds, EntitiesSlx.volEvents, EntitiesSlx.nonprofits, EntitiesSlx.groups],
  (ids, volEvents, nonprofits, groups) => {
    return ids.map((id) => {
      const volEvent = {...volEvents[id]};
      const nonprofit = nonprofits[volEvent.nonprofitId];
      volEvent.nonprofit = nonprofit;
      volEvent.status = helpers.getEventStatus(volEvent);
      volEvent.primaryGroup = groups[volEvent.primaryGroupId] || null;
      return volEvent;
    });
  }
);

const selSearchParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    const obj = _.pick(query, ['page', 'groupId', 'nonprofitId', 'primaryGroupId']);
    obj.publishStatus = Ps.isValid(query.publishStatus) ? query.publishStatus : Ps.ACTIVE;
    if (Object.values(Statuses).includes(query.status)) obj.status = query.status;
    if (Object.values(Types).includes(query.type)) obj.type = query.type;
    return obj;
  }
);

const selFilteredVolEvents = createSelector(
  [selVolEvents, selSearchParams],
  (volEvents, {status, type}) => {
    return volEvents.filter((ve) => {
      if (type && ve.type !== type) return false;
      if (status && ve.status !== status) return false;
      return true;
    });
  }
);

const selLiveVolEvents = createSelector(
  [selFilteredVolEvents],
  (volEvents) => {
    return volEvents.filter(ve => ve.status === Statuses.LIVE);
  }
);

export default {
  loadPending: selLoadPending,
  volEvents: selVolEvents,
  pagination: selPagination,
  filteredVolEvents: selFilteredVolEvents,
  liveVolEvents: selLiveVolEvents,
  searchParams: selSearchParams,
};
