import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CampaignsAx from 'app/actions/company-admin/campaigns';
import Modal       from 'app/components/common/modal';
import GroupsDuck  from 'app/ducks/company-admin/groups';
import EventsDuck  from 'app/ducks/company-admin/vol-events';
import Ps          from 'app/helpers/publish-statuses';

class ModalPublish extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isPublishing: false,
      isSubmitting: false,
    }

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickPublish = this.onClickPublish.bind(this);
  }

  get perms() {
    const {company, entityType, currentUser} = this.props;
    const role = currentUser?.employment?.employee?.role || undefined;
    return Ps.getPerms(entityType, role, (company?.settingsApprovals || {}));
  }
  get isFullAdmin() {
    return this.perms.isFullAdmin;
  }
  get canEdit() {
    return this.perms.canEdit;
  }
  get canPublish() {
    return this.perms.canPublish;
  }
  get reviewRequired() {
    return this.perms.reviewRequired;
  }
  get entityTypeName() {
    return this.perms.entityTypeName;
  }
  get showSubmit() {
    return this.canEdit && this.reviewRequired;
  }
  get showPublish() {
    return this.canPublish;
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickCancel() {
    this.props.onClose();
  }

  async onClickSubmit() {
    this.setState({isSubmitting: true});
    await this.props.onSubmit();
    this.props.onClose();
  }

  async onClickPublish() {
    this.setState({isPublishing: true});
    await this.props.onPublish();
    this.props.onClose();
  }

  render() {
    const {entityName, company} = this.props;
    const {isSubmitting, isPublishing} = this.state;
    const btnsDisabled = isSubmitting || isPublishing;

    return (
      <Modal className="bform" onClose={this.onCloseModal}>
        <h1 className="bform-h1">Publish {this.entityTypeName}</h1>
        <p>Ready to publish {entityName}?</p>
        {this.reviewRequired && (
          <p>{`${company.name} requires review before publishing. ${this.isFullAdmin ? 'Since you have permission to approve, you may publish now.' : ''}`}</p>
        )}
        <div className="bform-actions">
          <div className="bform-actions-right">
            <button onClick={this.onClickCancel} disabled={btnsDisabled} className="btn secondary">Cancel</button>
            {this.showSubmit && (
              <button onClick={this.onClickSubmit} disabled={btnsDisabled} className={`btn ${this.canPublish ? 'secondary' : 'blue'}`}>{isSubmitting ? 'Submitting...' : 'Submit for Review'}</button>
            )}
            {this.showPublish && (
              <button onClick={this.onClickPublish} disabled={btnsDisabled} className="btn blue">{isPublishing ? 'Publishing...' : 'Publish'}</button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

ModalPublish.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  entityType: PropTypes.oneOf(['Campaign', 'VolEvent', 'Bracket', 'Group']).isRequired,
  entityName: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};
ModalPublish.defaultProps = {
};



class ModalPublishCampaign extends React.PureComponent {
  render() {
    const {entity: campaign, ...passProps} = this.props;
    return <ModalPublish entityType="Campaign" entityName={campaign.name} {...passProps} />;
  }
}
const dispatchToPropsCamp = (dispatch, ownProps) => ({
  onPublish: () => dispatch(CampaignsAx.publish(ownProps.company.id, ownProps.entity.id)),
  onSubmit: () => dispatch(CampaignsAx.submit(ownProps.company.id, ownProps.entity.id)),
});
ModalPublish.Campaign = connect(undefined, dispatchToPropsCamp)(ModalPublishCampaign);



class ModalPublishVolEvent extends React.PureComponent {
  render() {
    const {entity: volEvent, ...passProps} = this.props;
    return <ModalPublish entityType="VolEvent" entityName={volEvent.title} {...passProps} />;
  }
}
const dispatchToPropsEvent = (dispatch, ownProps) => ({
  onPublish: () => dispatch(EventsDuck.Ax.publish(ownProps.company.id, ownProps.entity.id)),
  onSubmit: () => dispatch(EventsDuck.Ax.submit(ownProps.company.id, ownProps.entity.id)),
});
ModalPublish.VolEvent = connect(undefined, dispatchToPropsEvent)(ModalPublishVolEvent);



class ModalPublishGroup extends React.PureComponent {
  render() {
    const {entity: group, ...passProps} = this.props;
    return <ModalPublish entityType="Group" entityName={group.name} {...passProps} />;
  }
}
const dispatchToPropsGroup = (dispatch, ownProps) => ({
  onPublish: () => dispatch(GroupsDuck.Ax.publish(ownProps.company.id, ownProps.entity.id)),
  onSubmit: () => dispatch(GroupsDuck.Ax.submit(ownProps.company.id, ownProps.entity.id)),
});
ModalPublish.Group = connect(undefined, dispatchToPropsGroup)(ModalPublishGroup);



export default ModalPublish;
