import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { createSelector } from 'reselect';

import CadminBankAccountsAx from 'app/actions/company-admin/bank-accounts';
import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminCreditCardsAx  from 'app/actions/company-admin/credit-cards';
import NonprofitsAx         from 'app/actions/nonprofits';
import ToastAx              from 'app/actions/toast';
import CadminApi            from 'app/apis/company-admin';
import {
  PaymentMethodTypes,
  COMPANY_BALANCE,
  DonatableTypes,
}                           from 'app/constants';
import fees                 from 'app/helpers/fees';
import utils                from 'app/helpers/utils';
import history              from 'app/history';
import paths                from 'app/paths';
import reducerUtils         from 'app/reducers/utils';
import CadminSlx            from 'app/selectors/company-admin/';
import EntitiesSlx          from 'app/selectors/entities';
import RoutingSlx           from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_GRANTS_NEW_LOAD',
  SET_SELECTED_PM: 'CADMIN_PAGE_GRANTS_NEW_SET_SELECTED_PM',
  SET_AMOUNT: 'CADMIN_PAGE_GRANTS_NEW_SET_AMOUNT',
  SET_NOTE: 'CADMIN_PAGE_GRANTS_NEW_SET_NOTE',
  SET_WANTS_COVER_FEE: 'CADMIN_PAGE_GRANTS_NEW_SET_WANTS_COVER_FEE',
  SUBMIT: 'CADMIN_PAGE_GRANTS_NEW_SUBMIT',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const {companySlug} = params;
    const {nonprofitId} = query;
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      ...(nonprofitId ? [dispatch(NonprofitsAx.get(nonprofitId))] : [null]),
      dispatch(CadminBankAccountsAx.fetch(companySlug)),
      dispatch(CadminCreditCardsAx.fetch(companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  setNonprofit: (nonprofit) => (dispatch, getState) => {
    dispatch(NonprofitsAx.set(nonprofit));
    const companySlug = CadminSlx.companySlug(getState());
    const path = paths.cadminGrantsCreate(companySlug, {nonprofitId: nonprofit.id});
    history.push(path);
  },

  setSelectedPaymentMethod: (pm) => {
    return {type: Types.SET_SELECTED_PM, pm};
  },

  setAmount: (amount) => {
    return {type: Types.SET_AMOUNT, amount};
  },

  setNote: (note) => {
    return {type: Types.SET_NOTE, note};
  },

  setWantsCoverFee: (wantsCoverFee) => {
    return {type: Types.SET_WANTS_COVER_FEE, wantsCoverFee};
  },

  submit: () => (dispatch, getState) => {
    const state = getState();
    const params = Slx.params(state);
    const company = CadminSlx.company(state);
    const nonprofit = Slx.nonprofit(state);
    // const promise = utils.wait(3000);
    const promise = CadminApi.grantsCreateWithPm(company.id, params);
    promise.then(() => {
      dispatch(ToastAx.success(`Grant sent to ${nonprofit.name}`));
      history.push(paths.cadminGrants(company.slug));
    })
    return dispatch({type: Types.SUBMIT, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  loadPending: false,
  bankAccountIds: null,
  creditCardIds: null,
  selectedPmType: null,
  selectedPmId: null,
  nominalAmount: null,
  wantsCoverFee: false,
  note: null,
  submitPending: false,
  submitSuccess: false,
  submitError: false,
  submitErrorIneligible: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
      bankAccountIds: null,
      creditCardIds: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const bankAccountIds = action.result[2].bankAccounts.map(ba => ba.id);
    const creditCardIds  = action.result[3].creditCards.map(cc => cc.id);
    return {...state,
      loadPending: false,
      bankAccountIds,
      creditCardIds,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

  [Types.SET_SELECTED_PM]: (state, action) => {
    const {pm} = action;
    return {...state,
      selectedPmType: pm.type,
      selectedPmId: pm.id || null,
    };
  },

  [Types.SET_AMOUNT]: (state, action) => {
    return {...state,
      nominalAmount: action.amount,
    };
  },

  [Types.SET_NOTE]: (state, action) => {
    return {...state,
      note: action.note,
    };
  },

  [Types.SET_WANTS_COVER_FEE]: (state, action) => {
    return {...state,
      wantsCoverFee: action.wantsCoverFee,
    };
  },

  [`${Types.SUBMIT}_PENDING`]: (state, action) => {
    return {...state,
      submitPending: true,
      submitSuccess: false,
      submitError: false,
      submitErrorStripeMsg: null,
      submitErrorIneligible: false,
    };
  },
  [`${Types.SUBMIT}_RESOLVED`]: (state, action) => {
    return {...state,
      submitPending: false,
      submitSuccess: true,
      submitError: false,
      submitErrorStripeMsg: null,
      // reset form
      note: null,
      nominalAmount: null,
      selectedPmId: null,
      selectedPmType: null,
    };
  },
  [`${Types.SUBMIT}_REJECTED`]: (state, {error}) => {
    const submitErrorStripeMsg = _.get(error, 'response.data.error.stripeMessage') || null;
    const submitErrorIneligible = _.get(error, 'response.data.error.validations.donatableId[0]') === 'ineligible';
    return {...state,
      submitPending: false,
      submitSuccess: false,
      submitError: true,
      submitErrorIneligible,
      submitErrorStripeMsg,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selLoadPending           = state => state.companyAdmin.pageGrantsNew.loadPending;
  const selSelectedPmType        = state => state.companyAdmin.pageGrantsNew.selectedPmType;
  const selSelectedPmId          = state => state.companyAdmin.pageGrantsNew.selectedPmId;
  const selCreditCardIds         = state => state.companyAdmin.pageGrantsNew.creditCardIds;
  const selBankAccountIds        = state => state.companyAdmin.pageGrantsNew.bankAccountIds;
  const selNominalAmount         = state => state.companyAdmin.pageGrantsNew.nominalAmount;
  const selWantsCoverFee         = state => state.companyAdmin.pageGrantsNew.wantsCoverFee;
  const selNote                  = state => state.companyAdmin.pageGrantsNew.note;
  const selSubmitPending         = state => state.companyAdmin.pageGrantsNew.submitPending;
  const selSubmitError           = state => state.companyAdmin.pageGrantsNew.submitError;
  const selSubmitErrorStripeMsg  = state => state.companyAdmin.pageGrantsNew.submitErrorStripeMsg;
  const selSubmitErrorIneligible = state => state.companyAdmin.pageGrantsNew.submitErrorIneligible;

  const selPaymentMethods = createSelector(
    [selCreditCardIds, selBankAccountIds, EntitiesSlx.creditCards, EntitiesSlx.bankAccounts],
    (ccIds, baIds, creditCards, bankAccounts) => {
      if (!ccIds || !baIds) return [];
      const ccs = ccIds.map(id => creditCards[id]);
      const bas = baIds.map(id => bankAccounts[id]);
      return [...bas, ...ccs].filter(pm => pm);
    }
  );

  const selSelectedPaymentMethod = createSelector(
    [selPaymentMethods, selSelectedPmId, selSelectedPmType],
    (pms, selectedId, selectedType) => {
      if (selectedType === COMPANY_BALANCE.type) return COMPANY_BALANCE;
      return pms.find(pm => pm.id === selectedId);
    }
  );

  const selNonprofit = createSelector(
    [RoutingSlx.query, EntitiesSlx.nonprofits],
    (query, nonprofits) => nonprofits[query.nonprofitId]
  );

  const selShowCoverFee = createSelector(
    [selSelectedPaymentMethod],
    (pm) => {
      if (!pm) return false;
      const isBalance = pm === COMPANY_BALANCE;
      return !isBalance;
    }
  );

  const selCoverFee = createSelector(
    [selWantsCoverFee, selShowCoverFee],
    (wantsCoverFee, showCoverFee) => {
      if (!showCoverFee) return false;
      return wantsCoverFee;
    }
  );

  const selCoverAmount = createSelector(
    [selNominalAmount, selSelectedPaymentMethod],
    (nominalAmount, pm) => {
      if (!_.isFinite(nominalAmount)) return null;
      const isCc = pm?.type === PaymentMethodTypes.CREDIT_CARD;
      if (isCc) {
        return fees.calcCoverAmountCc(nominalAmount, pm);
      }
      const isBa = pm?.type === PaymentMethodTypes.BANK_ACCOUNT;
      if (isBa) {
        return fees.calcCoverAmountAch(nominalAmount);
      }
      return nominalAmount;
    }
  );

  const selAmount = createSelector(
    [selNominalAmount, selCoverAmount, selCoverFee],
    (nominalAmount, coverAmount, coverFee) => {
      return coverFee ? coverAmount : nominalAmount;
    }
  );

  const selFeeAmount = createSelector(
    [selNominalAmount, selCoverAmount],
    (nominalAmount, coverAmount) => {
      if (!_.isFinite(nominalAmount) || !_.isFinite(coverAmount)) return null;
      return coverAmount - nominalAmount;
    }
  );

  const selParams = createSelector(
    [selAmount, selNonprofit, selNote, selSelectedPmId, selSelectedPmType, selCoverFee],
    (amount, nonprofit, note, pmId, pmType, coverFee) => {
      return {
        amountInCents: amount || 0,
        feesCovered: coverFee,
        donatableType: DonatableTypes.NONPROFIT,
        donatableId: nonprofit?.id,
        note: (note || '').trim() || null,
        paymentMethodType: pmType,
        paymentMethodId: pmId,
      };
    }
  );

  const selCanSubmit = createSelector(
    [selParams, CadminSlx.company],
    (params, company) => {
      if (!params.paymentMethodType) return false;
      const isBalance = params.paymentMethodType === COMPANY_BALANCE.type;
      if (!isBalance && !params.paymentMethodId) return false;
      if (!params.amountInCents) return false;
      if (!params.donatableId) return false;
      if (isBalance && params.amountInCents > company.balance.amount) return false;
      if (params.amountInCents < 100) return false;
      return true;
    }
  );

  const selConfirmationMsg = createSelector(
    [selParams, selNonprofit, selSelectedPaymentMethod, selCanSubmit],
    (params, nonprofit, pm, canSubmit) => {
      if (!canSubmit || !nonprofit || !pm) return null;
      const amount = numeral(params.amountInCents / 100).format('$0,0.00');
      return `Send ${amount} grant to ${nonprofit.name} via ${pm.name}?`;
    },
  );

  return {
    loadPending: selLoadPending,
    nonprofit: selNonprofit,
    paymentMethods: selPaymentMethods,
    selectedPaymentMethod: selSelectedPaymentMethod,
    nominalAmount: selNominalAmount,
    amount: selAmount,
    feeAmount: selFeeAmount,
    showCoverFee: selShowCoverFee,
    wantsCoverFee: selWantsCoverFee,
    coverFee: selCoverFee,
    note: selNote,
    params: selParams,
    canSubmit: selCanSubmit,
    confirmationMsg: selConfirmationMsg,
    submitPending: selSubmitPending,
    submitError: selSubmitError,
    submitErrorStripeMsg: selSubmitErrorStripeMsg,
    submitErrorIneligible: selSubmitErrorIneligible,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
