
import PromptsDuck from 'app/ducks/prompts';

let store;

const _setStore = (_store) => {
  store = _store;
};

/**
 * Show a confirmation dialog
 * @param {Object} opts
 * @param {string} opts.msg - the main msg explaining what will happen
 * @param {string} opts.title - provide to override the default of "Please Confirm"
 * @param {string} opts.className
 * @param {string} opts.cancelBtnLabel
 * @param {string} opts.confirmBtnLabel
 * @param {string} opts.confirmBtnColor
 * @param {string} opts.danger - shorthand for setting confirmBtnColor='danger' and confirmBtnLabel=opts.danger
 * @param {boolean} opts.doubleConfirm - set to true to force user to check a box saying they understand
 * @returns {Promise<boolean>} didConfirm
 */
const confirm = async (opts) => {
  const objOpts = (typeof opts === 'string') ? {msg: opts} : opts;
  return new Promise((resolve, reject) => {
    store.dispatch(PromptsDuck.Ax.confirm(objOpts, resolve));
  });
};

export default {
  _setStore,
  confirm,
};
