
import CompaniesAx     from 'app/actions/company-admin/companies';
import ToastAx         from 'app/actions/toast';
import companyAdminApi from 'app/apis/company-admin';
import CadminSlx       from 'app/selectors/company-admin/';

const Types = {
  GET:            'CADMIN_CAMPAIGNS_GET',
  FETCH:          'CADMIN_CAMPAIGNS_FETCH',
  CREATE:         'CADMIN_CAMPAIGNS_CREATE',
  UPDATE:         'CADMIN_CAMPAIGNS_UPDATE',
  DELETE:         'CADMIN_CAMPAIGNS_DELETE',
  MARK_ENDED:     'CADMIN_CAMPAIGNS_MARK_ENDED',
  ANNOUNCE_SLACK: 'CADMIN_CAMPAIGNS_ANNOUNCE_SLACK',
  SUBMIT:         'CADMIN_CAMPAIGNS_SUBMIT',
  PUBLISH:        'CADMIN_CAMPAIGNS_PUBLISH',
  APPROVE:        'CADMIN_CAMPAIGNS_APPROVE',
  REJECT:         'CADMIN_CAMPAIGNS_REJECT',
  ARCHIVE:        'CADMIN_CAMPAIGNS_ARCHIVE',
  UNARCHIVE:      'CADMIN_CAMPAIGNS_UNARCHIVE',
};

const Actions = {

  fetch: (companyId, params) => {
    const promise = companyAdminApi.campaignsFetch(companyId, params);
    return { type: Types.FETCH, promise, _entities: ['campaigns', 'nonprofits', 'driveGoods', 'groups'] };
  },

  get: (companyId, campaignId) => {
    const promise = companyAdminApi.campaignsGet(companyId, campaignId);
    return { type: Types.GET, promise, _entities: ['campaign', 'company', 'groups', 'users', 'nonprofits', 'driveGoods', 'changeLogs'] };
  },

  create: (companyId, attrs) => {
    const promise = companyAdminApi.campaignsCreate(companyId, attrs);
    return { type: Types.CREATE, promise };
  },

  update: (companyId, campaignId, attrs) => {
    const promise = companyAdminApi.campaignsUpdate(companyId, campaignId, attrs);
    return { type: Types.UPDATE, promise, _entities: ['campaign', 'changeLogs'] };
  },

  markEnded: (companyId, campaignId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsMarkEnded(companyId, campaignId);
    promise.then(() => {
      dispatch(ToastAx.success('Campaign has been marked as ended.'));
    });
    return dispatch({type: Types.MARK_ENDED, promise, campaignId, _entities: ['campaign', 'changeLogs']});
  },

  announceInSlack: (companyId, campaignId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsAnnounceInSlack(companyId, campaignId);
    promise.then(() => {
      dispatch(ToastAx.success('Announced.'));
    });
    return dispatch({ type: Types.ANNOUNCE_SLACK, promise, campaignId });
  },

  submit: (companyId, campaignId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsSubmit(companyId, campaignId);
    promise.then(() => {
      dispatch(ToastAx.success('Campaign has been submitted for review.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.SUBMIT, promise, campaignId, _entities: ['campaign', 'changeLogs']});
  },

  publish: (companyId, campaignId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsPublish(companyId, campaignId);
    promise.then(() => {
      dispatch(ToastAx.success('Campaign has been published.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.PUBLISH, promise, campaignId, _entities: ['campaign', 'changeLogs']});
  },

  approve: (companyId, campaignId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsApprove(companyId, campaignId);
    promise.then(() => {
      dispatch(ToastAx.success('Campaign has been approved and published.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.APPROVE, promise, campaignId, _entities: ['campaign', 'changeLogs']});
  },

  reject: (companyId, campaignId, note) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsReject(companyId, campaignId, note);
    promise.then(() => {
      dispatch(ToastAx.success('Campaign has been moved back to draft with changes requested.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.REJECT, promise, campaignId, _entities: ['campaign', 'changeLogs']});
  },

  archive: (companyId, campaignId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsArchive(companyId, campaignId);
    promise.then(() => {
      dispatch(ToastAx.success('Campaign has been archived.'));
    });
    return dispatch({type: Types.ARCHIVE, promise, campaignId, _entities: ['campaign', 'changeLogs']});
  },

  unarchive: (companyId, campaignId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsPublish(companyId, campaignId);
    promise.then(() => {
      dispatch(ToastAx.success('Campaign has been restored back to active.'));
    });
    return dispatch({type: Types.UNARCHIVE, promise, campaignId, _entities: ['campaign', 'changeLogs']});
  },

  delete: (companyId, campaignId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = companyAdminApi.campaignsDelete(companyId, campaignId);
    promise.then(() => {
      dispatch(ToastAx.success('Campaign has been deleted.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.DELETE, promise, campaignId, _entities: ['campaign', 'changeLogs']});
  },

};

export {
  Types,
};

export default Actions;
