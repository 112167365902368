import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RoutingAx            from 'app/actions/routing';
import Icon                 from 'app/components/common/icon';
import Link                 from 'app/components/common/link';
import ModalPublish         from 'app/components/publish-status/modal-publish';
import ModalReview          from 'app/components/publish-status/modal-review';
import Ps                   from 'app/helpers/publish-statuses';
import utils                from 'app/helpers/utils';
import history              from 'app/history';
import paths                from 'app/paths';
import AuthSlx              from 'app/selectors/auth';
import RoutingSlx           from 'app/selectors/routing';

class PublishStatusBar extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showModalPublish: false,
      showModalReview: false,
    };

    this.onClickPublish = this.onClickPublish.bind(this);
    this.onCloseModalPublish = this.onCloseModalPublish.bind(this);
    this.onClickReview = this.onClickReview.bind(this);
    this.onCloseModalReview = this.onCloseModalReview.bind(this);
  }

  componentDidMount() {
    this.checkQueryAction();
  }

  componentDidUpdate(prevProps) {
    const psActionDidChange = prevProps.query.psAction !== this.props.query.psAction;
    if (psActionDidChange) this.checkQueryAction();
  }

  checkQueryAction() {
    const {query} = this.props;
    const psAction = this.props.query.psAction;
    if (psAction) {
      history.replace(utils.buildQueryString({...query, psAction: null}) || '?');
      if (psAction === 'publish') this.onClickPublish();
      if (psAction === 'review') this.onClickReview();
    }
  }

  get ps() {
    return this.props.entity.publishStatus;
  }
  get isDraft() {
    return this.ps === Ps.DRAFT;
  }
  get isPending() {
    return this.ps === Ps.PENDING;
  }
  get isActive() {
    return this.ps === Ps.ACTIVE;
  }
  get isArchived() {
    return this.ps === Ps.ARCHIVED;
  }
  get isDeleted() {
    return this.ps === Ps.DELETED;
  }

  get isNew() {
    return !this.props.entity.id;
  }
  get isEditing() {
    return this.props.editMode;
  }
  get isViewing() {
    return !this.isEditing;
  }
  get hide() {
    if (this.isActive && this.isViewing) return true;
    return false;
  }

  get perms() {
    const {company, entityType, currentUser} = this.props;
    const role = currentUser?.employment?.employee?.role || undefined;
    return Ps.getPerms(entityType, role, (company?.settingsApprovals || {}));
  }
  get canEdit() {
    return this.perms.canEdit;
  }
  get canPublish() {
    return this.perms.canPublish;
  }
  get entityTypeName() {
    return this.perms.entityTypeName;
  }
  get entityTypeNameLower() {
    return this.entityTypeName.toLowerCase();
  }

  get entityShowPath() {
    const {entity, entityType} = this.props;
    if (!entity?.id) return null;
    if (entityType === 'Campaign') return paths.campaign(entity.id);
    if (entityType === 'VolEvent') return paths.volEvent(entity.id);
    if (entityType === 'Group')    return paths.group(entity.id);
    return null;
  }
  get entityEditPath() {
    const {entity, entityType, company} = this.props;
    const slug = company?.slug;
    if (!entity?.id || !slug) return null;
    if (entityType === 'Campaign') return paths.cadminEditCampaign(slug, entity.id);
    if (entityType === 'VolEvent') return paths.cadminEditVolEvent(slug, entity.id);
    if (entityType === 'Group')    return paths.cadminGroupsEdit(  slug, entity.id);
    return null;
  }
  get entityManagePath() {
    const {entity, entityType, company} = this.props;
    const slug = company?.slug;
    if (!entity?.id || !slug) return null;
    if (entityType === 'Campaign') return paths.cadminCampaignsView(slug, entity.id);
    if (entityType === 'VolEvent') return paths.cadminVolEventsView(slug, entity.id);
    if (entityType === 'Group')    return paths.cadminGroupsView(   slug, entity.id);
    return null;
  }

  get showView() {
    if (this.isNew) return false;
    if (!this.entityShowPath) return false;
    return !this.isViewing;
  }  
  get showEdit() {
    if (this.isEditing) return false;
    if (!this.canEdit) return false;
    if (!this.entityEditPath) return false;
    return true;
  }
  get showManage() {
    if (!this.canEdit) return false;
    if (!this.entityManagePath) return false;
    return true;
  }
  get showSubmit() {
    if (!this.canEdit) return false;
    if (this.isNew) return false;
    if (!this.isDraft) return false;
    return true;
  }
  get showReview() {
    if (!this.canPublish) return false;
    if (!this.isPending) return false;
    return true;
  }

  onClickPublish() {
    this.setState({showModalPublish: true});
  }
  onCloseModalPublish() {
    this.setState({showModalPublish: false});
  }

  onClickReview() {
    this.setState({showModalReview: true});
  }
  onCloseModalReview() {
    this.setState({showModalReview: false});
  }

  renderPublishModal() {
    if (!this.state.showModalPublish) return null;
    const {entityType, entity, company, currentUser} = this.props;
    const commonProps = {entity, company, currentUser, onClose: this.onCloseModalPublish};
    if (entityType === 'Campaign') return <ModalPublish.Campaign {...commonProps} />;
    if (entityType === 'VolEvent') return <ModalPublish.VolEvent {...commonProps} />;
    if (entityType === 'Group')    return <ModalPublish.Group    {...commonProps} />;
    return null;
  }

  renderReviewModal() {
    if (!this.state.showModalReview) return null;
    const {entityType, entity, company, currentUser} = this.props;
    const commonProps = {entity, company, currentUser, onClose: this.onCloseModalReview};
    if (entityType === 'Campaign') return <ModalReview.Campaign {...commonProps} />;
    if (entityType === 'VolEvent') return <ModalReview.VolEvent {...commonProps} />;
    if (entityType === 'Group')    return <ModalReview.Group    {...commonProps} />;
    return null;
  }

  renderMainMsg() {
    if (this.isActive) return (
      <div className="ps-bar-msg">
        <strong>This {this.entityTypeNameLower} is published</strong>
        <span>Any changes made here will be reflected immediately.</span>
      </div>
    );
    if (this.isPending) return (
      <div className="ps-bar-msg">
        <strong>Pending Review</strong>
        <span>Approval required before this {this.entityTypeNameLower} goes live.</span>
      </div>
    );
    if (this.isArchived) return (
      <div className="ps-bar-msg">
        <strong>Archived</strong>
        <span>This {this.entityTypeNameLower} is no longer active.</span>
      </div>
    );
    if (this.isDeleted) return (
      <div className="ps-bar-msg">
        <strong>Deleted</strong>
        <span>This {this.entityTypeNameLower} is no longer active.</span>
      </div>
    );
    if (this.isDraft) return (
      <div className="ps-bar-msg">
        <strong>This is a draft</strong>
      </div>
    );
    return null;
  }

  render() {
    const {currentUser, query} = this.props;
    if (this.hide) return null;
    const makeActionHref = (psAction) => `${utils.buildQueryString({...query, psAction})}`;

    return (
      <div className={`ps-bar ps-${this.ps}`}>
        <div className="widther">
          <div className="ps-bar-group">
            {this.renderMainMsg()}
          </div>
          {(this.showView || this.showEdit || this.showManage) && (
            <div className="ps-bar-group">
              {this.showView && <Link className="ps-bar-link" href={this.entityShowPath}>Employee View <Icon.Caret direction="right" /></Link>}
              {this.showEdit && <Link className="ps-bar-link" href={this.entityEditPath}>{`Edit ${this.entityTypeName}`} <Icon.Caret direction="right" /></Link>}
              {this.showManage && <Link className="ps-bar-link" href={this.entityManagePath}>{`Manage ${this.entityTypeName}`} <Icon.Caret direction="right" /></Link>}
            </div>
          )}
          {this.showSubmit && (
            <div className="ps-bar-group">
              <span className="ps-bar-span">Ready to publish?</span>
              <Link href={makeActionHref('publish')} replace className="ps-bar-cta">Publish</Link>
            </div>
          )}
          {this.showReview && (
            <div className="ps-bar-group">
              <span className="ps-bar-span">Ready to review?</span>
              <Link href={makeActionHref('review')} replace className="ps-bar-cta">Review</Link>
            </div>
          )}
        </div>
        {this.renderPublishModal()}
        {this.renderReviewModal()}
      </div>
    );
  }

}

PublishStatusBar.propTypes = {
  entityType: PropTypes.oneOf(['Campaign', 'VolEvent', 'Group']).isRequired,
  entity: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
};

PublishStatusBar.defaultProps = {
  editMode: false,
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  query: RoutingSlx.query(state),
});

const dispatchToProps = (dispatch) => ({
  promptToSave: () => dispatch(RoutingAx.promptToSave()),
});

export default connect(stateToProps, dispatchToProps)(PublishStatusBar);
