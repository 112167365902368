import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import ClientTimestamp    from 'app/components/common/client-timestamp';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import ModalSlackEvent    from 'app/components/company-admin/slack/modal-channel-vol-event';
import ModalEventsEdit    from 'app/components/company-admin/volunteer/modal-events-simple-edit';
import RequireRole        from 'app/components/gating/require-role';
import PageLoading        from 'app/components/layout/page-loading';
import EventCard          from 'app/components/volunteer/vol-event-card';
import {
  VolEventTypes as Types,
}                         from 'app/constants';
import PageDuck           from 'app/ducks/company-admin/page-vol-events-view';
import EventsDuck         from 'app/ducks/company-admin/vol-events';
import helpers            from 'app/helpers/vol-events';
import Ps                 from 'app/helpers/publish-statuses';
import history            from 'app/history';
import Metrics            from 'app/metrics';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import AuthSlx            from 'app/selectors/auth';
import CadminSlx          from 'app/selectors/company-admin/';

class PageCadminVolEventsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      deletePending: false,
      archivePending: false,
      unarchivePending: false,
      markEndedPending: false,
      showSlackModal: false,
      showEditModal: false,
    };

    this.onClickConvert = this.onClickConvert.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onCloseEdit = this.onCloseEdit.bind(this);
    this.onClickMarkEnded = this.onClickMarkEnded.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickArchive = this.onClickArchive.bind(this);
    this.onClickUnarchive = this.onClickUnarchive.bind(this);
    this.onClickSlackSettings = this.onClickSlackSettings.bind(this);
    this.onCloseSlackSettings = this.onCloseSlackSettings.bind(this);
  }

  async onClickMarkEnded(event) {
    event.preventDefault();
    const didConfirm = await prompts.confirm({confirmBtnLabel: 'End Event', msg: 'Are you sure you want to end this event?'});
    if (!didConfirm) return;
    this.setState({markEndedPending: true});
    await this.props.markEnded(this.props.company.id, this.props.volEvent.id);
    this.setState({markEndedPending: false});
  }
  async onClickDelete(event) {
    event.preventDefault();
    const msg = `Are you sure you want to delete this event? The event will no longer be visible to employees or to admins anywhere.\nThis will negatively affect analytics/reporting, as associated time and attendance entries will be deleted as well.\nThis can not be undone. We recommend archiving instead.`;
    const didConfirm = await prompts.confirm({msg, danger: 'Delete Event', doubleConfirm: true});
    if (!didConfirm) return;
    this.setState({deletePending: true});
    await this.props.delete(this.props.company.id, this.props.volEvent.id);
    this.setState({deletePending: false});
  }
  async onClickArchive(event) {
    event.preventDefault();
    let msg = 'Are you sure you want to archive this event?\nIt will still show up in the admin area and analytics, but will no longer be discoverable by employees.';
    msg += `\nEmployees will no longer be able to register${this.isVol ? ' or track hours' : ''} for this event.`;
    const didConfirm = await prompts.confirm({confirmBtnLabel: 'Archive Event', msg});
    if (!didConfirm) return;
    this.setState({archivePending: true});
    await this.props.archive(this.props.company.id, this.props.volEvent.id);
    this.setState({archivePending: false});
  }
  async onClickUnarchive(event) {
    event.preventDefault();
    const didConfirm = await prompts.confirm({confirmBtnLabel: 'Restore Event', msg: 'Are you sure you want to restore this event back to the published state?\nIt will become discoverable by employees again.'});
    if (!didConfirm) return;
    this.setState({unarchivePending: true});
    await this.props.unarchive(this.props.company.id, this.props.volEvent.id);
    this.setState({unarchivePending: false});
  }
  async onClickConvert(event) {
    event.preventDefault();
    const msg = 'This will take you to the event builder. Once the builder has been submitted, the event will become visible.';
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Continue', title: 'Convert to Visible Event'});
    if (!didConfirm) return;
    const path = paths.cadminEditVolEvent(this.props.company.slug, this.props.volEvent.id);
    history.push(path);
  }

  onClickEdit() {
    this.setState({showEditModal: true});
  }
  onCloseEdit() {
    this.setState({showEditModal: false});
  }

  onClickSlackSettings() {
    this.setState({showSlackModal: true});
  }
  onCloseSlackSettings() {
    this.setState({showSlackModal: false});
  }

  get role() {
    return this.props.currentUser?.employment?.employee?.role || undefined;
  }
  get isVol() {
    return this.props.volEvent?.type !== Types.EVENT;
  }
  get type() {
    return this.isVol ? Types.VOL_OPP : Types.EVENT;
  }
  get analHref() {
    const {company, volEvent: ve} = this.props;
    if (!company || !ve) return '#';
    return this.isVol
      ? paths.cadminAnalytics(company.slug, {groupBy: 'employeeId', volEventId: ve.id},   Metrics.presets.volunteering.key)
      : paths.cadminAnalytics(company.slug, {groupBy: 'employeeId', groupEventId: ve.id}, Metrics.presets.groupEvents.key);
  }

  get isActive() {
    return this.props.volEvent?.publishStatus === Ps.ACTIVE;
  }

  get isActiveish() {
    return [Ps.ACTIVE, Ps.ARCHIVED].includes(this.props.volEvent?.publishStatus);
  }

  get status() {
    const {volEvent} = this.props;
    if (!volEvent) return ['status', 'gray'];
    if (this.isActive) {
      const [label, color] = helpers.getStatusDetails(volEvent);
      return [label, color];
    }
    const ps = volEvent.publishStatus;
    return [Ps.name(ps), Ps.color(ps)];
  }

  get hasSlack() {
    const {company} = this.props;
    return !!(company && company.isSlackSetup);
  }

  renderStatusPicker() {
    const {deletePending, archivePending, unarchivePending, markEndedPending} = this.state;
    if (deletePending) return 'deleting...';
    if (archivePending) return 'archiving...';
    if (markEndedPending) return 'ending...';
    if (unarchivePending) return 'restoring...';

    const {company, volEvent} = this.props;
    if (!volEvent) return null;
    const ps = volEvent.publishStatus;
    const perms = Ps.getPerms('VolEvent', this.role, company.settingsApprovals, ps);
    const [label, color] = this.status;
    const showMarkEnded = !!(perms.canEdit && volEvent.isOngoing && !volEvent.endedAt && (ps === Ps.ACTIVE));
    const showPublish = perms.canEdit && (ps === Ps.DRAFT);
    const showArchive = perms.canEdit && (ps === Ps.ACTIVE);
    const showUnarchive = perms.canEdit && (ps === Ps.ARCHIVED);
    const showReview = perms.canPublish && (ps === Ps.PENDING);
    const showDelete = perms.canDelete && (ps !== Ps.DELETED);

    const target = (
      <div className="ca-ps-picker">
        <span>Status</span>
        <div className={`ca-ps-picker-tag ${color}`}>{label}</div>
        {(ps !== Ps.DELETED) && <Icon.Caret className="ca-ps-picker-caret" direction="down" />}
      </div>
    );
    return (
      <EllipsisMenu target={target}>
        {showPublish   && <Link href={paths.volEvent(volEvent.id, {psAction: 'publish'})} onClick={this.onClickPublish}>Publish</Link>}
        {showReview    && <Link href={paths.volEvent(volEvent.id, {psAction: 'review'})}  onClick={this.onClickReview}>Approve or Restrict</Link>}
        {showMarkEnded && <a href="#" onClick={this.onClickMarkEnded}>Mark as Ended</a>}
        {showUnarchive && <a href="#" onClick={this.onClickUnarchive}>Restore (un-archive)</a>}
        {showArchive   && <a href="#" onClick={this.onClickArchive}>Archive</a>}
        {showDelete    && <a href="#" onClick={this.onClickDelete}>Delete</a>}
      </EllipsisMenu>
    );
  }

  renderChangeLog() {
    const {company, changeLogs} = this.props;
    if (!changeLogs?.length) return null;

    return (<>
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">History</h1>
        </div>
        <div className="ca-box-body">
          <table className="ca-box-table">
            <thead>
              <tr>
                <th>Event</th>
                <th>Date</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              {changeLogs.map((change) => {
                return (
                  <tr key={change.id}>
                    <td>{Ps.changeName(change)}</td>
                    <td><ClientTimestamp.DateTimeTz timestamp={change.createdTs} /></td>
                    <td>
                      {!!change.employeeName && <Link className="pink-hover" href={paths.cadminEmployee(company?.slug, change.employeeId)}>{change.employeeName}</Link>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>);
  }

  renderMain() {
    const {company, volEvent, changeLogs} = this.props;
    if (!volEvent) return null;
    const vteLinkText = this.isVol ? 'Manage Volunteers & Hours' : 'Manage Attendees';
    const showSlack = !!(this.hasSlack && !volEvent.isHidden);

    return (<>
      <div className="ca-main-ctas">
        <Link className="btn small icon secondary" href={paths.volEvent(volEvent.id)}><Icon.BrowserPageText />Employee View</Link>
        {volEvent.isHidden ? (<>
          <button className="btn small icon secondary" onClick={this.onClickEdit}><Icon.Pencil />Edit</button>
        </>) : (<>
          <Link className="btn small icon secondary" href={paths.cadminEditVolEvent(company.slug, volEvent.id)}><Icon.Pencil />Edit</Link>
        </>)}
        {this.isActiveish && (
          <Link className="btn small icon secondary" href={paths.cadminVolTracking(company.slug, {volEventId: volEvent.id, eventType: volEvent.type})}><Icon.TaskChecklistCheck /> {vteLinkText}</Link>
        )}
        {this.isActiveish && (
          <RequireRole>
            <Link className="btn small icon secondary" href={this.analHref}><Icon.AnalyticsBars />Analytics</Link>
          </RequireRole>
        )}
        {showSlack && (
          <button className="btn small icon secondary" onClick={this.onClickSlackSettings}><Icon.LogoSlack />Slack Settings</button>
        )}
      </div>

      <div className="ca-main-entity-preview">
        <h2 className="ca-main-entity-preview-title">Event Listing Preview</h2>
        {volEvent.isHidden ? (<>
          <p>No preview available. This is a hidden event used only for tracking purposes.</p>
          <button className="btn small icon secondary" onClick={this.onClickConvert}><Icon.Pencil />Convert to Visible Event</button>
        </>) : (
          <EventCard volEvent={volEvent} />
        )}
      </div>

      {this.renderChangeLog()}
    </>);
  }

  render() {
    const {company, volEvent} = this.props;
    const {showSlackModal, showEditModal} = this.state;
    if (!company) return <PageLoading />;
    const name = volEvent?.title || 'Event';
    const activeItem = this.isVol ? 'vol-events' : 'events';

    return (
      <CadminLayout className="ca-vevent-vw" company={company} activeItem={activeItem}>
        <Meta title={`${name} | Millie`} />
        <div className="ca-main-head">
          <Link className="ca-main-head-back" href={paths.cadminVolEvents(company.slug, {type: this.type})}><Icon.Caret direction="left" /> Back to Events</Link>
          <h1 className="ca-main-head-h1">{name}</h1>
          <div className="ca-main-head-actions">
            {this.renderStatusPicker()}
          </div>
        </div>
        {this.renderMain()}
        {showSlackModal && (
          <ModalSlackEvent volEvent={volEvent} onClose={this.onCloseSlackSettings} />
        )}
        {showEditModal && (
          <ModalEventsEdit volEvent={volEvent} onClose={this.onCloseEdit} />
        )}
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentUser: AuthSlx.currentUser(state),
  volEvent: PageDuck.Slx.volEvent(state),
  changeLogs: PageDuck.Slx.changeLogs(state),
});

const dispatchToProps = (dispatch) => ({
  delete:    (companyId, volEventId) => dispatch(EventsDuck.Ax.delete(companyId, volEventId)),
  archive:   (companyId, volEventId) => dispatch(EventsDuck.Ax.archive(companyId, volEventId)),
  unarchive: (companyId, volEventId) => dispatch(EventsDuck.Ax.unarchive(companyId, volEventId)),
  markEnded: (companyId, volEventId) => dispatch(EventsDuck.Ax.markEnded(companyId, volEventId)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminVolEventsView);
