import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-dashboard';
import Checkbox           from 'app/components/common/checkbox';
import Icon               from 'app/components/common/icon';
import NonprofitInput     from 'app/components/common/nonprofit-input';
import StandardInput      from 'app/components/common/standard-input';
import UploadedImageInput from 'app/components/common/uploaded-image-input';
import EntityInput        from 'app/components/company-admin/common/entity-input';
import Builder            from 'app/components/company-admin/layout/builder';
import BuilderFooter      from 'app/components/company-admin/layout/builder-footer';
import BuilderIterable    from 'app/components/company-admin/layout/builder-iterable';
import BuilderSection     from 'app/components/company-admin/layout/builder-section';
import Ps                 from 'app/helpers/publish-statuses';
import CadminSlx          from 'app/selectors/company-admin/';
import BuilderSlx         from 'app/selectors/company-admin/builder-dashboard';

class DashboardBuilder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeLogo = this.onChangeLogo.bind(this);
    this.onChangeEmployeesCanView = this.onChangeEmployeesCanView.bind(this);
    this.onChangeLinkCanView = this.onChangeLinkCanView.bind(this);
    this.onChangeShowSectionMatch = this.onChangeShowSectionMatch.bind(this);
    this.onChangeShowSectionVolunteer = this.onChangeShowSectionVolunteer.bind(this);
    this.onChangeShowSectionGrant = this.onChangeShowSectionGrant.bind(this);
    this.onChangeShowSectionGift = this.onChangeShowSectionGift.bind(this);
    this.onChangeShowCustomerGifts = this.onChangeShowCustomerGifts.bind(this);
    this.onChangeShowEmployeeGifts = this.onChangeShowEmployeeGifts.bind(this);
    this.onChangeMissionText = this.onChangeMissionText.bind(this);
    this.onChangeProgramDetailsText = this.onChangeProgramDetailsText.bind(this);
    this.onClickAddLovedNonprofit = this.onClickAddLovedNonprofit.bind(this);
    this.onRemoveLovedNonprofit = this.onRemoveLovedNonprofit.bind(this);
    this.onClickAddEvent = this.onClickAddEvent.bind(this);
    this.onRemoveEvent = this.onRemoveEvent.bind(this);
    this.onClickAddCampaign = this.onClickAddCampaign.bind(this);
    this.onRemoveCampaign = this.onRemoveCampaign.bind(this);
    this.onChangeHasSocialFeed = this.onChangeHasSocialFeed.bind(this);
  }

  get hasIntl() {
    return !!this.props.company?.features?.international;
  }

  /*
   *  UI Events
   */

  onClickSave() {
    this.props.save();
  }

  onClickAddLovedNonprofit() {
    const index = this.props.lovedNonprofits.length;
    this.props.setLovedNonprofit(null, index);
  }
  onChangeLovedNonprofit(index, nonprofit) {
    this.props.setLovedNonprofit(nonprofit, index);
  }
  onRemoveLovedNonprofit(index) {
    this.props.removeLovedNonprofit(index);
  }

  onClickAddEvent() {
    const index = this.props.volEvents.length;
    this.props.setVolEvent(null, index);
  }
  onChangeEvent(index, volEvent) {
    this.props.setVolEvent(volEvent, index);
  }
  onRemoveEvent(index) {
    this.props.removeVolEvent(index);
  }

  onClickAddCampaign() {
    const index = this.props.campaigns.length;
    this.props.setCampaign(null, index);
  }
  onChangeCampaign(index, campaign) {
    this.props.setCampaign(campaign, index);
  }
  onRemoveCampaign(index) {
    this.props.removeCampaign(index);
  }

  onChangeHasSocialFeed(event) {
    const hasSocialFeed = event.target.checked;
    this.props.setKeyVal('hasSocialFeed', hasSocialFeed);
  }

  onChangeEmployeesCanView(event) {
    const employeesCanView = event.target.checked;
    this.props.setKeyVal('employeesCanView', employeesCanView);
  }

  onChangeLinkCanView(event) {
    const linkCanView = event.target.checked;
    this.props.setKeyVal('linkCanView', linkCanView);
  }

  onChangeShowSectionMatch(event) {
    const showSectionMatch = event.target.checked;
    this.props.setKeyVal('showSectionMatch', showSectionMatch);
  }

  onChangeShowSectionVolunteer(event) {
    const showSectionVolunteer = event.target.checked;
    this.props.setKeyVal('showSectionVolunteer', showSectionVolunteer);
  }

  onChangeShowSectionGrant(event) {
    const showSectionGrant = event.target.checked;
    this.props.setKeyVal('showSectionGrant', showSectionGrant);
  }

  onChangeShowSectionGift(event) {
    const showSectionGift = event.target.checked;
    this.props.setKeyVal('showSectionGift', showSectionGift);
  }

  onChangeShowCustomerGifts(event) {
    const showCustomerGifts = event.target.checked;
    this.props.setKeyVal('showCustomerGifts', showCustomerGifts);
  }

  onChangeShowEmployeeGifts(event) {
    const showEmployeeGifts = event.target.checked;
    this.props.setKeyVal('showEmployeeGifts', showEmployeeGifts);
  }

  onChangeMissionText(event) {
    const missionText = event.target.value;
    this.props.setKeyVal('missionText', missionText);
  }

  onChangeProgramDetailsText(event) {
    const programDetailsText = event.target.value;
    this.props.setKeyVal('programDetailsText', programDetailsText);
  }

  onChangeLogo(logoPath) {
    this.props.setKeyVal('logoPath', logoPath);
  }

  /*
   *  Render Sections
   */

  renderSectionLogo() {
    const { logoPath } = this.props;
    return (
      <BuilderSection title="Logo">
        <UploadedImageInput
          name="logo"
          label="Logo Image"
          onChange={this.onChangeLogo}
          s3PathParts={['company-dashboards']}
          maxImageWidth={512}
          pathValue={logoPath}
        />
      </BuilderSection>
    );
  }

  renderSectionMission() {
    const { missionText } = this.props;
    return (
      <BuilderSection title="Mission / Intro">
        <StandardInput type="textarea" label="Mission" name="missionText" value={missionText || ''} onChange={this.onChangeMissionText} />
      </BuilderSection>
    );
  }

  renderSectionCampaigns() {
    const { campaigns, company } = this.props;
    return (
      <BuilderSection title="Campaigns">
        <div className="cadmin-dbb-camps-camps">
          {campaigns.map((campaign, index) => {
            return (
              <BuilderIterable index={index} key={index} onDelete={this.onRemoveCampaign}>
                <EntityInput.Campaign publishStatuses={Ps.nonDeleted} onChange={this.onChangeCampaign.bind(this, index)} campaignId={campaign?.id} />
              </BuilderIterable>
            );
          })}
          <button className="btn blue small cadmin-builder-iterable-add" onClick={this.onClickAddCampaign}>+ Add Campaign</button>
        </div>
      </BuilderSection>
    );
  }

  renderSectionSocial() {
    const {hasSocialFeed} = this.props;

    const topRight = (
      <Checkbox isToggle checked={hasSocialFeed} onChange={this.onChangeHasSocialFeed} />
    );
    return (
      <BuilderSection title="Activity Feed" topRight={topRight}>
        <div className="italic">Only All-Access Admins can post here.</div>
      </BuilderSection>
    );
  }

  renderSectionMatch() {
    const { showSectionMatch } = this.props;
    const topRight = (
      <Checkbox isToggle checked={showSectionMatch} onChange={this.onChangeShowSectionMatch} />
    );
    return (
      <BuilderSection title="Giving Program" topRight={topRight} />
    );
  }

  renderSectionVolunteer() {
    const { showSectionVolunteer, volEvents, company } = this.props;
    const topRight = (
      <Checkbox isToggle checked={showSectionVolunteer} onChange={this.onChangeShowSectionVolunteer} />
    );
    return (
      <BuilderSection title="Volunteer Program" topRight={topRight}>
        <div className="cadmin-dbb-vol-events">
          {volEvents.map((volEvent, index) => {
            return (
              <BuilderIterable index={index} key={index} onDelete={this.onRemoveEvent}>
                <EntityInput.VolEvent publishStatuses={Ps.nonDeleted} volEventId={volEvent?.id} onChange={this.onChangeEvent.bind(this, index)} />
              </BuilderIterable>
            );
          })}
          <button className="btn blue small cadmin-builder-iterable-add" onClick={this.onClickAddEvent}>+ Add Opportunity</button>
        </div>
      </BuilderSection>
    );
  }

  renderSectionGrant() {
    const { showSectionGrant } = this.props;
    const topRight = (
      <Checkbox isToggle checked={showSectionGrant} onChange={this.onChangeShowSectionGrant} />
    );
    return (
      <BuilderSection title="Grant Program" topRight={topRight} />
    );
  }

  renderSectionGift() {
    const { showSectionGift, showCustomerGifts, showEmployeeGifts, company } = this.props;
    const topRight = (
      <Checkbox isToggle checked={showSectionGift} onChange={this.onChangeShowSectionGift} />
    );
    return (
      <BuilderSection title="Gift Program" className="cadmin-dbb-gift" topRight={topRight}>
        <div className="cadmin-builder-checkbox">
          <Checkbox id="checkbox-show-customer-gifts" onChange={this.onChangeShowCustomerGifts} checked={showCustomerGifts} />
          <label htmlFor="checkbox-show-customer-gifts">Customer Impact</label>
        </div>
        <div className="cadmin-builder-checkbox">
          <Checkbox id="checkbox-show-employee-gifts" onChange={this.onChangeShowEmployeeGifts} checked={showEmployeeGifts} />
          <label htmlFor="checkbox-show-employee-gifts">Employee Gifts</label>
        </div>
      </BuilderSection>
    );
  }

  renderSectionCommunity() {
    const { lovedNonprofits, programDetailsText } = this.props;
    return (
      <BuilderSection title="Our Community" className="cadmin-dbb-comm">
        <label>Nonprofits We Love</label>
        <div className="cadmin-dbb-comm-loved-nps">
          {lovedNonprofits.map((nonprofit, index) => {
            return (
              <BuilderIterable index={index} key={index} onDelete={this.onRemoveLovedNonprofit}>
                <NonprofitInput onChange={this.onChangeLovedNonprofit.bind(this, index)} selectedNonprofit={nonprofit} intl={this.hasIntl} />
              </BuilderIterable>
            );
          })}
          <button className="btn blue small cadmin-builder-iterable-add" onClick={this.onClickAddLovedNonprofit}>+ Add Nonprofit</button>
        </div>

        <label>Program Details</label>
        <StandardInput type="textarea" label="Program Details" name="programDetailsText" className="cadmin-dbb-comm-pd-text" value={programDetailsText || ''} onChange={this.onChangeProgramDetailsText} />
      </BuilderSection>
    );
  }

  renderSectionSharing() {
    const { employeesCanView, linkCanView } = this.props;
    return (
      <BuilderSection title="Sharing Settings" className="cadmin-dbb-sharing">
        <div className="cadmin-builder-toggle">
          <span className="cadmin-builder-toggle-badge">Recommended</span>
          <label htmlFor="checkbox-emps-can-view">Employee access to dashboard</label>
          <Checkbox id="checkbox-emps-can-view" isToggle checked={employeesCanView} onChange={this.onChangeEmployeesCanView} />
        </div>
        <div className="cadmin-builder-toggle">
          <label htmlFor="checkbox-link-can-view">Anyone with the link can view</label>
          <Checkbox id="checkbox-link-can-view" isToggle checked={linkCanView} onChange={this.onChangeLinkCanView} />
        </div>
      </BuilderSection>
    );
  }

  renderFooter() {
    const { hasTouched, savePending } = this.props;
    const disabled = !hasTouched || savePending;
    const btnText = savePending ? 'Saving...' : 'Save';
    return (
      <BuilderFooter className="cadmin-dbb-bottom">
        <div className="cadmin-builder-bottom-actions">
          <button className="btn green" disabled={disabled} onClick={this.onClickSave}>{btnText}</button>
        </div>
      </BuilderFooter>
    );
  }

  render() {
    return (
      <Builder footer={this.renderFooter()} className="cadmin-dbb">
        {this.renderSectionLogo()}
        {this.renderSectionMission()}
        {this.renderSectionCampaigns()}
        {this.renderSectionSocial()}
        {this.renderSectionMatch()}
        {this.renderSectionVolunteer()}
        {this.renderSectionGrant()}
        {this.renderSectionGift()}
        {this.renderSectionCommunity()}
        {this.renderSectionSharing()}
      </Builder>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  hasTouched: BuilderSlx.hasTouched(state),
  savePending: BuilderSlx.savePending(state),
  logoPath: BuilderSlx.logoPath(state),
  lovedNonprofits: BuilderSlx.lovedNonprofits(state),
  campaigns: BuilderSlx.campaigns(state),
  volEvents: BuilderSlx.volEvents(state),
  employeesCanView: BuilderSlx.employeesCanView(state),
  linkCanView: BuilderSlx.linkCanView(state),
  showSectionMatch: BuilderSlx.showSectionMatch(state),
  showSectionVolunteer: BuilderSlx.showSectionVolunteer(state),
  showSectionGrant: BuilderSlx.showSectionGrant(state),
  showSectionGift: BuilderSlx.showSectionGift(state),
  showCustomerGifts: BuilderSlx.showCustomerGifts(state),
  showEmployeeGifts: BuilderSlx.showEmployeeGifts(state),
  missionText: BuilderSlx.missionText(state),
  hasSocialFeed: BuilderSlx.hasSocialFeed(state),
  programDetailsText: BuilderSlx.programDetailsText(state),
});

const dispatchToProps = (dispatch) => ({
  setKeyVal: (key, val) => dispatch(BuilderAx.setKeyVal(key, val)),
  setLovedNonprofit: (nonprofit, index) => dispatch(BuilderAx.setLovedNonprofit(nonprofit, index)),
  removeLovedNonprofit: (index) => dispatch(BuilderAx.removeLovedNonprofit(index)),
  setVolEvent: (volEvent, index) => dispatch(BuilderAx.setVolEvent(volEvent, index)),
  removeVolEvent: (index) => dispatch(BuilderAx.removeVolEvent(index)),
  setCampaign: (campaign, index) => dispatch(BuilderAx.setCampaign(campaign, index)),
  removeCampaign: (index) => dispatch(BuilderAx.removeCampaign(index)),
  save: () => dispatch(BuilderAx.save()),
});

export default connect(stateToProps, dispatchToProps)(DashboardBuilder);
