import _ from 'lodash';
import { createSelector } from 'reselect';
import timm from 'timm';

import CompaniesAx   from 'app/actions/company-admin/companies';
import ToastAx       from 'app/actions/toast';
import CadminApi     from 'app/apis/company-admin';
import reducerUtils  from 'app/reducers/utils';
import CadminSlx     from 'app/selectors/company-admin/';
import EntitiesSlx   from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  GET:                    'COMP_GROUPS_GET',
  SUBMIT:                 'COMP_GROUPS_SUBMIT',
  PUBLISH:                'COMP_GROUPS_PUBLISH',
  APPROVE:                'COMP_GROUPS_APPROVE',
  REJECT:                 'COMP_GROUPS_REJECT',
  ARCHIVE:                'COMP_GROUPS_ARCHIVE',
  UNARCHIVE:              'COMP_GROUPS_UNARCHIVE',
  DELETE:                 'COMP_GROUPS_DELETE',
  GROUP_EMPS_SEARCH:      'COMP_GROUPS_GROUP_EMPS_SEARCH',
  GROUP_EMPS_DELETE:      'COMP_GROUPS_GROUP_EMPS_DELETE',
  GROUP_EMPS_MAKE_LEAD:   'COMP_GROUPS_GROUP_EMPS_MAKE_LEAD',
  GROUP_EMPS_REMOVE_LEAD: 'COMP_GROUPS_GROUP_EMPS_REMOVE_LEAD',
  GROUP_EMPS_ADD:         'COMP_GROUPS_GROUP_EMPS_ADD',
};

const Ax = {

  get: (companyId, groupId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsGet(companyId, groupId);
    return dispatch({type: Types.GET, promise, _entities: ['group', 'campaigns', 'volEvents', 'nonprofits', 'changeLogs']});
  },

  submit: (companyId, groupId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsSubmit(companyId, groupId);
    promise.then(() => {
      dispatch(ToastAx.success('Group has been submitted for review.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.SUBMIT, promise, groupId, _entities: ['group', 'changeLogs']});
  },

  publish: (companyId, groupId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsPublish(companyId, groupId);
    promise.then(() => {
      dispatch(ToastAx.success('Group has been published.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.PUBLISH, promise, groupId, _entities: ['group', 'changeLogs']});
  },

  approve: (companyId, groupId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsApprove(companyId, groupId);
    promise.then(() => {
      dispatch(ToastAx.success('Group has been approved and published.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.APPROVE, promise, groupId, _entities: ['group', 'changeLogs']});
  },

  reject: (companyId, groupId, note) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsReject(companyId, groupId, note);
    promise.then(() => {
      dispatch(ToastAx.success('Group has been moved back to draft with changes requested.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.REJECT, promise, groupId, _entities: ['group', 'changeLogs']});
  },

  archive: (companyId, groupId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsArchive(companyId, groupId);
    promise.then(() => {
      dispatch(ToastAx.success('Group has been archived.'));
    });
    return dispatch({type: Types.ARCHIVE, promise, groupId, _entities: ['group', 'changeLogs']});
  },

  unarchive: (companyId, groupId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsPublish(companyId, groupId);
    promise.then(() => {
      dispatch(ToastAx.success('Group has been restored back to active.'));
    });
    return dispatch({type: Types.UNARCHIVE, promise, groupId, _entities: ['group', 'changeLogs']});
  },

  delete: (companyId, groupId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsDelete(companyId, groupId);
    promise.then(() => {
      dispatch(ToastAx.success('Group has been deleted.'));
      dispatch(CompaniesAx.getAttentionNumbers(companyId, {refresh: true}));
    });
    return dispatch({type: Types.DELETE, promise, groupId, _entities: ['group', 'changeLogs']});
  },

  groupEmployeesSearch: (companyId, params={}) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsGroupEmployeesSearch(companyId, params);
    return dispatch({type: Types.GROUP_EMPS_SEARCH, promise, _entities: ['groups', 'employees', 'groupTypes', 'groupEmployees']});
  },

  groupEmployeesDelete: (companyId, groupEmployeeId) => (dispatch, getState) => {
    const state = getState();
    if (!companyId) companyId = CadminSlx.companyId(state);
    const groupEmployee = EntitiesSlx.groupEmployees(state)[groupEmployeeId];
    const promise = CadminApi.groupsMembersRemove(companyId, groupEmployee.groupId, groupEmployee.employeeId);
    promise.then(() => {
      dispatch(ToastAx.success('Group member removed.'));
    });
    return dispatch({type: Types.GROUP_EMPS_DELETE, promise, groupEmployeeId});
  },

  groupEmployeesMakeLead: (companyId, groupEmployeeId) => (dispatch, getState) => {
    const state = getState();
    if (!companyId) companyId = CadminSlx.companyId(state);
    const groupEmployee = EntitiesSlx.groupEmployees(state)[groupEmployeeId];
    const promise = CadminApi.groupsMakeLead(companyId, groupEmployee.groupId, groupEmployee.employeeId);
    promise.then(() => {
      dispatch(ToastAx.success('Group member changed to lead.'));
    });
    return dispatch({type: Types.GROUP_EMPS_MAKE_LEAD, promise, _entities: ['groupEmployee']});
  },

  groupEmployeesRemoveLead: (companyId, groupEmployeeId) => (dispatch, getState) => {
    const state = getState();
    if (!companyId) companyId = CadminSlx.companyId(state);
    const groupEmployee = EntitiesSlx.groupEmployees(state)[groupEmployeeId];
    const promise = CadminApi.groupsRemoveLead(companyId, groupEmployee.groupId, groupEmployee.employeeId);
    promise.then(() => {
      dispatch(ToastAx.success('Group member changed to regular member.'));
    });
    return dispatch({type: Types.GROUP_EMPS_REMOVE_LEAD, promise, _entities: ['groupEmployee']});
  },

  groupEmployeesCreate: (companyId, groupId, employeeIds) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.groupsGroupEmployeesCreate(companyId, groupId, employeeIds);
    promise.then(() => {
      dispatch(ToastAx.success('Members added to group.'));
    });
    return dispatch({type: Types.GROUP_EMPS_ADD, promise});
  },

};

/*
 *  Reducer
 */

const initialState = {
  createPending: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.GROUP_EMPS_ADD}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
    };
  },
  [`${Types.GROUP_EMPS_ADD}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },
  [`${Types.GROUP_EMPS_ADD}_REJECTED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selCreatePending = state => state.companyAdmin.groups.createPending;

  return {
    createPending: selCreatePending,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
