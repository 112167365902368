
import _ from 'lodash';

class ResizeMonitor extends EventTarget {

  constructor(...args) {
    super(...args);

    this.emitResize = _.debounce(this.emitResize, 200);

    if (__IS_CLIENT__) {
      window.addEventListener('resize', this.onResize.bind(this));
    }
  }

  addEventListener(...args) {
    super.addEventListener(...args);
    this.emitResize();
  }

  emitResize() {
    const {innerWidth: width, innerHeight: height} = window;
    this.dispatchEvent(new CustomEvent('resize', {detail: {width, height}}));
  }

  onResize(event) {
    this.emitResize();
  }

}

const resizeMonitor = new ResizeMonitor();

export default resizeMonitor;
