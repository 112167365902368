import {
  PublishStatuses,
  EmployeeRoles,
} from 'app/constants';

const all = Object.values(PublishStatuses);
const nonDeleted = [PublishStatuses.DRAFT, PublishStatuses.PENDING, PublishStatuses.ACTIVE, PublishStatuses.ARCHIVED];
const tabList    = [PublishStatuses.DRAFT, PublishStatuses.PENDING, PublishStatuses.ACTIVE, PublishStatuses.ARCHIVED];

const nameMap = {
  [PublishStatuses.DRAFT]: 'Draft',
  [PublishStatuses.PENDING]: 'Ready for Review',
  [PublishStatuses.ACTIVE]: 'Published',
  [PublishStatuses.ARCHIVED]: 'Archived',
  [PublishStatuses.DELETED]: 'Deleted',
};
const name = (status) => nameMap[status] || status;

const colorMap = {
  [PublishStatuses.PENDING]: 'pink',
  [PublishStatuses.ACTIVE]: 'green',
  [PublishStatuses.DELETED]: 'danger',
};
const color = (status) => colorMap[status] || 'gray';

const changeNameMap = {
  [PublishStatuses.DRAFT]: 'Draft created',
  [PublishStatuses.PENDING]: 'Submitted for review',
  [PublishStatuses.ACTIVE]: 'Published',
  [PublishStatuses.ARCHIVED]: 'Archived',
  [PublishStatuses.DELETED]: 'Deleted',
};
const changeName = (changeLog) => {
  const {beforePublishStatus: before, afterPublishStatus: after} = changeLog;
  const defaultName = changeNameMap[after] || after;
  if (before !== PublishStatuses.PENDING) return defaultName;
  if (after === PublishStatuses.ACTIVE) return 'Approved & published';
  if (after === PublishStatuses.DRAFT) return 'Changes requested';
  return defaultName;
};

const isValid = (status) => all.includes(status);
const activeishStatuses = [PublishStatuses.ACTIVE, PublishStatuses.ARCHIVED];
const preactiveStatuses = [PublishStatuses.DRAFT, PublishStatuses.PENDING];
const isActiveish = (status) => activeishStatuses.includes(status);
const isPreActive = (status) => preactiveStatuses.includes(status);

const getPerms = (entityType, role, settingsApprovals, publishStatus) => {
  if (!['Campaign', 'VolEvent', 'Group'].includes(entityType)) throw new Error('invalid entityType in ps.getPerms');
  const [reviewRequired, entityTypeName] = (() => {
    const [attr, name] = ({
      Campaign: ['campaignsRequired', 'Campaign'],
      VolEvent: ['eventsRequired', 'Event'],
      Group: ['groupsRequired', 'Group'],
    })[entityType] || ['', 'Page'];
    return [!!(settingsApprovals || {})[attr], name];
  })();
  const isFullAdmin = role === EmployeeRoles.ADMIN;
  const isPartialAdmin = (() => {
    if (isFullAdmin) return true;
    // group leads can do everything champions can do, and also manage groups
    // champions can manage campaigns & events, but not groups
    if (role === EmployeeRoles.GROUP_LEAD) return true;
    if (entityType === 'Group') return false;
    if (role === EmployeeRoles.CHAMPION) return true;
    return false;
  })();
  const canDelete = (() => {
    if (isFullAdmin) return true;
    if (isPreActive(publishStatus)) {
      if (isPartialAdmin) return true;
    }
    return false;
  })();
  const canEdit = isPartialAdmin;
  const canPublish = (() => {
    // full admins: yes, reg emps: no, otherwise: depends on settings
    if (isFullAdmin) return true;
    if (!isPartialAdmin) return false;
    return !reviewRequired;
  })();
  return {reviewRequired, entityTypeName, isFullAdmin, isPartialAdmin, canEdit, canDelete, canPublish};
};

export default {
  ...PublishStatuses,
  all,
  nonDeleted,
  tabList,
  nameMap,
  name,
  changeName,
  color,
  isValid,
  activeishStatuses,
  preactiveStatuses,
  isActiveish,
  isPreActive,
  getPerms,
};
