
import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminPurchasesAx    from 'app/actions/company-admin/company-purchases';

const Types = {
  LOAD: 'CADMIN_PAGE_PURCHASE_LOAD',
};

const Actions = {

  load: ({params: {companySlug, purchaseId}}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.loadCommon(companySlug)),
      dispatch(CadminPurchasesAx.get(companySlug, purchaseId)),
    ]);
    return dispatch({type: Types.LOAD, promise, purchaseId});
  },

};

export {
  Types,
};

export default Actions;
