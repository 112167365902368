import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-campaign';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import Builder            from 'app/components/company-admin/campaigns/builder';
import PageLoading        from 'app/components/layout/page-loading';
import PublishStatusBar   from 'app/components/publish-status/bar';
import CadminSlx          from 'app/selectors/company-admin/';
import CampaignProfile    from 'app/components/campaigns/campaign-profile';
import BuilderSlx         from 'app/selectors/company-admin/builder-campaign';
import PageSlx            from 'app/selectors/company-admin/page-campaigns-edit';


class PageCadminEditCampaign extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
  }

  onSave() {
    return this.props.save();
  }

  render() {
    const {company, campaign, hasTouched, save} = this.props;
    if (!company || !campaign) return <PageLoading />;

    const builder = <Builder />;

    if (__IS_CLIENT__) {
      window.builder=builder;
    }

    return (
      <CadminLayout className="page-cadmin-campaign-builder edit" company={company} activeItem="campaigns" builder={builder}>
        <Meta title="Edit Campaign | Millie" />
        <CampaignProfile campaign={campaign} company={company} editMode />
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  campaign: PageSlx.campaign(state),
  hasTouched: BuilderSlx.hasTouched(state),
});

const dispatchToProps = (dispatch) => ({
  save: () => dispatch(BuilderAx.save()),
});

export default connect(stateToProps, dispatchToProps)(PageCadminEditCampaign);
