import _ from 'lodash';
import { createSelector } from 'reselect';

import CampaignsAx       from 'app/actions/campaigns';
import CadminCompaniesAx from 'app/actions/company-admin/companies';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_CAMPS_TEMPLATES_LOAD',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CampaignsAx.fetchTemplates()),
      dispatch(CadminCompaniesAx.loadCommon(params.companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
  templateCampaignIds: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
      templateCampaignIds: null,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const templateCampaignIds = _.get(action, 'result[0].campaigns').map(c => c.id);
    return {...state,
      isLoading: false,
      templateCampaignIds,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading = (state) => state.companyAdmin.pageCampaignsTemplates.isLoading;
  const selTemplateCampaignIds = state => state.companyAdmin.pageCampaignsTemplates.templateCampaignIds;

  const getCampaigns = (ids, campaigns, nonprofits) => {
    return ids && ids.map((id) => {
      const campaign = {...campaigns[id]};
      campaign.aNonprofit = nonprofits[campaign.aNonprofitId];
      return campaign;
    });
  };

  const selTemplateCampaigns = createSelector(
    [selTemplateCampaignIds, EntitiesSlx.campaigns, EntitiesSlx.nonprofits],
    getCampaigns
  );

  return {
    isLoading: selIsLoading,
    templateCampaigns: selTemplateCampaigns,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
