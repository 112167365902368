import { createSelector } from 'reselect';
import _ from 'lodash';

import Ps          from 'app/helpers/publish-statuses';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selGroupIds = state => state.companyAdmin.pageGroups.groupIds;
const selSummary = state => state.companyAdmin.pageGroups.summary;

const selGroups = createSelector(
  [selGroupIds, EntitiesSlx.groups, EntitiesSlx.employees, EntitiesSlx.groupTypes],
  (groupIds, groups, employees, groupTypes) => {
    return groupIds && groupIds.map((id) => {
      const obj = {...groups[id]};
      obj.adminEmployee = employees[obj.adminEmployeeId];
      obj.groupType = groupTypes[obj.groupTypeId];
      return obj;
    });
  }
);

const selQueryParams = createSelector(
  [RoutingSlx.query],
  (query) => {
    const obj = {
      publishStatus: Ps.isValid(query.publishStatus) ? query.publishStatus : Ps.ACTIVE,
    };
    return obj;
  }
);

export default {
  summary: selSummary,
  groups: selGroups,
  queryParams: selQueryParams,
};
